import { createReducer } from '@reduxjs/toolkit'
import { setAllFilterForm, setPartFilterForm } from './action'

type TState = {
  partData: {
    total: number
    filterForm: any
    data: any
  }
  allData: {
    total: number
    filterForm: any
    data: any
  }
}

const initialState: TState = {
  partData: {
    total: 0,
    filterForm: {
      sort_by: '',
      sort: '',
      page: 1,
      per_page: 40,
    },
    data: [],
  },
  allData: {
    total: 0,
    filterForm: {
      sort_by: '',
      sort: '',
      page: 1,
      per_page: 40,
    },
    data: [],
  },
}

export const affiliateReducer = createReducer(initialState, builder => {
  builder.addCase(setPartFilterForm, (state, action) => {
    state.partData = action.payload
  })
  builder.addCase(setAllFilterForm, (state, action) => {
    state.allData = action.payload
  })
})

export default affiliateReducer
