export type TFilterForm = {
  status: '' | 'scheduled' | 'processing' | 'done' | 'partial_stuck'
  type?: '' | 'daily' | 'weekly'
  page: number
  per_page: number
  sort_by: string
  sort: string
}

export type TPayout = {
  name: string
  type: string
}

export const statusOptions = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Processing',
    value: 'processing',
  },
  {
    label: 'Done',
    value: 'done',
  },
  {
    label: 'Partial Stuck',
    value: 'stuck',
  },
]

export const detailStatusOptions = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Done',
    value: 'paid',
  },
  {
    label: 'Scheduled',
    value: 'pending',
  },
  {
    label: 'In Transit',
    value: 'in_transit',
  },
  {
    label: 'Stuck',
    value: 'failed',
  },
]

export const typeOptions = [
  {
    label: 'All Types',
    value: '',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
]
