import axiosInstance from 'utils/axios'

export async function getTerritoryListApi() {
  const response = await axiosInstance.request({
    url: 'map/territories',
    method: 'get',
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getTerritoryApi(value: string) {
  const response = await axiosInstance.request({
    url: 'map/territories',
    method: 'get',
    params: {
      address_query: value,
    },
  })
  return response.data
}

interface TmapBrands {
  address_query: string
  latitude?: string
  longitude?: string
}
export async function getMapBrandsApi(params: TmapBrands) {
  const response = await axiosInstance.request({
    url: 'map/accounts',
    method: 'get',
    params,
    cache: { ignoreCache: true },
  })
  return response.data
}
