import { useRef } from 'react'
import isEqual from 'lodash/isEqual'

/**
 * how to use
 * 
  import { useCompare } from 'hooks/useDebounce'
  const compareFunc = useCompare()
  useEffect(() => {
    compareFunc(formData, formDataInit, (flag: boolean) => setFormIsDirty(flag))
  }, [formData, formDataInit])
 */

type ICallback = (args: boolean) => void
// 对比函数
function compare(a: any, b: any, fn: ICallback) {
  const res = isEqual(a, b)
  // console.log('res-->', res, a, b)
  fn(!res)
}

// debounce/compare hooks
export function useCompare() {
  const compareFunc = useDebounce(compare)
  return compareFunc
}

// useDebounce Hook
type IdebounceFnType = (...args: any[]) => void

export default function useDebounce(fn: IdebounceFnType, wait = 1000) {
  const debounceFnRef = useRef(debounce(fn, wait))
  return debounceFnRef.current
}

// debounce 原始函数
export function debounce(this: any, fn: IdebounceFnType, wait = 1000) {
  let timer: NodeJS.Timeout | null = null
  const ctx = this
  return function (...args: any[]) {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(ctx, args)
    }, wait)
  }
}
