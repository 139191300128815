/* eslint-disable react-hooks/exhaustive-deps */
import { History, Transition } from 'history'
import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

/**
 * https://github.com/remix-run/react-router/issues/8139#issuecomment-9538163153
 * https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 */
type ExtendNavigator = Navigator & Pick<History, 'block'>

export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) {
      return
    }
    window.addEventListener('beforeunload', removeBeforeUnload)
    // 实现该功能主要是利用了 history 库的 block api https://github.com/remix-run/history/blob/main/docs/blocking-transitions.md
    const unblock = (navigator as any as ExtendNavigator).block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }
      blocker(autoUnblockingTx)
    })
    // 由于无法直接 remove history 库中绑定的 beforeunload 事件，只能自己在绑定一个 beforeunload 事件（在原事件之前），触发时调用 unblock
    // https://github.com/remix-run/history/blob/485ebc177c1f3f8eef93b0d654fffd1d321c2ecd/packages/history/index.ts#L549
    function removeBeforeUnload() {
      unblock()
    }
    return () => {
      unblock()
      window.removeEventListener('beforeunload', removeBeforeUnload)
    }
  }, [when])
}
