import { ReactElement } from 'react'
import cn from 'classnames'

interface Iprops {
  className?: string
  title?: string
  children: ReactElement
  onUpload: (file: Blob) => void // 直接返回 blob 对象
}

export default function UploadFile({
  children,
  title,
  className,
  onUpload,
}: Iprops) {
  const onChange = (e: TInputEvent) => {
    e.preventDefault()
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    // 防止同一个文件重复上传不生效
    e.target.value = ''
    onUpload(file)
  }

  return (
    <div className={cn('relative inline-flex', className)}>
      {children}
      <input
        className='absolute inset-0 z-10 opacity-0 cursor-pointer'
        type='file'
        accept='*'
        onChange={onChange}
      />
    </div>
  )
}
