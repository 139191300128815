import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useAppSelector } from 'redux/hooks'

import { Select } from 'components/Select'
import { DatePicker } from 'components/DatePicker'
import { getSaleCrmTeams } from 'redux/sales'
import { modelOptions, timePeriodOptions } from '../helpers/constant'

export type TSalesFilterProp = {
  handleFilterChange: (type: string, value: any) => void
}

export default function SalesFilter({
  handleFilterChange,
}: TSalesFilterProp): JSX.Element {
  const { filterForm } = useAppSelector(state => state.sales)
  const { model, unit, option, crm_team_id } = filterForm
  const [teamOptions, setTeamOptions] = useState([
    {
      label: 'All Teams',
      value: '',
    },
  ])

  const getTeamOptions = async () => {
    try {
      const res = await getSaleCrmTeams()
      if (res && res.length > 0) {
        setTeamOptions(
          [
            {
              label: 'All Teams',
              value: '',
            },
          ].concat(
            res.map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              }
            }),
          ),
        )
      }
    } catch (err: any) {
      if (err && err.message) {
        console.error(err.message)
      }
    }
  }

  useEffect(() => {
    getTeamOptions()
  }, [])

  return (
    <div className='flex space-x-4'>
      <Select
        className='w-60'
        value={model}
        onChange={value => {
          handleFilterChange('model', value)
        }}
        options={modelOptions}
      />
      <Select
        className='w-60'
        value={unit}
        onChange={value => {
          handleFilterChange('unit', value)
        }}
        options={timePeriodOptions}
      />
      {unit === 'monthly' && (
        <DatePicker
          className='w-60'
          picker='month'
          allowClear={false}
          format='MMM, YYYY'
          value={moment(option, 'YYYY-MM')}
          onChange={value => {
            handleFilterChange('option', value?.format() || '')
          }}
        />
      )}
      {unit === 'quarterly' && (
        <DatePicker
          className='w-60'
          picker='quarter'
          allowClear={false}
          format={value => `Q${value.format('Q YYYY')}`}
          value={moment(option, 'YYYY-MM')}
          onChange={value => {
            handleFilterChange('option', value?.format() || '')
          }}
        />
      )}
      {unit === 'yearly' && (
        <DatePicker
          className='w-60'
          picker='year'
          allowClear={false}
          format='YYYY'
          value={moment(option, 'YYYY')}
          onChange={value => {
            handleFilterChange('option', value?.format() || '')
          }}
        />
      )}
      <Select
        className='w-60'
        value={crm_team_id}
        onChange={value => {
          handleFilterChange('crm_team_id', value)
        }}
        options={teamOptions}
      />
    </div>
  )
}
