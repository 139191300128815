import Modal from 'components/Modal'
import Button from 'components/Button'

export type TProps = {
  open: boolean
  success: boolean
  errorMsg: string
  toggle: () => void
}

export default function MenuImportModal({
  open,
  success,
  errorMsg,
  toggle,
}: TProps) {
  return (
    <Modal
      open={open}
      toggle={toggle}
      title='IMPORT RESULT'
      footer={
        <div className='flex w-full justify-end'>
          <Button onClick={toggle}>CLOSE</Button>
        </div>
      }
    >
      {success ? (
        <div className='w-full font-bold text-xl text-light-900'>
          🎉 Import successful
        </div>
      ) : (
        <div className='w-full font-bold text-xl text-light-900'>
          ⛔ Import failed due to the following: {errorMsg}
        </div>
      )}
    </Modal>
  )
}
