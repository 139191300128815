import { TOKEN_KEY, CRM_ROLE_ID } from 'data/constant'
import Cookies from 'js-cookie'
import moment from 'moment'

export const getAccessToken = (): string | undefined => {
  return Cookies.get(TOKEN_KEY)
}

export const removeAccessToken = (): void => {
  Cookies.remove(TOKEN_KEY)
  Cookies.remove(CRM_ROLE_ID)
}

export const saveAccessToken = (
  access_token: string,
  expires_at: Date,
): void => {
  Cookies.set(TOKEN_KEY, access_token, {
    expires: moment(expires_at).diff(moment(), 'days'),
  })
}

export const getCRMROLEID = (): string | undefined => {
  return Cookies.get(CRM_ROLE_ID)
}

export const saveCRMROLEID = (crm_role_id: string): void => {
  Cookies.set(CRM_ROLE_ID, crm_role_id)
}

export const removeCRMROLEID = (): void => {
  Cookies.remove(CRM_ROLE_ID)
}
