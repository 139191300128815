import { useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { SolidTrashCan } from '@rushable/icons'
import Button from 'components/Button'
import CommunicationLogItem from 'components/CommunicationLogItem'
import Modal from 'components/Modal'
import SingleInput from 'components/SingleInput'
import ParagraphInput from 'components/ParagraphInput'
import {
  getVaultInfoApi,
  createVaultApi,
  updateVaultApi,
  deleteVaultApi,
} from 'redux/account'

type Tvault = {
  onClose: () => void
}

export default function VaultTab({ onClose }: Tvault) {
  const [logList, setLogList] = useState<any[]>([])
  const urlParams = useParams()
  const [curLog, setCurLog] = useState<any>({})
  const [updateModel, setUpdateModel] = useState({
    open: false,
    value: '',
    okLoading: false,
    deleteLoading: false,
  })
  const [pwdModel, setPwdModel] = useState({
    open: true,
    // value: 'rushabledefault1',
    value: '',
    loading: false,
    isInputedPwd: false,
  })

  const getVaultInfo = async () => {
    setPwdModel(prev => ({ ...prev, loading: true }))
    try {
      const res = await getVaultInfoApi(urlParams.id!, pwdModel.value)
      setPwdModel(prev => ({ ...prev, open: false, isInputedPwd: true }))
      setLogList(res)
    } catch (error: any) {
      toast.error(error.message)
    }
    setPwdModel(prev => ({ ...prev, loading: false }))
  }

  const handleCreate = async () => {
    setUpdateModel(prev => ({ ...prev, okLoading: true }))
    try {
      const params = { message: updateModel.value }
      let res
      if (curLog.id) {
        res = await updateVaultApi(urlParams.id!, curLog.id, params)
      } else {
        res = await createVaultApi(urlParams.id!, params)
      }
      toast.success(res.message)
      setUpdateModel(prev => ({ ...prev, open: false, value: '' }))
      getVaultInfo()
    } catch (error: any) {
      toast.error(error.message)
    }
    setUpdateModel(prev => ({ ...prev, okLoading: false }))
  }

  const handleDelete = async () => {
    setUpdateModel(prev => ({ ...prev, deleteLoading: true }))
    try {
      const res = await deleteVaultApi(urlParams.id!, curLog.id)
      toast.success(res.message)
      setUpdateModel(prev => ({ ...prev, open: false }))
      getVaultInfo()
    } catch (error: any) {
      toast.error(error.message)
    }
    setUpdateModel(prev => ({ ...prev, deleteLoading: true }))
  }
  return (
    <>
      {pwdModel.isInputedPwd && (
        <div>
          {logList.map((item: any, index: number) => {
            const editor = item.editor || {}
            return (
              <CommunicationLogItem
                key={index}
                className='my-4'
                source='to'
                mode='comment'
                name={`${editor.first_name} ${editor.last_name}`}
                avatar={editor.profile_photo}
                updateAt={item.updated_at}
                msg={item.message}
                onCommentUpdated={() => {
                  setCurLog(item)
                  setUpdateModel(prev => ({
                    ...prev,
                    open: true,
                    value: item.message,
                  }))
                }}
              />
            )
          })}
          <Button
            className='mx-auto'
            theme='contained'
            color='secondary'
            onClick={() => {
              setUpdateModel(prev => ({ ...prev, open: true, value: '' }))
              setCurLog({})
            }}
          >
            ADD INFORMATION
          </Button>
        </div>
      )}

      <Modal
        open={updateModel.open}
        toggle={() => setUpdateModel(prev => ({ ...prev, open: false }))}
        title={curLog.id ? 'EDIT INFORMATION' : 'CREATE INFORMATION'}
        okText={curLog.id ? 'UPDATE' : 'CONFIRM'}
        cancelText='CLOSE'
        okButtonProps={{
          loading: updateModel.okLoading,
          disabled: !updateModel.value,
        }}
        onOk={handleCreate}
        footerLeftElement={
          <>
            {curLog.id && (
              <Button
                theme='text'
                color='warning'
                loading={updateModel.deleteLoading}
                onClick={handleDelete}
                iconBefore={<SolidTrashCan size={12} />}
              >
                DELETE
              </Button>
            )}
          </>
        }
      >
        <ParagraphInput
          value={updateModel.value}
          name='DESCRIPTION'
          label='DESCRIPTION'
          placeholder='Input'
          onChange={(e: any) =>
            setUpdateModel(prev => ({ ...prev, value: e.target.value }))
          }
        />
      </Modal>
      <Modal
        open={pwdModel.open}
        toggle={() => {
          onClose()
          setPwdModel(prev => ({ ...prev, open: false }))
        }}
        title='VAULT ACCESS'
        okText='CONFIRM'
        cancelText='CLOSE'
        okButtonProps={{
          loading: pwdModel.loading,
          disabled: !pwdModel.value,
        }}
        onOk={getVaultInfo}
      >
        <SingleInput
          value={pwdModel.value}
          name='INPUT YOUR PASSWORD'
          label='INPUT YOUR PASSWORD'
          placeholder='Input'
          onChange={e =>
            setPwdModel(prev => ({ ...prev, value: e.target.value }))
          }
        />
      </Modal>
    </>
  )
}
