import React from 'react'

import { DAYS } from 'data/enum'
import { TOpenHour } from 'types/openHour'

import DayClose from './DayClose'
import DayHoursField from './DayHoursField'

export type TDayHoursCollectionFormProp = {
  day: string
  dailyHours: TOpenHour[]
  onHourChange: (
    index: number,
    day: string,
    name: string,
    value: string,
  ) => void
  addHours: (day: string) => void
  removeHours: (day: string, index: number) => void
  disabled?: boolean
}

export default function DayHoursCollection({
  day,
  dailyHours,
  onHourChange,
  addHours,
  removeHours,
  disabled,
}: TDayHoursCollectionFormProp): JSX.Element {
  return (
    <div className='flex py-2'>
      <div className='w-72 font-bold mr-6 text-light-300 text-sm flex items-center'>
        {DAYS.find(d => d.abbr === day)?.label || ''}
      </div>
      <div className='block'>
        {dailyHours.length === 0 && (
          <DayClose addHours={() => addHours(day)} disabled={disabled} />
        )}
        {dailyHours.map((dayHours, index) => (
          <DayHoursField
            key={index}
            index={index}
            dayHours={dayHours}
            onChange={(name, value) =>
              onHourChange(index, dayHours.day, name, value)
            }
            addHours={() => addHours(dayHours.day)}
            removeHours={() => removeHours(dayHours.day, index)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  )
}
