import * as ROUTES from './constant'

import OrdersPage from 'pages/Orders'
import AccountsPage from 'pages/Accounts'
import ReferralPage from 'pages/Referral'
import AffiliatePage from 'pages/Affiliate'
import DisputePage from 'pages/Dispute'
import CreditPage from 'pages/Credit'
import BlocklistPage from 'pages/Blocklist'
import DomainsPage from 'pages/Domains'
import SalesPage from 'pages/Sales'
import MapPage from 'pages/Map'

import PayoutPage from 'pages/Payout'
import LoginPage from 'pages/Login'

/**
 * https://files.slack.com/files-pri/T01569QU890-F04DYCC817S/image.png
 * 菜单权限控制 crm_role_id
 *  orders        1、11-29 显示
 *  accounts      1、11-199
 *  maps          1、11-19
 *  sales         1、11-19
 *  domain        1、21-29
 *  referral      1
 *  affiliate     1、101-199
 *  credit        1、21-29
 *  blocklist     1、21-29
 *  payout        1
 */

// nav menu route
const ORDERS: TRouteType = {
  path: ROUTES.ORDERS,
  name: 'Orders',
  component: OrdersPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 29,
  },
}

const ACCOUNTS: TRouteType = {
  path: ROUTES.ACCOUNTS,
  name: 'Accounts',
  component: AccountsPage,
  auth: true,
}

const Map: TRouteType = {
  path: ROUTES.MAP,
  name: 'Map',
  component: MapPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
  },
}

const SALES: TRouteType = {
  path: ROUTES.SALES,
  name: 'Sales',
  component: SalesPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 11,
    max: 19,
  },
}

const DOMAINS: TRouteType = {
  path: ROUTES.DOMAINS,
  name: 'Domain',
  component: DomainsPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const REFERRAL: TRouteType = {
  path: ROUTES.REFERRAL,
  name: 'Referral',
  component: ReferralPage,
  auth: true,
  permission: {
    roleId: 1,
  },
}

const AFFILIATE: TRouteType = {
  path: ROUTES.AFFILIATE,
  name: 'Affiliate',
  component: AffiliatePage,
  auth: true,
  permission: {
    roleId: 1,
    min: 101,
    max: 199,
  },
}

const DISPUTE: TRouteType = {
  path: ROUTES.DISPUTE,
  name: 'Dispute',
  component: DisputePage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const CREDIT: TRouteType = {
  path: ROUTES.CREDIT,
  name: 'Credit',
  component: CreditPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

const BLOCKLIST: TRouteType = {
  path: ROUTES.BLOCKLIST,
  name: 'Blocklist',
  component: BlocklistPage,
  auth: true,
  permission: {
    roleId: 1,
    min: 21,
    max: 29,
  },
}

// other route
const PAYOUT: TRouteType = {
  path: ROUTES.PAYOUT,
  name: 'Payout',
  component: PayoutPage,
  auth: true,
  permission: {
    roleId: 1,
  },
}

const LOGIN: TRouteType = {
  path: ROUTES.LOGIN,
  name: 'Login',
  component: LoginPage,
  auth: false,
}

export const navMenuRoutes = [
  ORDERS,
  ACCOUNTS,
  Map,
  SALES,
  DOMAINS,
  REFERRAL,
  AFFILIATE,
  CREDIT,
  DISPUTE,
  BLOCKLIST,
  PAYOUT,
]
export const pageRoutes = [...navMenuRoutes, LOGIN]
