import { ReactElement, Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'

export type TModalProp = {
  className?: string
  open: boolean
  toggle: () => void
  children: ReactElement | ReactElement[]
}

export default function ModalCore({
  className,
  open,
  toggle,
  children,
}: TModalProp): JSX.Element {
  return (
    <>
      <Transition.Root appear show={open} as={Fragment}>
        <Dialog as='div' className='relative z-[150]' onClose={toggle}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-dark-900 bg-opacity-80' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel>
                  <div
                    className={className}
                    style={{
                      filter: 'drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.24))',
                    }}
                  >
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
