import React, { useState } from 'react'
import { useControl, Marker, MarkerProps, ControlPosition } from 'react-map-gl'
// @ts-ignore
import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder'
import Pin from './pin'

// type GeocoderControlProps = {
//   mapboxAccessToken: string,
//   origin?: string,
//   zoom?: number,
//   flyTo?: boolean | object,
//   placeholder?: string,
//   proximity?: {
//     longitude: number,
//     latitude: number,
//   },
//   trackProximity?: boolean,
//   collapsed?: boolean,
//   clearAndBlurOnEsc?: boolean,
//   clearOnBlur?: boolean,
//   box?: [number, number, number, number],
//   countries?: string,
//   types?: string,
//   minLength?: number,
//   limit?: number,
//   language?: string,
//   filter?: (feature: object) => boolean,
//   localGeocoder?: Function,
//   externalGeocoder?: Function,
//   reverseMode?: 'distance' | 'score',
//   reverseGeocode?: boolean,
//   enableEventLogging?: boolean,
//   marker?: boolean | object,
//   render?: (feature: object) => string,
//   getItemValue?: (feature: object) => string,
//   mode?: 'mapbox.places' | 'mapbox.places-permanent',
//   localGeocoderOnly?: boolean,
//   autocomplete?: boolean,
//   fuzzyMatch?: boolean,
//   routing?: boolean,
//   worldview?: string,

//   position: ControlPosition,

//   onLoading?: (e: object) => void,
//   onResults?: (e: object) => void,
//   onResult?: (e: object) => void,
//   onError?: (e: object) => void,
// }

type GeocoderControlProps = Omit<
  GeocoderOptions,
  'accessToken' | 'mapboxgl' | 'marker'
> & {
  mapboxAccessToken: string
  marker?: Omit<MarkerProps, 'longitude' | 'latitude'>

  position: ControlPosition

  onLoading?: (e: object) => void
  onResults?: (e: object) => void
  onResult?: (e: any) => void
  onError?: (e: object) => void
}

/* eslint-disable complexity,max-statements */
export default function GeocoderControl(props: GeocoderControlProps) {
  const [marker, setMarker] = useState<any>(null)

  const geocoder = useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        marker: false,
        collapsed: true,
        accessToken: props.mapboxAccessToken,
      })
      ctrl.on('loading', props.onLoading)
      ctrl.on('results', props.onResults)
      ctrl.on('result', (evt: any) => {
        const { result } = evt
        props.onResult?.(result)
        const location =
          result &&
          (result.center ||
            (result.geometry?.type === 'Point' && result.geometry.coordinates))
        if (location && props.marker) {
          setMarker(
            <Marker
              {...props.marker}
              anchor='bottom'
              longitude={location[0]}
              latitude={location[1]}
            >
              <Pin checked={true} />
            </Marker>,
          )
        } else {
          setMarker(null)
        }
      })
      ctrl.on('error', props.onError)
      return ctrl
    },
    {
      position: props.position,
    },
  )

  // @ts-ignore (TS2339) private member
  if (geocoder._map) {
    if (
      geocoder.getProximity() !== props.proximity &&
      props.proximity !== undefined
    ) {
      geocoder.setProximity(props.proximity)
    }
    if (
      geocoder.getRenderFunction() !== props.render &&
      props.render !== undefined
    ) {
      geocoder.setRenderFunction(props.render)
    }
    if (
      geocoder.getLanguage() !== props.language &&
      props.language !== undefined
    ) {
      geocoder.setLanguage(props.language)
    }
    if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
      geocoder.setZoom(props.zoom)
    }
    if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
      geocoder.setFlyTo(props.flyTo)
    }
    if (
      geocoder.getPlaceholder() !== props.placeholder &&
      props.placeholder !== undefined
    ) {
      geocoder.setPlaceholder(props.placeholder)
    }
    if (
      geocoder.getCountries() !== props.countries &&
      props.countries !== undefined
    ) {
      geocoder.setCountries(props.countries)
    }
    if (geocoder.getTypes() !== props.types && props.types !== undefined) {
      geocoder.setTypes(props.types)
    }
    if (
      geocoder.getMinLength() !== props.minLength &&
      props.minLength !== undefined
    ) {
      geocoder.setMinLength(props.minLength)
    }
    if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
      geocoder.setLimit(props.limit)
    }
    if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
      geocoder.setFilter(props.filter)
    }
    if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {
      geocoder.setOrigin(props.origin)
    }
    // Types missing from @types/mapbox__mapbox-gl-geocoder
    // if (geocoder.getAutocomplete() !== props.autocomplete && props.autocomplete !== undefined) {
    //   geocoder.setAutocomplete(props.autocomplete);
    // }
    // if (geocoder.getFuzzyMatch() !== props.fuzzyMatch && props.fuzzyMatch !== undefined) {
    //   geocoder.setFuzzyMatch(props.fuzzyMatch);
    // }
    // if (geocoder.getRouting() !== props.routing && props.routing !== undefined) {
    //   geocoder.setRouting(props.routing);
    // }
    // if (geocoder.getWorldview() !== props.worldview && props.worldview !== undefined) {
    //   geocoder.setWorldview(props.worldview);
    // }
  }
  return marker
}

const noop = () => {}

GeocoderControl.defaultProps = {
  marker: true,
  onLoading: noop,
  onResults: noop,
  onResult: noop,
  onError: noop,
}
