import React, { useState, useEffect } from 'react'
import { AdminLayout } from 'components/Layout'
import { setTotal, setFilterForm, setData, getPayouts } from 'redux/payouts'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'

import PayoutFilter from './components/PayoutFilter'
import PayoutTable from './components/PayoutTable'

export default function PayoutPage() {
  const dispatch = useAppDispatch()
  const { filterForm, total, data } = useAppSelector(state => state.payouts)
  const [loading, setLoading] = useState(false)

  const handleFilterChange = (type: string, value: any) => {
    let newForm = { ...filterForm }
    if (type === 'sort') {
      newForm = {
        ...filterForm,
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
    } else {
      newForm = {
        ...filterForm,
        [type]: value,
      }
    }
    getTable({ ...newForm, page: 1 })
  }

  const onPageNoChange = (page: number) => {
    getTable({ ...filterForm, page })
  }

  const getTable = async (form: any) => {
    // 请求 api
    setLoading(true)
    try {
      dispatch(setFilterForm(form))
      const res = await getPayouts(form)
      dispatch(setTotal(res.total))
      dispatch(setData(res.data))
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <PayoutFilter
          filterForm={filterForm}
          handleFormChange={handleFilterChange}
        />
        <div className='mt-8'>
          <PayoutTable
            loading={loading}
            total={total}
            current={filterForm.page}
            pageSize={filterForm.per_page}
            data={data}
            onPageNoChange={onPageNoChange}
            handleFilterChange={handleFilterChange}
          />
        </div>
      </div>
    </AdminLayout>
  )
}
