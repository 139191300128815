import { useState, useEffect } from 'react'
import cn from 'classnames'
import { Plus, SolidTrashCan } from '@rushable/icons'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import Table from 'components/Table'
import Label from 'components/Label'
import Checkbox from 'components/Checkbox'
import type { TColumnProp } from 'components/Table'
import Button from 'components/Button'
import BadgeText from 'components/BadgeText'
import PersonName from 'components/PersonName'
import Drawer from 'components/Drawer'
import { Select } from 'components/Select'
import SingleInput from 'components/SingleInput'
import AlertText from 'components/AlertText'
import { DOMAIN } from 'data/constant'
import { CHOOSEITEMOPTIONS } from '../../helpers/constant'
import {
  createLogisticApi,
  updateLogisticApi,
  deleteLogisticApi,
  approveLogisticApi,
  syncEasyShipApi,
} from 'redux/account'

export type TPremiumAddOnTableProps = {
  onboardingId: string
  className?: string
  title?: string
  disabled?: boolean
  crm_closer: Record<string, any> // 当前用户
  items: any[] // 项目条数
  onSuccess: () => void
}

export default function PremiumAddOnTable({
  onboardingId,
  className,
  title,
  disabled,
  crm_closer,
  items,
  onSuccess,
}: TPremiumAddOnTableProps): JSX.Element | null {
  const profile = useAppSelector(state => state.auth.profile)
  const [show, setShow] = useState(false)
  const [curItem, setCurItem] = useState<Record<string, any>>({})
  const [team, setTeam] = useState('')
  const [easyShip, seteasyShip] = useState('')
  const [easyShipRes, seteasyShipRes] = useState('')
  const [billingChecked, setBillingChecked] = useState(false)
  const [loading, setLoading] = useState({
    submitLoading: false,
    syncLoading: false,
    approveLoading: false,
    deleteLoading: false,
  })

  useEffect(() => {
    if (!show) {
      setTeam('')
      seteasyShip('')
      seteasyShipRes('')
      setBillingChecked(false)
    }
    if (show && curItem.id) {
      setTeam(curItem.item)
    }
  }, [show])

  const getAvatar = (profile_photo: string) =>
    profile_photo && `${DOMAIN}${profile_photo}`

  const columns: TColumnProp[] = [
    {
      key: 'item',
      name: 'ITEM',
    },
    {
      key: 'request_by',
      name: 'REQUESTED BY',
      className: 'w-40',
      custom: (value: any) => {
        return (
          <PersonName
            className='!flex'
            avatar={getAvatar(value?.profile_photo)}
            name={`${value?.first_name || '-'} ${value?.last_name || ''}`}
          />
        )
      },
    },
    {
      key: 'approved_by',
      name: 'APPROVED BY',
      className: 'w-40',
      custom: (value: any) => {
        return (
          <PersonName
            className='!flex'
            avatar={getAvatar(value?.profile_photo)}
            name={`${value?.first_name || '-'} ${value?.last_name || ''}`}
          />
        )
      },
    },
    {
      key: 'shipped_by',
      name: 'SHIPPED BY',
      className: 'w-40',
      custom: (value: any) => {
        return (
          <PersonName
            className='!flex'
            avatar={getAvatar(value?.profile_photo)}
            name={`${value?.first_name || '-'} ${value?.last_name || ''}`}
          />
        )
      },
    },
    {
      key: 'easyship_tracking_status',
      name: 'LOGISTIC STATUS',
      align: 'right',
      custom: (value: string) => (
        <span className='capitalize'>{value || '-'}</span>
      ),
    },
  ]

  const handleSubmit = async () => {
    setLoading(prev => ({ ...prev, submitLoading: true }))
    try {
      const res = await createLogisticApi(onboardingId, team)
      toast.success(res.message)
      onSuccess()
      setShow(false)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, submitLoading: false }))
  }

  const handleUpdate = async () => {
    setLoading(prev => ({ ...prev, submitLoading: true }))
    try {
      const res = await updateLogisticApi(onboardingId, curItem.id, easyShip)
      toast.success(res.message)
      onSuccess()
      setShow(false)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, submitLoading: false }))
  }

  const handleSync = async () => {
    setLoading(prev => ({ ...prev, syncLoading: true }))
    try {
      const res = await syncEasyShipApi(easyShip)
      seteasyShipRes(`${res.destination} ${res.restaurant}`)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, syncLoading: false }))
  }

  const handleDelete = async () => {
    setLoading(prev => ({ ...prev, deleteLoading: true }))
    try {
      const res = await deleteLogisticApi(onboardingId, curItem.id)
      onSuccess()
      toast.success(res.message)
      setShow(false)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, deleteLoading: false }))
  }

  const handleApprove = async () => {
    setLoading(prev => ({ ...prev, approveLoading: true }))
    try {
      const res = await approveLogisticApi(curItem.id)
      onSuccess()
      toast.success(res.message)
      setShow(false)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, approveLoading: false }))
  }
  // 待审核
  const isNeedApprove = !!curItem?.id && !curItem.approved_by
  // 已审核
  const isApproved = !!curItem?.id && !!curItem.approved_by

  const getProfile = (info: any) => {
    const { profile_photo, first_name, last_name } = info || {}
    return {
      img_url: profile_photo ? `${DOMAIN}${profile_photo}` : null,
      label: `${first_name || ''} ${last_name || ''}`,
    }
  }

  const getDrawerBtn = () => {
    const btnProps = {
      okText: 'SUBMIT REQUEST',
      okColor: 'primary',
      okDisabled: true,
      isCancelBtn: true,
      onOK: handleSubmit,
    }
    if (!curItem.id) {
      return {
        ...btnProps,
        okDisabled: !team,
      }
    }
    if (isNeedApprove) {
      return {
        ...btnProps,
        okText: 'CLOSE',
        okColor: 'secondary',
        isCancelBtn: false,
        okDisabled: false,
        onOk: () => setShow(false),
      }
    }
    if (isApproved) {
      return {
        ...btnProps,
        okText: 'UPDATE',
        okDisabled: !easyShip,
        onOK: handleUpdate,
      }
    }
  }
  const drawerBtn = getDrawerBtn()!
  return (
    <>
      <div className={cn('ru-premium-addon-table', className)}>
        <div className='flex justify-between mb-2'>
          <Label>{title} </Label>
          <Button
            theme='text'
            size='small'
            color='secondary'
            disabled={disabled}
            onClick={() => {
              setCurItem({})
              setShow(true)
            }}
            iconBefore={<Plus size={12} />}
          >
            ADD LOGISTIC ITEM
          </Button>
        </div>
        <div className={cn('w-full py-2 border border-dark-100 rounded-lg')}>
          <Table
            columns={columns}
            data={items || []}
            clickedRow={row => {
              if (disabled) {
                return
              }
              setCurItem(row)
              seteasyShip(row.easyship_tracking_id || '')
              setShow(true)
            }}
          />
        </div>
      </div>
      <Drawer
        title='ADD LOGISTIC ITEM'
        okText={drawerBtn.okText}
        cancelText='CLOSE'
        isCancelBtn={drawerBtn.isCancelBtn}
        okButtonProps={{
          disabled: drawerBtn.okDisabled,
          color: drawerBtn.okColor,
          loading: loading.submitLoading,
        }}
        onOk={drawerBtn.onOK}
        open={show}
        toggle={() => setShow(!show)}
        footerLeftElement={
          <>
            {isNeedApprove && (
              <div className='flex'>
                <Button
                  color='warning'
                  theme='text'
                  loading={loading.deleteLoading}
                  iconBefore={<SolidTrashCan size={12} />}
                  onClick={handleDelete}
                >
                  DELETE
                </Button>
                <AlertText
                  className='ml-6'
                  color='yellow'
                  text='Pending Approval'
                />
              </div>
            )}
          </>
        }
      >
        <div className='w-full h-full flex space-y-6 flex-col'>
          <Select
            disabled
            isPerson
            label='REQUESTER'
            onChange={() => {}}
            placeholder=''
            value='1'
            options={[
              {
                label: curItem.id
                  ? getProfile(curItem.request_by).label
                  : profile?.userName!,
                img_url: curItem.id
                  ? getProfile(curItem.request_by).img_url
                  : profile?.userPhoto,
                value: '1',
              },
            ]}
          />
          <Select
            label='CHOOSE ITEM'
            onChange={value => setTeam(value)}
            options={CHOOSEITEMOPTIONS}
            placeholder='Select'
            disabled={!!curItem.id}
            value={team}
          />
          {isApproved && (
            <Select
              disabled
              isPerson
              label='APPROVED BY'
              onChange={() => {}}
              placeholder=''
              value='1'
              options={[{ ...getProfile(curItem.approved_by), value: '1' }]}
            />
          )}
          {/* 编辑/未审核 状态下 有审核权限的人才能看到 */}
          {isNeedApprove && (
            <>
              <Checkbox
                checked={billingChecked}
                onChange={e => setBillingChecked(e.target.checked)}
              >
                Billing handled
              </Checkbox>
              <Button
                color='secondary'
                loading={loading.approveLoading}
                disabled={!billingChecked}
                onClick={handleApprove}
              >
                APPROVE FOR SHIPPING
              </Button>
            </>
          )}

          {/* approve 之后才会显示此按钮 */}
          {isApproved && (
            <div>
              <div className='flex space-x-4 items-end mb-2'>
                <SingleInput
                  className='flex-1'
                  name='EASYSHIP'
                  label='EASYSHIP'
                  placeholder='Input'
                  value={easyShip}
                  onChange={e => seteasyShip(e.target.value)}
                />
                <Button
                  className='min-w-[72px] relative bottom-0.5'
                  color='secondary'
                  theme='outlined'
                  disabled={!easyShip}
                  loading={loading.syncLoading}
                  onClick={handleSync}
                >
                  SYNC
                </Button>
              </div>
              {easyShipRes && <BadgeText color='default' value={easyShipRes} />}
            </div>
          )}
        </div>
      </Drawer>
    </>
  )
}
