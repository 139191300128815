import cn from 'classnames'
import { SolidUserCircled } from '@rushable/icons'
import { DOMAIN } from 'data/constant'

export type TPersonNameProps = {
  className?: string
  avatarSize?: number
  avatar?: string
  name?: string
  hasName?: boolean
  hasAvatar?: boolean
}

export default function PersonName({
  className,
  avatarSize = 24,
  avatar,
  name,
  hasName = true,
  hasAvatar = true,
}: TPersonNameProps): JSX.Element {
  const needDOMAIN = avatar?.indexOf(DOMAIN!) === -1
  const size = { width: avatarSize + 'px', height: avatarSize + 'px' }
  return (
    <div className={cn('ru-PersonName inline-flex items-center', className)}>
      {hasAvatar ? (
        avatar ? (
          <div className='rounded-full overflow-hidden' style={size}>
            <img
              className='object-cover'
              src={needDOMAIN ? `${DOMAIN}${avatar}` : avatar}
              alt=''
            />
          </div>
        ) : (
          <div className='rounded-full overflow-hidden flex justify-center items-center'>
            <SolidUserCircled className='text-light-300' size={avatarSize} />
          </div>
        )
      ) : null}
      {hasName && (
        <div
          className={cn('flex-1 text-sm text-light-500 text-ellipsis', {
            'pl-2': hasAvatar,
          })}
        >
          {name}
        </div>
      )}
    </div>
  )
}
