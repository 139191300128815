import axiosInstance from 'utils/axios'
import { createAction } from '@reduxjs/toolkit'

export const setPartFilterForm = createAction<any>(
  'affiliate/setPartFilterForm',
)
export const setAllFilterForm = createAction<any>('affiliate/setAllFilterForm')

export async function getAffiliateTeam(params: any) {
  const response = await axiosInstance.request({
    url: 'affiliate/teams',
    method: 'get',
    params,
    cache: {
      ignoreCache: false,
    },
  })
  return response.data
}

export async function getAffiliateCRMTeams(
  id: string | undefined,
  params: any,
) {
  const response = await axiosInstance.request({
    url: `affiliate/crm_teams/${id}/accounts`,
    method: 'get',
    params,
    cache: {
      ignoreCache: false,
    },
  })
  return response.data
}

export async function getCommission(id?: string | number, params?: any) {
  const response = await axiosInstance.request({
    url: `affiliate/crm_teams/${id}/commissions`,
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function handleClaim(id?: string | number, data?: any) {
  const response = await axiosInstance.request({
    url: `affiliate/crm_teams/${id}/claim`,
    method: 'post',
    data,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
