import cn from 'classnames'

type Tkey = '1' | '2' | '3' | '4'

export type TSaleProp = {
  className?: string
  weeklySales: number[] | Record<Tkey, number> // 后端返回数据 每周收入涨幅
}

export default function SaleComparison({
  className,
  weeklySales,
}: TSaleProp): JSX.Element {
  const getItem = (sales: number) => {
    /**
     * 四个格子
     * green   >= 40
     * green   >= 20
     * red     <= -20
     * red     <= -40
     */
    return (
      <>
        {new Array(4).fill('').map((_, index) => {
          let bgColor = 'bg-light-100'
          if (index === 0 && sales >= 40) {
            bgColor = 'bg-green'
          }
          if (index === 1 && sales >= 20) {
            bgColor = 'bg-green'
          }
          if (index === 2 && sales <= -20) {
            bgColor = 'bg-red'
          }
          if (index === 3 && sales <= -40) {
            bgColor = 'bg-red'
          }
          return (
            <div
              key={index}
              className={cn('w-3 h-3px rounded mb-0.5 last:mb-0', bgColor)}
            ></div>
          )
        })}
      </>
    )
  }

  let weeks: number[] = []
  if (Array.isArray(weeklySales)) {
    weeks = weeklySales
  } else {
    weeks = ['1', '2', '3', '4'].map(item => weeklySales[item as Tkey] || 0)
  }
  return (
    <div className={cn('flex', className)}>
      {weeks.map((sales, index) => {
        return (
          <div className='flex flex-col mr-1 last:mr-0' key={index}>
            {getItem(sales)}
          </div>
        )
      })}
    </div>
  )
}
