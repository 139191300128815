import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from '@rushable/icons'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getAccountDetailApi, emptyAccountDetail } from 'redux/account'
import { getAccess } from 'redux/common'
import { AdminLayout, AdminPageTitle } from 'components/Layout'
import Button from 'components/Button'
import Tabs from 'components/Tabs'
import AccountSideBar from './components/AccountSideBar'
import SignupTab from './tabPage/SignupTab'
import Success from './tabPage/SuccessTab'
import OnboardingTab from './tabPage/OnboardingTab'
import VaultTab from './tabPage/VaultTab'
import MenuTab from './tabPage/MenuTab'
import { Ttabstatus } from './helpers/constant'

export default function AccountDetailPage() {
  const params = useParams()
  const isCreate = params.id === 'create'
  const dispatch = useAppDispatch()
  const [curStatus, setCurStatus] = useState<Ttabstatus>() // 控制左边栏状态
  const [prevTab, setPrevTab] = useState<Ttabstatus>()
  const [curTab, setCurTab] = useState<Ttabstatus>()
  const [accessLoading, setAccessLoading] = useState(false)
  const { detail, initLoading } = useAppSelector(state => state.account)
  const authMap = useAppSelector(s => s.auth.authMap)

  useEffect(() => {
    if (!isCreate) {
      dispatch(getAccountDetailApi(params.id!))
    }
    return () => {
      // 离开detail页面清空redux内的相关数据
      dispatch(emptyAccountDetail())
    }
  }, [])

  useEffect(() => {
    // Signup: draft、signup、withdrawn
    // Onboarding: onboarding  canceled
    // Success: live
    // terminate状态：canceled、withdrawn
    if (['onboarding', 'canceled'].includes(detail.status)) {
      setCurTab('Onboarding')
      setCurStatus('Onboarding')
    }
    if (detail.status === 'live') {
      setCurTab('Success')
      setCurStatus('Success')
    }
    if (['draft', 'signup', 'withdrawn'].includes(detail.status)) {
      setCurTab('Signup')
      setCurStatus('Signup')
    }
  }, [detail])

  const getAccessLink = async () => {
    setAccessLoading(true)
    try {
      const res = await getAccess({ location_id: detail?.location_id })
      window.open(res.redirect)
    } catch (error: any) {
      toast.error(error.message)
    }
    setAccessLoading(false)
  }

  if (isCreate) {
    return <SignupTab type='form' />
  }
  // 权限不为 rushable 或者 处在 signup 阶段的显示此 UI
  if (
    ['draft', 'signup', 'withdrawn'].includes(detail.status) ||
    (authMap && authMap.type !== 'rushable')
  ) {
    return <SignupTab type='form' signupId={detail.id} accountDetail={detail} />
  }

  return (
    <AdminLayout showNavs={false}>
      <AdminPageTitle
        title='ACCOUNT DETAIL'
        right={
          <>
            {!initLoading && (
              <Button loading={accessLoading} onClick={() => getAccessLink()}>
                DASHBOARD
              </Button>
            )}
          </>
        }
      />
      {initLoading || !curTab ? (
        <div className='flex mt-20 justify-center'>
          <Loading size={24} />
        </div>
      ) : (
        <div className='flex'>
          <AccountSideBar type={curStatus!} data={detail} />
          <div className='flex-1 mt-4 mb-10 px-10'>
            <Tabs
              tabClass='mb-10'
              activeKey={curTab}
              isCache={false}
              onChange={value => {
                setPrevTab(curTab)
                setCurTab(value)
              }}
            >
              <Tabs.Panel tabKey='Signup' tab='Signup'>
                <SignupTab
                  type='tabs'
                  signupId={detail.id}
                  accountDetail={detail}
                />
              </Tabs.Panel>
              <Tabs.Panel tabKey='Onboarding' tab='Onboarding'>
                <OnboardingTab />
              </Tabs.Panel>
              <Tabs.Panel tabKey='Success' tab='Success'>
                <Success />
              </Tabs.Panel>
              <Tabs.Panel tabKey='Menu' tab='Menu'>
                <MenuTab />
              </Tabs.Panel>
              <Tabs.Panel tabKey='Vault' tab='Vault'>
                <VaultTab onClose={() => setCurTab(prevTab)} />
              </Tabs.Panel>
            </Tabs>
          </div>
        </div>
      )}
    </AdminLayout>
  )
}
