import { useState } from 'react'
import { Select, BrandSelect } from 'components/Select'
import { IOptions } from 'components/Select/help'

import { TFilterForm, statusOptions } from '../helpers/constant'

export type TSearchFilterProps = {
  filterForm: TFilterForm
  handleFormChange: (type: string, value: string | number) => void
}

export default function SearchFilter({
  filterForm,
  handleFormChange,
}: TSearchFilterProps) {
  const [locationOptions, setLocationOptions] = useState<IOptions[]>([
    { value: '', label: 'All Locations' },
  ])

  const { status, brand_name, location_id } = filterForm

  const onChange = (item: any, rest: any) => {
    const { locations } = rest
    handleFormChange('brand_name', item)
    setLocationOptions(
      [{ value: '', label: 'All Locations' }].concat(
        locations.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          }
        }),
      ),
    )
  }

  return (
    <div className='mb-8 flex space-x-4'>
      <Select
        className='w-216'
        options={statusOptions}
        value={status}
        onChange={value => handleFormChange('status', value)}
      />
      <BrandSelect
        className='w-216'
        placeholder='Search'
        value={brand_name}
        apiType='disputes'
        isShowLocation={false}
        onChange={onChange}
      />
      {brand_name && brand_name !== 'All Brands' ? (
        <Select
          key={location_id}
          className='w-216'
          options={locationOptions}
          value={location_id}
          onChange={value => handleFormChange('location_id', value)}
        />
      ) : null}
    </div>
  )
}
