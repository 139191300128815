import { useState } from 'react'
import { Select, BrandSelect } from 'components/Select'
import { RangePicker } from 'components/DatePicker'
import { IOptions } from 'components/Select/help'
import { Loading } from '@rushable/icons'

import {
  TFilterForm,
  statusOptions,
  filterTypeOptions,
} from '../helpers/constant'

export type TSearchFilterProps = {
  autoLoading: boolean
  filterForm: TFilterForm
  handleFormChange: (type: string, value: string | number) => void
}

export default function SearchFilter({
  autoLoading,
  filterForm,
  handleFormChange,
}: TSearchFilterProps) {
  const [open, setOpen] = useState(false)
  const [locationOptions, setLocationOptions] = useState<IOptions[]>([
    { value: '', label: 'All Locations' },
  ])

  const { status, brand_name, location_id, types } = filterForm
  const onChange = (item: any, rest: any) => {
    const { locations } = rest
    handleFormChange('brand_name', item)
    if (locations && locations.length > 0) {
      setLocationOptions(
        [{ value: '', label: 'All Locations' }].concat(
          locations.map((loc: any) => {
            return {
              label: loc.name,
              value: loc.id,
            }
          }),
        ),
      )
    }
  }

  return (
    <div className='mb-8 mr-4 flex items-center justify-between'>
      <div className='flex space-x-4'>
        <Select
          className='w-216'
          options={statusOptions}
          value={status}
          onChange={value => handleFormChange('status', value)}
        />
        <BrandSelect
          className='w-216'
          placeholder='Search'
          value={brand_name}
          apiType='orders'
          isShowLocation={false}
          onChange={onChange}
        />
        {brand_name && brand_name !== 'All Brands' ? (
          <Select
            key={location_id}
            className='w-216'
            options={locationOptions}
            value={location_id}
            onChange={value => handleFormChange('location_id', value)}
          />
        ) : null}
        <Select
          className='w-216'
          options={filterTypeOptions}
          value={types || ''}
          onChange={value => handleFormChange('types', value)}
        />
        <RangePicker
          className='w-256'
          open={open}
          onOpenChange={flag => {
            setOpen(flag)
          }}
          onCalendarChange={(value: any) => {
            if (
              !value ||
              (!!value?.[0] && value?.[1]) ||
              (value?.[0] && !!value?.[1])
            ) {
              handleFormChange('dates', value)
            }
          }}
        />
      </div>
      {autoLoading && <Loading />}
    </div>
  )
}
