import React from 'react'
import { SolidChatQuestion } from '@rushable/icons'
import ConditionsItemForm from './ConditionsItemForm'
import { OnboardingIssueOptions } from '../help/constant'
import type { TFilterItem } from '../help/constant'

export default function OnboardingIssueFilter({
  className,
  value,
  onChange,
}: TFilterItem) {
  return (
    <ConditionsItemForm
      className={className}
      icon={<SolidChatQuestion size={16} className='text-light-100' />}
      label='Onboarding issue'
      value={value}
      options={OnboardingIssueOptions}
      onChange={onChange}
    />
  )
}
