import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axios'
import { formatParamsFormData } from 'utils/tools'

export const getProfile = createAsyncThunk('auth/profile', async () => {
  const response = await axiosInstance.request({
    url: 'profile',
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
})

export interface Teditprofile {
  photo?: Blob
  call_link?: string
  old_password?: string
  new_password?: string
  new_password_confirmation?: string
}
export async function updateProfileApi(params: Teditprofile) {
  const response = await axiosInstance.request({
    url: 'profile/edit',
    data: formatParamsFormData(params),
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export async function loginApi(email: string, password: string) {
  const response = await axiosInstance.request({
    url: 'login',
    data: { email, password },
    method: 'post',
  })
  return response.data
}

export async function logOutApi() {
  const response = await axiosInstance.request({
    url: 'logout',
    method: 'post',
  })
  return response.data
}
