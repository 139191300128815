import { useEffect, useState } from 'react'
import moment from 'moment'
import currency from 'currency.js'
import Modal from 'components/Modal'
import Button from 'components/Button'
import SingleInput from 'components/SingleInput'
import BadgeText from 'components/BadgeText'
import { chargeNowApi } from 'redux/account'

export type Tprops = {
  disabled?: boolean
  onboardingId: string
  payment: Record<string, any>
  onSuccess: () => void
}

export default function SetupFeeModal({
  disabled,
  onboardingId,
  payment,
  onSuccess,
}: Tprops) {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState({ msg: '', status: '' })

  const { signup_fee, discount } = payment || {}
  const chargeFee = currency(signup_fee).subtract(discount)
  const chargeDesc = +signup_fee == 299 ? 'delayed' : 'launch'
  const modalChargeDesc = +signup_fee == 299 ? '⏰ Delayed' : 'At Launch'

  useEffect(() => {
    if (!show) {
      setResult({ msg: '', status: '' })
    }
  }, [show])
  const handleSubmit = async () => {
    setLoading(true)
    try {
      const res = await chargeNowApi(onboardingId, chargeFee.value)
      setResult({ msg: res.message, status: 'success' })
      onSuccess()
    } catch (error: any) {
      setResult({ msg: error.message, status: 'error' })
    }
    setLoading(false)
  }

  return (
    <>
      {payment.paid !== 1 && (
        <Button
          className='flex-1'
          color='secondary'
          disabled={disabled}
          onClick={() => setShow(true)}
        >
          CHARGE {chargeFee.format()}({chargeDesc})
        </Button>
      )}
      <Modal
        open={show}
        toggle={() => setShow(!show)}
        title='CHARGE SETUP FEE'
        isCancelBtn={false}
        okText='CLOSE'
        okButtonProps={{ color: 'secondary' }}
        onOk={() => setShow(false)}
      >
        <div className='w-full text-left'>
          <div className='flex space-x-4'>
            <SingleInput
              name='SETUP_FEE'
              label='SETUP FEE'
              placeholder='Input'
              value={chargeFee.format()}
              disabled={true}
              onChange={e => e}
            />
            <SingleInput
              name='SETUP_FEE'
              label='CHARGING RULE'
              placeholder=''
              value={modalChargeDesc}
              disabled={true}
              onChange={e => e}
            />
            <SingleInput
              name='SINCE_SIGNUP'
              label='SINCE SIGNUP'
              placeholder='Input'
              value={moment().diff(payment.signup_at || '', 'days') + ' days'}
              disabled={true}
              onChange={e => e}
            />
          </div>
          {result.status !== 'success' && (
            <Button
              className='w-full mt-6'
              color='secondary'
              theme='outlined'
              loading={loading}
              onClick={handleSubmit}
            >
              CHARGE NOW
            </Button>
          )}

          <BadgeText
            className='w-full mt-6 flex'
            color={result.status === 'error' ? 'red' : 'green'}
            value={result.msg}
          />
        </div>
      </Modal>
    </>
  )
}
