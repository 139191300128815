import { useState, useEffect } from 'react'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import ParagraphInput from 'components/ParagraphInput'
import SingleInput from 'components/SingleInput'
import Label from 'components/Label'
import Button from 'components/Button'
import { UploadFile } from 'components/Upload'
import MenuItem from './MenuItem'
import MenuCollection from './MenuCollection'
import {
  getMenuInfoApi,
  uploadMenuFileApi,
  updateMenuApi,
  removeMenuFileApi,
  syncLinkApi,
  menuExportApi,
} from 'redux/account'

export default function AccountsMenuTab() {
  const urlParams = useParams()
  const [menus, setMenus] = useState<any[]>([])
  const [exportMenus, setExportMenus] = useState<any[]>([])
  const [collections, setCollections] = useState<any[]>([])
  const [uploadLoading, setUploadLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [hasMenu, setHasMenu] = useState(false)
  const [note, setNote] = useState({
    value: '',
    disabled: true,
    loading: false,
  })
  const [link, setLink] = useState({
    value: '',
    loading: false,
  })

  useEffect(() => {
    getMenuInfo()
  }, [])

  const getMenuInfo = async () => {
    try {
      const res = await getMenuInfoApi(urlParams.id!)
      if (res.menu_link) {
        const item = {
          id: 'preset',
          type: 'link',
          src: res.menu_link,
          link: res.menu_link,
        }
        const menus =
          res.onboarding_menus?.map((v: any) => ({
            id: v.id,
            type: 'file',
            src: v.file_name,
            link: v.preview_link,
          })) || []
        setMenus([item, ...menus])
      } else {
        setMenus(res.onboarding_menus || [])
      }

      setNote(prev => ({ ...prev, value: res.menu_work_notes || '' }))
      const exportMenus =
        res.onboarding_menu_imports?.map((v: any) => ({
          id: v.id,
          type: 'file',
          src: v.link,
        })) || []
      setExportMenus(exportMenus)
      setHasMenu(res?.has_menu || false)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const uploadMenuFile = async (file: Blob) => {
    setUploadLoading(true)
    try {
      const res = await uploadMenuFileApi(urlParams.id!, file)
      toast.success(res.message)
      getMenuInfo()
    } catch (error: any) {
      toast.error(error.message)
    }
    setUploadLoading(false)
  }

  const onDeleteFile = (item: any, index: number) => {
    console.log('delete:', item)
    menus[index].loading = true
    setMenus([...menus])
    if (item.id === 'preset') {
      updateMenu({ menu_link: null })
    } else {
      deleteMenuFile(item.id)
    }
  }

  const handleEditNotes = async () => {
    if (note.disabled) {
      setNote(prev => ({ ...prev, disabled: false }))
    } else {
      setNote(prev => ({ ...prev, loading: true }))
      updateMenu({
        menu_work_notes: note.value,
      })
    }
  }

  // 更新 menu
  const updateMenu = async (params: any) => {
    try {
      const res = await updateMenuApi(urlParams.id!, params)
      toast.success(res.message)
      getMenuInfo()
    } catch (error: any) {
      toast.error(error.message)
    }
    setNote(prev => ({ ...prev, loading: false }))
  }

  const deleteMenuFile = async (id: string) => {
    try {
      const res = await removeMenuFileApi(urlParams.id!, id)
      toast.success(res.message)
      getMenuInfo()
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handleSync = async () => {
    setLink(prev => ({ ...prev, loading: true }))
    try {
      const res = await syncLinkApi(urlParams.id!, link.value)
      console.log('res-->', res)
      setCollections(res || [])
      toast.success(res.message)
    } catch (error: any) {
      toast.error(error.message)
    }
    setLink(prev => ({ ...prev, loading: false }))
  }

  const exportMenu = async () => {
    setExportLoading(true)
    try {
      const res = await menuExportApi(urlParams.id!)
      window.open(res.folder)
    } catch (error: any) {
      toast.error(error.message)
    }
    setExportLoading(false)
  }

  return (
    <div className={cn('ru-AccountsMenuTab flex space-x-10')}>
      <div className='flex-1 w-1/2'>
        <div>
          <Label>MENU UPLOADS</Label>
          {menus.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                item={item}
                deleteLoading={item.loading}
                onDeleteFile={() => onDeleteFile(item, index)}
              />
            )
          })}
          <UploadFile
            className='mt-4'
            onUpload={file => {
              uploadMenuFile(file)
              console.log(file)
            }}
          >
            <Button color='secondary' loading={uploadLoading}>
              ADD MENU
            </Button>
          </UploadFile>
        </div>
        <div className='mt-8'>
          <ParagraphInput
            name='ADDITIONAL_NOTES'
            label='ADDITIONAL NOTES'
            placeholder='Input'
            value={note.value}
            disabled={note.disabled}
            onChange={e =>
              setNote(prev => ({ ...prev, value: e.target.value }))
            }
          />
          <Button
            color='secondary'
            loading={note.loading}
            className='mt-4'
            onClick={handleEditNotes}
          >
            {note.disabled ? 'EDIT NOTES' : 'CONFIRM'}
          </Button>
        </div>
      </div>
      <div className='flex-1'>
        <Label className='mb-2'>MENU IMPORT</Label>
        <div className='flex space-x-4 items-end'>
          <SingleInput
            name='MENU_IMPORT'
            placeholder='Input gsheet link'
            value={link.value}
            onChange={e =>
              setLink(prev => ({ ...prev, value: e.target.value }))
            }
          />
          <Button
            color='secondary'
            theme='outlined'
            disabled={!link.value}
            loading={link.loading}
            className='min-w-72'
            onClick={handleSync}
          >
            SYNC
          </Button>
        </div>
        <MenuCollection
          url={link.value}
          collections={collections}
          onImport={() => {
            getMenuInfo()
            setCollections([])
            setLink({ value: '', loading: false })
          }}
        />

        {hasMenu && (
          <>
            <div className='my-8 border-b border-dashed border-dark-100' />
            <div>
              <Label className='mb-2'>MENU EXPORT</Label>
              <div className='flex flex-col space-y-2'>
                {exportMenus.map((item: any, index: number) => {
                  return <MenuItem key={index} item={item} hasDelete={false} />
                })}
              </div>
              <Button
                className='mt-2 w-full'
                color='secondary'
                theme='outlined'
                loading={exportLoading}
                onClick={exportMenu}
              >
                EXPORT MENU
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
