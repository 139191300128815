import React from 'react'
import currency from 'currency.js'

import Table from 'components/Table'
import DateAndTime from 'components/DateAndTime'
import Pricing from 'components/Pricing'
import StageProgress from 'components/StageProgress'

import type { TRowProp, TNowParams } from '../helpers/constant'
import { SOURCEMAP } from 'utils/constant'

export type TBonusCalculationTableProp = {
  className?: string
  data: Array<TRowProp>
  total: number
  loading?: boolean
  page?: number
  pageSize?: number
  tabKey: string
  sortData: {
    sort_by: string
    sort: string
  }
  handleGetList: (params: TNowParams) => void
}

export default function BonusCalculationTable({
  className = '',
  loading,
  data,
  total,
  page,
  pageSize,
  tabKey,
  sortData,
  handleGetList,
}: TBonusCalculationTableProp): JSX.Element {
  const { sort_by, sort } = sortData
  const baseColumns = [
    {
      key: 'location',
      name: 'BRAND',
      custom: (location: any) => {
        return (
          <div className='text-ellipsis'>{location?.brand?.name || ''}</div>
        )
      },
    },
    {
      key: 'location',
      name: 'LOCATION',
      custom: (location: any) => {
        return <div className='text-ellipsis'>{location?.name || ''}</div>
      },
    },
    {
      key: 'signup_form',
      name: 'SOURCE',
      sortable: true,
      sortDirection: sort_by === 'source' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'source' : '',
            sort: value,
          },
        })
      },
      className: 'w-110',
      custom: (value: any) => {
        const { source } = value
        // @ts-ignore
        return <div className='text-ellipsis'>{SOURCEMAP[source] || '-'}</div>
      },
    },
  ]

  const pricingColumns = [
    {
      key: 'pricing',
      name: 'PRICING',
      align: 'right',
      className: 'w-176',
      custom: (value: any, item: any) => {
        return (
          <div className='flex justify-end'>
            <Pricing
              store={item.price ? currency(item.price).format() : '-'}
              userGroups={
                item.convenience_fee
                  ? currency(item.convenience_fee).format()
                  : '-'
              }
            />
          </div>
        )
      },
    },
  ]

  const volumeColumns = [
    {
      key: 'volume_amount',
      name: 'VOLUME',
      sortable: true,
      sortDirection: sort_by === 'volume' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'volume' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      className: 'w-104',
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value, { precision: 0 }).format()}
          </div>
        )
      },
    },
  ]

  const setupColumns = [
    {
      key: 'setup',
      name: 'SETUP',
      sortable: true,
      sortDirection: sort_by === 'setup' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'setup' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      className: 'w-104',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
    },
  ]

  const signUpColumns = [
    {
      key: 'signup_form',
      name: 'SIGNUP',
      sortable: true,
      sortDirection: sort_by === 'signup' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'signup' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      className: 'w-120',
      custom: (value: any) => {
        const { ready_at } = value
        // signup_form ready_at
        return (
          <div className='flex justify-end'>
            {ready_at ? (
              <DateAndTime
                className='text-right text-light-500'
                value={ready_at}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
                noExpired={true}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
  ]
  const launchColumns = [
    {
      key: 'onboarding',
      name: 'LAUNCH',
      sortable: true,
      sortDirection: sort_by === 'launch' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'launch' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      className: 'w-120',
      custom: (value: any) => {
        // onboarding done_at
        const { done_at } = value
        return (
          <div className='flex justify-end'>
            {done_at ? (
              <DateAndTime
                className='text-right text-light-500'
                value={done_at}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
                noExpired={true}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
  ]
  const cancelColumns = [
    {
      key: 'location',
      name: 'CANCEL',
      sortable: true,
      sortDirection: sort_by === 'cancel' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'cancel' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      className: 'w-120',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            <DateAndTime
              className='text-right text-light-500'
              value={value?.stripe_subscription_ends_at || ''}
              parse='YYYY-MM-DD HH:mm:ss'
              format='MMM DD, YYYY'
              noExpired={true}
            />
          </div>
        )
      },
    },
  ]

  const stageColumns = [
    {
      key: 'stage',
      name: 'STAGE',
      className: 'w-120',
      tdNoHidden: true,
      sortable: true,
      sortDirection: sort_by === 'stage' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'stage' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      custom: (value: any, row: any) => {
        return (
          <StageProgress
            className='ml-2'
            statusName={value}
            popTop={row.index >= 8}
            onboarding={row.onboarding}
            signupForm={row.signup_form}
          />
        )
      },
    },
  ]

  const columns = (): any => {
    switch (tabKey) {
      case '1':
        return [
          ...baseColumns,
          ...pricingColumns,
          ...volumeColumns,
          ...signUpColumns,
          ...stageColumns,
        ]
      case '2':
        return [
          ...baseColumns,
          ...pricingColumns,
          ...setupColumns,
          ...launchColumns,
          ...stageColumns,
        ]
      case '3':
        return [
          ...baseColumns,
          ...signUpColumns,
          ...launchColumns,
          ...cancelColumns,
          ...stageColumns,
        ]
    }
  }

  return (
    <div className='mt-8'>
      <Table
        loading={loading}
        current={page}
        columns={columns()}
        data={data}
        total={total}
        resizePageSize={pageSize}
        onPageNoChange={value =>
          handleGetList({
            nowPage: value,
          })
        }
      />
    </div>
  )
}
