import { ReactElement, useId } from 'react'
import cn from 'classnames'
import { Loading } from '@rushable/icons'

export type TSwitchProp = {
  className?: string
  labelClass?: string
  children?: string | ReactElement
  checked?: boolean
  disabled?: boolean
  loading?: boolean
  onChange?: (e: TInputEvent) => void
}

export default function Switch({
  className = '',
  labelClass,
  children = '',
  disabled,
  checked,
  loading,
  onChange,
}: TSwitchProp): JSX.Element {
  const labelId = useId()

  return (
    <div className={cn('flex items-center', className)}>
      {loading ? (
        <span className='w-9 h-5 flex justify-center'>
          <Loading size={20} />
        </span>
      ) : (
        <span className='relative flex'>
          <input
            className={cn(
              'w-9 h-5 rounded-3xl appearance-none cursor-pointer',
              checked ? 'bg-blue' : 'border-2 border-dark-100 bg-transparent',
              disabled ? 'opacity-50 cursor-not-allowed' : '',
            )}
            id={labelId}
            type='checkbox'
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          <span
            className={cn(
              'rounded-full absolute w-3 h-3 top-1/2 translate-y-50 pointer-events-none duration-150',
              checked ? 'bg-white left-5' : 'bg-dark-100 left-1',
            )}
          />
        </span>
      )}
      {children && (
        <label
          className={cn(
            'pl-1.5 cursor-pointer text-light-700 whitespace-nowrap',
            labelClass,
          )}
          htmlFor={labelId}
        >
          {children}
        </label>
      )}
    </div>
  )
}
