export const getDaasType = (daas_type: string) => {
  var before = ''
  switch (daas_type) {
    case 'doordash':
      before = 'DD-'
      break
    default:
      break
  }
  return before
}

export const getReference = (type: string, daas_delivery: any) => {
  let str = '-'
  if (type === 'delivery') {
    str = 'In-house'
  }
  if (type === 'daas_delivery') {
    if (!daas_delivery) {
      str = 'On-demand'
    }
    if (daas_delivery) {
      if (daas_delivery.type) {
        str = getDaasType(daas_delivery.type) + daas_delivery.external_id ?? ''
      }
    }
  }
  return str
}
