import React from 'react'
import cn from 'classnames'

export type TStageIndicator = {
  className?: string
  stages: (
    | 'draft'
    | 'signing'
    | 'onboarding'
    | 'live'
    | 'cancelling'
    | 'canceled'
  )[]
}

export default function StageIndicator({
  className,
  stages,
}: TStageIndicator): JSX.Element {
  return (
    <div className={cn('flex', className)}>
      <div
        className={cn(
          'w-2 h-2 rounded-full',
          stages.indexOf('draft') > -1 ? 'bg-light-700' : 'bg-dark-100',
        )}
      />
      <div
        className={cn(
          'w-2 h-2 rounded-full ml-1',
          stages.indexOf('signing') > -1 ? 'bg-orange' : 'bg-dark-100',
        )}
      />
      <div
        className={cn(
          'w-2 h-2 rounded-full ml-1',
          stages.indexOf('onboarding') > -1 ? 'bg-blue' : 'bg-dark-100',
        )}
      />
      <div
        className={cn(
          'w-2 h-2 rounded-full ml-1',
          stages.indexOf('live') > -1 ? 'bg-green' : 'bg-dark-100',
        )}
      />
      <div
        className={cn(
          'w-2 h-2 rounded-full ml-1',
          stages.indexOf('cancelling') > -1 ? 'bg-yellow' : 'bg-dark-100',
        )}
      />
      <div
        className={cn(
          'w-2 h-2 rounded-full ml-1',
          stages.indexOf('canceled') > -1 ? 'bg-red' : 'bg-dark-100',
        )}
      />
    </div>
  )
}
