import { useState, ReactElement } from 'react'
import Cropper from 'react-cropper'
import cn from 'classnames'
import Modal from 'components/Modal'
import 'cropperjs/dist/cropper.css'

interface Iprops {
  className?: string
  title?: string
  children: ReactElement
  onUpload?: (file: Blob) => void // 直接返回 blob 对象
  onUploadApi?: (file: Blob) => Promise<any> // 接收 api 调用自动处理 loading
}

export default function UploadCrop({
  children,
  title,
  className,
  onUpload,
  onUploadApi,
}: Iprops) {
  const [showModal, setShowModal] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [image, setImage] = useState<any>(null)
  const [cropper, setCropper] = useState<any>()

  const onChange = (e: TInputEvent) => {
    e.preventDefault()
    const files = e.target.files
    const reader = new FileReader()
    reader.onload = () => setImage(reader.result)
    reader.readAsDataURL(files![0])
    setShowModal(true)
    // 防止同一个文件重复上传不生效
    e.target.value = ''
  }

  const getCropData = () => {
    if (typeof cropper === 'undefined') {
      return
    }
    const cropCanvas = cropper.getCroppedCanvas()
    cropCanvas.toBlob((blob: Blob) => {
      if (onUpload) {
        onUpload(blob)
        setShowModal(false)
      }
      if (onUploadApi) {
        setShowLoading(true)
        onUploadApi(blob)
          .then(res => setShowModal(false))
          .finally(() => setShowLoading(false))
      }
    }, 'image/png')
  }

  return (
    <>
      <div className={cn('relative', className)}>
        {children}
        <input
          className='absolute inset-0 z-10 opacity-0 cursor-pointer'
          type='file'
          onChange={onChange}
        />
      </div>
      <Modal
        title={title}
        open={showModal}
        onOk={getCropData}
        okButtonProps={{ loading: showLoading }}
        onCancel={() => setShowModal(false)}
        toggle={() => setShowModal(true)}
      >
        <Cropper
          style={{ height: '400px', width: '100%' }}
          aspectRatio={1} //固定图片裁剪比例 1为正方形
          initialAspectRatio={1}
          zoomTo={0.1}
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          autoCropArea={1}
          guides={true}
          // https://github.com/fengyuanchen/cropperjs/issues/671
          checkOrientation={false}
          onInitialized={instance => setCropper(instance)}
        />
      </Modal>
    </>
  )
}
