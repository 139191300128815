import React from 'react'
import cn from 'classnames'
import { MarkI } from '@rushable/icons'

export type TAlertTextProps = {
  className?: string
  color: 'blue' | 'green' | 'yellow' | 'orange' | 'red'
  icon?: boolean
  text: string
}

const colorOptions = {
  blue: {
    bgc: 'bg-blue-16',
    textColor: 'text-blue',
  },
  green: {
    bgc: 'bg-green-16',
    textColor: 'text-green',
  },
  yellow: {
    bgc: 'bg-yellow-16',
    textColor: 'text-yellow',
  },
  orange: {
    bgc: 'bg-orange-16',
    textColor: 'text-orange',
  },
  red: {
    bgc: 'bg-red-16',
    textColor: 'text-red',
  },
}

export default function AlertText({
  className,
  color,
  icon = true,
  text,
}: TAlertTextProps): JSX.Element {
  return (
    <div
      className={cn(
        'ru-AlertText rounded-lg px-4 py-2 inline-flex justify-center items-center',
        colorOptions[color].bgc,
        className,
      )}
    >
      {icon && <MarkI size={16} className={colorOptions[color].textColor} />}
      <div className={cn('text-sm pl-1', colorOptions[color].textColor)}>
        {text}
      </div>
    </div>
  )
}
