import { Routes, Route } from 'react-router-dom'
import MainPage from './SalesPage'
import SalesDetail from './SalesDetail'
export default function SalesPage() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}></Route>
      <Route path='/detail/:id' element={<SalesDetail />} />
    </Routes>
  )
}
