import { useState, useEffect } from 'react'
import { SolidTrashCan } from '@rushable/icons'
import { toast } from 'react-toastify'
import Modal from 'components/Modal'
import Button from 'components/Button'
import ParagraphInput from 'components/ParagraphInput'
import Checkbox from 'components/Checkbox'
import {
  createCommentApi,
  updateCommentApi,
  deleteCommentApi,
} from 'redux/account'

export type TEditCommentModalProps = {
  onboardingId: string
  comment?: Record<string, any> // 编辑时当前 comment 信息
  open: boolean
  toggle: () => void
  onSuccess: () => void
}

export default function EditCommentModal({
  onboardingId,
  comment,
  open,
  toggle,
  onSuccess,
}: TEditCommentModalProps) {
  const [value, setValue] = useState('')
  const [check, setCheck] = useState(false)
  const [loading, setLoading] = useState({ ok: false, delete: false })
  const isEdit = !!comment?.id

  useEffect(() => {
    if (comment?.id) {
      setValue(comment.msg)
      setCheck(!!comment.notification)
    } else {
      setValue('')
      setCheck(false)
    }
  }, [comment?.id])

  const handleSubmit = async () => {
    const params = {
      onboarding_id: onboardingId,
      content: value,
      notification: check ? 1 : 0,
    }
    setLoading(prev => ({ ...prev, ok: true }))
    try {
      let res = null
      if (isEdit) {
        res = await updateCommentApi(comment!.id, params)
      } else {
        res = await createCommentApi(params)
      }
      toast.success(res.message)
      onSuccess()
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, ok: false }))
  }

  const handleDelete = async () => {
    setLoading(prev => ({ ...prev, delete: true }))
    try {
      let res = await deleteCommentApi(comment!.id)
      toast.success(res.message)
      onSuccess()
      toggle()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(prev => ({ ...prev, delete: false }))
  }

  return (
    <Modal
      open={open}
      toggle={toggle}
      title={`${isEdit ? 'EDIT' : 'ADD'} COMMENT`}
      okText={isEdit ? 'UPDATE' : 'CONFIRM'}
      cancelText='CLOSE'
      onOk={handleSubmit}
      okButtonProps={{ loading: loading.ok }}
      footerLeftElement={
        isEdit ? (
          <Button
            color='warning'
            loading={loading.delete}
            iconBefore={<SolidTrashCan size={12} />}
            theme='text'
            onClick={handleDelete}
          >
            DELETE
          </Button>
        ) : (
          <></>
        )
      }
    >
      <div className='w-full text-left'>
        <ParagraphInput
          name='DESCRIPTION'
          label='DESCRIPTION'
          placeholder='Input'
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <Checkbox
          className='mt-4'
          size={16}
          checked={check}
          onChange={e => {
            setCheck(e.target.checked)
          }}
        >
          Push notification to Slack channel
        </Checkbox>
      </div>
    </Modal>
  )
}
