import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { affiliateReducer } from './affiliate'
import { authReducer } from './auth'
import { accountDetailReducer } from './account'
import { creditReducer } from './credit'
import { referralReducer } from './referral'
import { blockListReducer } from './blocklist'
import { salesReducer } from './sales'
import { payoutsReducer } from './payouts'

const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountDetailReducer,
    referral: referralReducer,
    credit: creditReducer,
    blocklist: blockListReducer,
    sales: salesReducer,
    payouts: payoutsReducer,
    affiliate: affiliateReducer,
  },
  devTools: process.env.NODE_ENV === 'development' ? true : false,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {pastelID: PastelIDState, errorModal: IErrorModalState, ...}
export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
