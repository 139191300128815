import { ReactElement, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import SingleInput from 'components/SingleInput'
import Button from 'components/Button'
import { updateSuccessApi } from 'redux/account'

interface Tprops {
  type: 'website' | 'ordering'
  icon: ReactElement
  label: string
  value: string
  onSuccess?: () => void
}
export default function SetInput({
  type,
  icon,
  label,
  value,
  onSuccess,
}: Tprops) {
  const urlParams = useParams()
  const [gbp, setGbp] = useState('')
  const [status, setStatus] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setGbp(value)
  }, [value])

  // 调用api
  const updateSuccess = async () => {
    setLoading(true)

    const paramType =
      type === 'website' ? 'gbp_website_tracking' : 'gbp_ordering_tracking'
    try {
      let params = {
        [paramType]: gbp,
      }

      const res = await updateSuccessApi(urlParams.id!, params)
      toast.success(res.message)
      setStatus(true)
      onSuccess?.()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  return (
    <div className='flex-1 flex space-x-4 items-end'>
      <SingleInput
        value={gbp}
        disabled={status}
        name={label}
        label={label}
        iconBefore={icon}
        onChange={e => setGbp(e.target.value)}
      />
      <Button
        theme='outlined'
        color='secondary'
        loading={loading}
        disabled={!status && !gbp}
        onClick={() => {
          if (status) {
            setStatus(!status)
          } else {
            updateSuccess()
          }
        }}
      >
        {status ? 'EDIT' : 'SET'}
      </Button>
    </div>
  )
}
