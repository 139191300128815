import React from 'react'
import cn from 'classnames'
import moment from 'moment'
import { AngleRight, SolidPhone } from '@rushable/icons'
import DateAndTime from 'components/DateAndTime'
import Button from 'components/Button'
import IconButton from 'components/IconButton'

export type TCallItemProps = {
  source: 'from' | 'to'
  name: string
  callTime?: number | string
  callUrl?: string
  updateAt: string
}

export default function CallItem({
  source,
  name,
  callTime,
  callUrl,
  updateAt,
}: TCallItemProps): JSX.Element {
  return (
    <div
      className={cn(
        'flex-1 px-4 py-2 border rounded-lg',
        source === 'to'
          ? 'border-dark-500 bg-dark-500'
          : 'border-blue-16 bg-blue-16',
      )}
    >
      <div className='flex justify-between mb-2'>
        <div className='flex flex-1 items-center'>
          <IconButton
            className='mr-1'
            icon={<SolidPhone size={16} className='text-light-500' />}
            size='small'
            hasHover={false}
          />
          <span className='text-xs text-light-500 pt-0.5'>{name}</span>
        </div>
        <DateAndTime
          className='h-5 text-light-100 text-xs px-0'
          value={updateAt}
          format='MMM DD @ hh:mm A'
        />
      </div>
      <div className='text-xs text-light-500 '>
        {moment().diff(moment(callTime), 'minutes')} mins •
        <Button
          className='inline-flex pl-[3px]'
          color='secondary'
          iconAfter={
            <AngleRight
              size={12}
              className='inline-block text-light-700 relative bottom-[1px]'
            />
          }
          href={callUrl}
          target='_blank'
          size='small'
          theme='text'
        >
          PLAY RECORDING
        </Button>
      </div>
    </div>
  )
}
