import React from 'react'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import { currencyFormatter } from 'utils/digit'

const Row = ({ title, ele }: { title: string; ele: React.ReactElement }) => {
  return (
    <div className='w-full h-16 px-10 border-b border-dark-100 flex justify-between items-center'>
      <div className='font-bold text-xs text-light-100'>{title}</div>
      {ele}
    </div>
  )
}

export default function RightTable({ summary }: { summary: any }) {
  return (
    <div
      className='w-400 border-l border-dark-100'
      style={{
        minHeight: 'calc(100vh - 72px)',
      }}
    >
      <Row
        title='Available fund'
        ele={
          <div className='text-green'>
            {currencyFormatter(summary.available_amount || 0)}
          </div>
        }
      />
      <Row
        title='Pending fund'
        ele={
          <div className='text-blue'>
            {currencyFormatter(summary.pending_amount || 0)}
          </div>
        }
      />
      <Row
        title='Lifetime'
        ele={
          <div className='text-light-500'>
            {currencyFormatter(summary.lifeTime_amount || 0)}
          </div>
        }
      />
      <Row
        title='Live accounts'
        ele={<div className='text-light-500'>{summary.live_count || '0'}</div>}
      />
      <Row
        title='Total accounts'
        ele={
          <div className='text-light-500'>{summary.signup_count || '0'}</div>
        }
      />
      <Row
        title='Signups'
        ele={<div className='text-light-500'>{summary.average || '0'}/mo</div>}
      />
      <Row
        title='Affiliate name'
        ele={<div className='text-light-500'>{summary.name || '-'}</div>}
      />
      <Row
        title='Affiliate admin'
        ele={
          <div className='text-light-500'>
            {summary.admin_user
              ? `${summary.admin_user.first_name} ${summary.admin_user.last_name}`
              : '-'}
          </div>
        }
      />
      <Row
        title='Last training session'
        ele={
          <div className='text-light-500'>
            {summary.previously_trained_at ? (
              <DateAndTime
                className='text-light-500'
                format='MMM DD, YYYY'
                parse='YYYY-MM-DD HH:mm:ss'
                value={summary.previously_trained_at}
              />
            ) : (
              '-'
            )}
          </div>
        }
      />
      <Row
        title='Affiliate status'
        ele={
          <BadgeText
            color={!!summary.active ? 'green' : 'red'}
            value={!!summary.active ? 'Active' : 'Inactive'}
          />
        }
      />
    </div>
  )
}
