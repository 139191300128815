import React from 'react'
import { SolidLayer } from '@rushable/icons'
import ConditionsItemForm from './ConditionsItemForm'
import { StageOptions } from '../help/constant'
import type { TFilterItem } from '../help/constant'

export default function StageFilter({
  className,
  value,
  onChange,
}: TFilterItem) {
  return (
    <ConditionsItemForm
      className={className}
      icon={<SolidLayer size={16} className='text-light-100' />}
      label='Stage (required)'
      value={value}
      options={StageOptions}
      onChange={onChange}
    />
  )
}
