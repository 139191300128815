import { ReactElement } from 'react'
import cn from 'classnames'
import Table from 'components/Table'

interface propsType {
  type: 'online' | 'offering' | 'sales'
  className?: string
  label: string
  labelRight?: ReactElement
  data: any[]
  footer?: ReactElement | ReactElement[]
}

export default function AccountSideBar({
  className,
  label,
  labelRight,
  data,
  type,
  footer,
}: propsType) {
  const salesVolumeColumn = [
    {
      key: 'item',
      name: 'ITEM',
    },
    {
      align: 'right',
      key: 'amount',
      name: 'AMOUNT',
      className: 'w-24',
    },
    {
      align: 'right',
      key: 'x',
      name: 'X',
      className: 'w-72',
    },
    {
      align: 'right',
      key: 'volume',
      name: 'VOLUME',
      className: 'w-28',
    },
  ]

  const onlineColumn = [
    {
      key: 'item',
      name: 'ITEM',
    },
    {
      align: 'right',
      key: 'amount',
      name: 'AMOUNT',
      className: 'w-36',
    },
    {
      align: 'right',
      key: 'occurrence',
      name: 'OCCURRENCE',
      className: 'w-120',
    },
  ]
  let columns = type === 'sales' ? salesVolumeColumn : onlineColumn
  return (
    <div className={cn(className)}>
      <div className='flex justify-between items-center w-full mb-2'>
        <div className='text-light-300 font-bold text-xs'>{label}</div>
        {labelRight}
      </div>
      <div className='py-2 border border-dark-100 rounded-lg'>
        <Table className='!min-h-min' columns={columns} data={data} />
        {footer && (
          <div className='mt-2 border-t border-dark-100 py-2.5 px-4 flex justify-between font-bold text-light-700'>
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}
