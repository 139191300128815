import cn from 'classnames'
import { SolidCalendar } from '@rushable/icons'
import type { TimeRangePickerProps } from 'antd'
import DatePicker from 'antd/es/date-picker'
// import 'assets/scss/components/datepicker.scss'
import { Moment } from 'moment'

const { RangePicker } = DatePicker

export type RangeValue = [Moment | null, Moment | null] | null

type TextraProps = {
  open?: boolean
  className?: string
}

export type TDatePicker = TextraProps & TimeRangePickerProps

export default function RangePickerComps({
  className,
  open,
  ...props
}: TDatePicker): JSX.Element {
  return (
    <RangePicker
      className={cn(
        'w-full py-2 px-4 bg-dark-300 border-2 border-dark-300 text-light-100 rounded-lg',
        className,
      )}
      style={{ color: '#4C515C', borderColor: 'transparent' }}
      inputReadOnly={true}
      suffixIcon={<SolidCalendar className='text-light-300' size={16} />}
      allowClear={true}
      separator={false}
      open={open}
      {...props}
    />
  )
}
