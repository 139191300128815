import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'redux/hooks'
import Label from 'components/Label'
import TaskList from 'components/OnboardingTask/TaskList'
import Button from 'components/Button'
import Switch from 'components/Switch'

import EditTaskModal from './EditTaskModal'
import SetupFeeModal from './SetupFeeModal'
import {
  deleteTaskApi,
  updateTaskApi,
  sortTaskApi,
  updateOnboardingApi,
  getAccountDetailApi,
} from 'redux/account'

interface Tprops {
  tab?: 'Onboarding' | 'Success'
  disabled?: boolean
  onboardingId: string
  onboardingInfo: Record<string, any>
  onSuccess: () => void
}

export default function OnboardingCheckList({
  tab = 'Onboarding',
  disabled,
  onboardingId,
  onboardingInfo,
  onSuccess,
}: Tprops) {
  const urlParams = useParams()
  const dispatch = useAppDispatch()
  const [curTask, setCurTask] = useState<any>({})
  const [taskModal, setTaskModal] = useState(false)
  const [taskList, setTaskList] = useState<any[]>([])
  const [completeLoading, setCompleteLoading] = useState(false)

  const [showCompleted, setShowCompleted] = useState(false)

  useEffect(() => {
    let list = onboardingInfo.onboarding_tasks?.map((item: any) => {
      return {
        ...item,
        id: item.id,
        name: item.name,
        checked: !!item.completed_at,
        isTpl: !!item.onboarding_task_template_id,
        loading: !!item.loading,
      }
    })
    if (showCompleted) {
      list = list.filter((v: any) => v.checked)
    }
    setTaskList(list)
  }, [onboardingInfo.onboarding_tasks, showCompleted])

  const handleDelete = async (item: any) => {
    try {
      const res = await deleteTaskApi(onboardingId, item.id)
      toast.success(res.message)
      onSuccess()
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handleUpdate = async (completed: boolean, item: any, index: number) => {
    taskList[index].loading = true
    setTaskList([...taskList])
    try {
      const res = await updateTaskApi(onboardingId, item.id, {
        completed,
        type: tab === 'Onboarding' ? 'onboarding' : 'success',
      })
      toast.success(res.message)
      onSuccess()
    } catch (error: any) {
      toast.error(error.message)
      taskList[index].loading = false
      setTaskList([...taskList])
    }
  }

  const handleSort = async (tasks: any[]) => {
    setTaskList([...tasks])
    const ids = tasks.map(item => item.id)
    try {
      const res = await sortTaskApi(onboardingId, ids)
      toast.success(res.message)
      onSuccess()
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handleCompleteOnboarding = async () => {
    setCompleteLoading(true)
    try {
      const res = await updateOnboardingApi(onboardingId, { status: 'done' })
      toast.success(res.message)
      // 刷新页面整体数据
      dispatch(getAccountDetailApi(urlParams.id!))
    } catch (error: any) {
      toast.error(error.message)
    }
    setCompleteLoading(false)
  }

  const isAllComplete = () => {
    return taskList?.every(item => !!item.completed_at)
  }
  return (
    <>
      {tab === 'Onboarding' ? (
        <Label>ONBOARDING CHECKLIST</Label>
      ) : (
        <div className='mt-4'>
          <Label className='mb-2'>ADDRESSABLE ITEMS</Label>
          <div className='flex justify-between items-center space-x-14'>
            <Button
              className='w-full'
              theme='outlined'
              color='secondary'
              disabled={disabled}
              onClick={() => setTaskModal(true)}
            >
              ADD NEW ITEM
            </Button>
            <Switch
              checked={showCompleted}
              onChange={e => {
                setShowCompleted(e.target.checked)
              }}
            >
              Show completed items
            </Switch>
          </div>
        </div>
      )}
      <div
        className={cn('flex space-y-2 flex-col mt-2 ', {
          'mb-4': !!taskList?.length,
        })}
      >
        <TaskList
          list={taskList || []}
          onChecked={handleUpdate}
          onDelete={handleDelete}
          onSort={handleSort}
          disabled={disabled}
          onEdit={(task, index) => {
            setCurTask(task)
            setTaskModal(true)
          }}
        />
      </div>
      <>
        {tab === 'Onboarding' && (
          <>
            <div className='flex space-x-2'>
              <SetupFeeModal
                disabled={
                  !onboardingInfo.status || onboardingInfo.status === 'canceled'
                }
                onboardingId={onboardingId}
                payment={onboardingInfo.payment_schedule || {}}
                onSuccess={onSuccess}
              />
              <Button
                className='flex-1'
                color='secondary'
                theme='outlined'
                disabled={disabled}
                onClick={() => setTaskModal(true)}
              >
                ADD NEW TASK
              </Button>
            </div>
            {/* status done 情况 */}
            {isAllComplete() && !disabled && (
              <Button
                className='w-full mt-4'
                loading={completeLoading}
                onClick={handleCompleteOnboarding}
              >
                COMPLETE ONBOARDING
              </Button>
            )}
          </>
        )}
      </>
      <EditTaskModal
        onboardingId={onboardingId}
        task={curTask}
        tab={tab}
        open={taskModal}
        toggle={() => setTaskModal(!taskModal)}
        onSuccess={onSuccess}
      />
    </>
  )
}
