import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { Select, SearchSelect } from 'components/Select'
import Label from 'components/Label'
import { DateAndTimePicker } from 'components/DatePicker'

import PremiumAddOnTable from '../../components/PremiumAddOnTable'
import UserTable from '../../components/UserTable'

import CheckList from './CheckList'
import Communication from './Communication'
import { DOMAIN, RUSHABLETIMEZONE } from 'data/constant'
import {
  getOnboardingInfoApi,
  getAssigneeListApi,
  updateOnboardingApi,
  TupdateOnboarding,
} from 'redux/account'

import {
  WEBSITEOPTIONS,
  GBPOPTIONS,
  getWebsiteValue,
  getGBPValue,
} from '../../helpers/constant'
import moment from 'moment'

const DISABLEDEDIT = ['canceled', 'done']

export default function OnboardingTab() {
  const urlParams = useParams()
  const [onboardingInfo, setOnboarding] = useState<any>({})
  const [website, setWebsite] = useState('')
  const [gbp, setGbp] = useState('')
  const [shippingAddress, setShippingAddress] = useState('')
  const [assigneeOptions, setAssigneeOptions] = useState([])
  const [assignee, setAssignee] = useState('')
  const [accountUsers, setAccountUsers] = useState<any>([])
  const [followupTime, setFollowupTime] = useState({
    local: '',
    rushable: '',
    tz: '',
  })

  useEffect(() => {
    getAssigneeList()
    getOnboardingInfo()
  }, [])

  const getAssigneeList = async () => {
    try {
      const res = await getAssigneeListApi()
      const list = res.map((item: any) => ({
        img_url: item.profile_photo ? `${DOMAIN}${item.profile_photo}` : null,
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      }))
      setAssigneeOptions(list)
    } catch (error: any) {
      console.log(error)
      toast.error(error.message)
    }
  }

  const getOnboardingInfo = async () => {
    try {
      const res = await getOnboardingInfoApi(urlParams.id!)
      initData(res)
    } catch (error: any) {
      console.log(error)
      toast.error(error.message)
    }
  }

  const initData = (res: any) => {
    // fill WEBSITE PREFERENCE value
    const website = getWebsiteValue(
      res.website_preference,
      res.has_existing_domain,
      res.delegate_dns_or_website,
    )

    // fill GBP PREFERENCE
    const gbp = getGBPValue(
      res.has_gmb_ownership,
      res.need_help_regain_gmb,
      res.delegate_gmb_update,
    )
    // SHIPPING ADDRESS
    const { line_1, city, state, zipcode } = res.tablet_shipping_address || {}
    const loc_add_query = `${line_1 || ''} ${city || ''} ${state || ''} ${
      zipcode || ''
    }`

    setShippingAddress(loc_add_query)
    setAssignee(res.project_lead?.id)
    setWebsite(website)
    setGbp(gbp)
    setAccountUsers(res.users || [])
    setOnboarding(res)

    const timezone = res.address?.timezone || RUSHABLETIMEZONE
    const time = res.followup_time
    setFollowupTime({
      local: time && moment(time).tz(timezone).format('YYYY-MM-DD HH:mm:ss'),
      rushable: time,
      tz: timezone,
    })
  }

  const updateOnboarding = async (params: TupdateOnboarding) => {
    try {
      const res = await updateOnboardingApi(onboardingInfo.id, params)
      toast.success(res.message)
      getOnboardingInfo()
    } catch (error: any) {
      toast.error(error.message)
    }
  }
  // canceled done 状态下禁止编辑 (包括api返回数据之前)
  const formDisabled =
    DISABLEDEDIT.includes(onboardingInfo.status) || !onboardingInfo.status
  return (
    <div>
      <div className='mb-8'>
        <Label>ACCOUNT USERS</Label>
        <UserTable
          className='mt-2 py-2'
          hasCheck
          disabled={formDisabled}
          items={accountUsers}
          onCheck={(checked: boolean, item: any, index: number) => {
            accountUsers[index].loading = true
            setAccountUsers([...accountUsers])
            updateOnboarding({
              employment_id: item.employment_id,
              person_of_contact: checked ? 1 : 0,
            })
          }}
        />
      </div>
      <div className='mb-8 flex space-x-8'>
        <Select
          label='WEBSITE PREFERENCE'
          className='flex-1'
          placeholder='Select'
          disabled={formDisabled}
          value={website}
          options={WEBSITEOPTIONS}
          onChange={(value, item) => {
            setWebsite(value)
            updateOnboarding({ website_preference: item.option })
          }}
        />
        <Select
          label='GBP PREFERENCE'
          className='flex-1'
          placeholder='Select'
          disabled={formDisabled}
          value={gbp}
          options={GBPOPTIONS}
          onChange={(value, item) => {
            setGbp(value)
            updateOnboarding({ GBP_preference: item.option })
          }}
        />
      </div>
      <div className='mb-8 flex space-x-8'>
        <SearchSelect
          label='SHIPPING ADDRESS'
          indicators='empty'
          className='flex-1'
          placeholder='Search'
          disabled={formDisabled}
          value={shippingAddress}
          onChange={value => {
            setShippingAddress(value)
            updateOnboarding({ shipping_address: value })
          }}
        />
        <Select
          label='ONBOARDING ASSIGNEE'
          className='flex-1'
          placeholder='Select'
          isPerson
          value={assignee}
          disabled={formDisabled}
          options={assigneeOptions}
          onChange={value => {
            setAssignee(value)
            updateOnboarding({ assignee_id: value })
          }}
        />
      </div>
      <PremiumAddOnTable
        title='LOGISTICS'
        onboardingId={onboardingInfo.id}
        className='mb-8'
        disabled={formDisabled}
        onSuccess={getOnboardingInfo}
        crm_closer={onboardingInfo.crm_closer || {}}
        items={onboardingInfo.onboarding_logistics || []}
      />
      <div className='mb-8 flex justify-between space-x-8'>
        <div className='flex-1'>
          <CheckList
            disabled={formDisabled}
            onboardingId={onboardingInfo.id}
            onboardingInfo={onboardingInfo}
            onSuccess={getOnboardingInfo}
          />
        </div>
        <div className='flex-1'>
          <div className='flex space-x-4 mb-6'>
            <div className='flex-1'>
              <Label className='mb-2'>LOCAL FOLLOWUP TIME</Label>
              <DateAndTimePicker
                value={followupTime.local}
                timezone={followupTime.tz}
                noTransformTz={true}
                disabled={formDisabled}
                onChange={(date: any, dateString: any) => {
                  const rushableTime = dateString
                    ? moment(dateString)
                        .tz(RUSHABLETIMEZONE)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : ''

                  setFollowupTime(prev => ({
                    ...prev,
                    local: dateString || '',
                    rushable: rushableTime,
                  }))

                  updateOnboarding({ followup_time: rushableTime })
                }}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
            <div className='flex-1'>
              <Label className='mb-2'>RUSHABLE FOLLOWUP TIME</Label>
              <DateAndTimePicker
                value={followupTime.rushable}
                timezone={RUSHABLETIMEZONE}
                noTransformTz={true}
                disabled={formDisabled}
                onChange={(date: any, dateString: any) => {
                  const localTime = dateString
                    ? moment(dateString)
                        .tz(followupTime.tz)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : ''
                  setFollowupTime(prev => ({
                    ...prev,
                    local: localTime,
                    rushable: dateString,
                  }))
                  updateOnboarding({ followup_time: dateString })
                }}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
          </div>
          <Communication
            disabled={formDisabled}
            onboardingId={onboardingInfo.id}
            attention={onboardingInfo.attention}
            logs={onboardingInfo.onboarding_comments}
            onSuccess={getOnboardingInfo}
          />
        </div>
      </div>
    </div>
  )
}
