import { Moment } from 'moment'

export type TModelFilter = 'stats_by_period' | 'overall_insights'

export type TTimePeriod = 'monthly' | 'quarterly' | 'yearly'

export type TFilterForm = {
  model: TModelFilter
  unit: string
  option: string
  crm_team_id: string
  sort_by: string
  sort: string
}

export type TSalesItem = {
  closer_id: number
  sales_goal: string
  actual_quantity: string
  effort: string
  volume_amount: string
  signup_amount: string
  tier_amount: string
  self_gen_amount: string
  adjustment_amount: string
  total_amount: string
  pacing: null | string
  team_actual_quantity_total: string
  closer_percentage: string
  closer_total: string
  exp: null | string
  self_signup_percentage: string
  team_effort_average: string
  closer_effort_average: string
  cell_contribution_in_30_days: string
  grinder?: boolean
  maverick?: boolean
  carrier?: boolean
  mage?: boolean
  closer: {
    id: number
    profile_photo: string
    first_name: string
    last_name: string
    team_name: string
  }
}

export type TBonus = {
  adjustment_amount: string
  cancellation_quantity: string
  closer_id: number
  self_gen_bonus: string
  self_gen_quantity: string
  setup_fee_amount: string
  setup_fee_bonus: string
  total_bonus: string
  volume_amount: string
  volume_bonus: string
}

export type TRowProp = {
  [index: string]: any
}

export type TCount = {
  activity_name: string
  id: number
  quantity: number
  type: string
  unit_pt: number
  value: string
}

export type TNowParams = {
  nowPage: number
  nowSort?: {
    sort_by: string
    sort: string
  }
  ignoreCache?: boolean
}

export const modelOptions = [
  { label: 'Stats by Period', value: 'stats_by_period' },
  { label: 'Overall Insights', value: 'overall_insights' },
]

export const timePeriodOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
]

export const formatOption = (type: string, value: Moment) => {
  switch (type) {
    case 'monthly':
      return value.format('YYYY-MM')
    case 'quarterly':
      return `${value.format('YYYY')} Q${value.format('Q')}`
    case 'yearly':
      return value.format('YYYY')
    default:
      return ''
  }
}
