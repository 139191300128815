import { MutableRefObject, Ref, useCallback } from 'react'

/**
 * 合并多个ref为一个
 */
export const useCombinedRefs = <T extends any>(
  ...refs: Array<Ref<T>>
): Ref<T> =>
  useCallback(
    (element: T) =>
      refs.forEach(ref => {
        if (!ref) {
          return
        }
        if (typeof ref === 'function') {
          return ref(element)
        }
        ;(ref as MutableRefObject<T>).current = element
      }),
    refs,
  )
