import { ReactElement, useId } from 'react'
import cn from 'classnames'
import { Check } from '@rushable/icons'
import { Loading } from '@rushable/icons'

export type TCheckboxProp = {
  className?: string
  size?: number
  labelClass?: string
  children?: string | ReactElement
  shape?: 'square' | 'circle'
  checked?: boolean
  disabled?: boolean
  loading?: boolean
  onChange?: (e: TInputEvent) => void
}

export default function Checkbox({
  className = '',
  size = 24,
  labelClass,
  children = '',
  shape = 'square',
  disabled,
  checked,
  loading,
  onChange,
}: TCheckboxProp): JSX.Element {
  const labelId = useId()

  const style = { width: `${size}px`, height: `${size}px` }

  return (
    <div className={cn('flex items-center', className)}>
      {loading ? (
        <span className={cn('flex justify-center')} style={style}>
          <Loading size={20} />
        </span>
      ) : (
        <span className='relative flex p-px' style={style}>
          <input
            className={cn(
              'cursor-pointer  appearance-none w-full h-full',
              shape === 'circle' ? 'rounded-full' : 'rounded',
              checked ? 'bg-blue' : 'border-2 border-dark-100 bg-transparent',
              disabled ? 'opacity-50 cursor-not-allowed' : '',
            )}
            id={labelId}
            type='checkbox'
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          {checked && (
            <Check
              size={size - 6}
              className='text-white absolute left-1/2	top-1/2	translate-x-50 translate-y-50 pointer-events-none'
            />
          )}
        </span>
      )}
      {children && (
        <label
          className={cn('pl-1.5 cursor-pointer text-light-700', labelClass)}
          htmlFor={labelId}
        >
          {children}
        </label>
      )}
    </div>
  )
}
