import React from 'react'
import { toast } from 'react-toastify'
import { SolidStore, SolidMapPin, SolidUser } from '@rushable/icons'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import { getAccess } from 'redux/common'

import { typeOptions } from '../helpers/constant'
import { getReference } from 'utils/order'

import { formatCurrency } from 'utils/tools'

const columns = [
  {
    key: 'id',
    name: 'ORDER ID',
    className: 'w-112',
    custom: (value: string | number) => {
      return <div className='truncate'>#{value}</div>
    },
  },
  {
    key: 'customer_total',
    name: 'TOTAL',
    className: 'w-88',
    align: 'right',
    custom: (value: string | number) => {
      return <div className='w-full truncate'>${formatCurrency(value)}</div>
    },
  },
  {
    key: 'status',
    name: 'STATUS',
    className: 'w-112',
    custom: (value: string, item: any) => {
      let color: any = ''
      let text = ''
      switch (value) {
        case 'new':
          color = 'yellow'
          text = 'New'
          break
        case 'processed':
          color = 'blue'
          text = 'Processed'
          break
        case 'deposited':
          color = 'green'
          text = 'Deposited'
          break
        case 'refunded':
          color = 'orange'
          text = 'Refunded'
          break
        case 'rejected':
          color = 'red'
          text = 'Rejected'
          break
      }
      return <BadgeText value={text} color={color} />
    },
  },
  {
    key: 'location',
    name: 'BRAND',
    custom: (value: any) => {
      return (
        <div className='truncate'>
          <SolidStore
            size={16}
            className='mr-1 text-light-100 relative top-[3px]'
          />
          {value?.brand?.name}
        </div>
      )
    },
  },
  {
    key: 'location',
    name: 'LOCATION',
    custom: (value: any) => {
      return (
        <div className='truncate'>
          <SolidMapPin
            size={16}
            className='mr-1 text-light-100 relative top-[3px]'
          />
          {value?.name}
        </div>
      )
    },
  },
  {
    key: 'customer',
    name: 'CUSTOMER',
    className: 'w-160',
    custom: (value: any) => {
      return (
        <div className='truncate'>
          <SolidUser
            size={16}
            className='mr-1 text-light-100 relative top-[3px]'
          />
          {value?.first_name} {value?.last_name}
        </div>
      )
    },
  },
  {
    key: 'type',
    name: 'TYPE',
    className: 'w-120',
    custom: (value: string | number) => {
      const item = typeOptions.filter(v => v.value === value)
      return <div>{item[0] && item[0].label ? item[0].label : '-'}</div>
    },
  },
  {
    key: 'type',
    name: 'REFERENCE',
    className: 'w-160',
    custom: (value: string | number, item: any) => {
      const reference = getReference(item?.type, item?.daas_delivery)
      return <div className='truncate'>{reference}</div>
    },
  },
  {
    key: 'request_at',
    name: 'REQUEST',
    align: 'right',
    className: 'w-188',
    custom: (value: string, item: any) => {
      return (
        <DateAndTime
          format='MMM DD @ hh:mm A'
          parse='YYYY-MM-DD HH:mm:ss'
          timezone={item?.location?.address?.timezone}
          value={value}
          noExpired={true}
        />
      )
    },
  },
]

export type TOrdersTableProps = {
  loading: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  onPageNoChange: (page: number) => void
}

export default function OrdersTable({
  loading,
  total,
  current,
  pageSize,
  data,
  onPageNoChange,
}: TOrdersTableProps) {
  const clickedRow = async (item: any) => {
    const id = toast.loading('Get Access...')
    try {
      const res = await getAccess({ order_id: item.id })
      if (res && res.message === 'Success') {
        window.open(res.redirect)
        toast.dismiss(id)
      } else {
        toast.error('Get Access Error!')
      }
    } catch (err: any) {
      if (err.message) {
        toast.error(err.message)
      }
    }
  }
  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={clickedRow}
      />
    </div>
  )
}
