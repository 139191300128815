import React from 'react'

import cn from 'classnames'
import { ActionMenu } from '@rushable/icons'

export type TIconButtonProp = {
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  size?: 'large' | 'small'
  icon?: React.ReactElement
  hasHover?: boolean
  [propName: string]: any
}

export default function IconButton({
  className = '',
  size = 'small',
  icon,
  onClick,
  hasHover = true,
  ...args
}: TIconButtonProp): JSX.Element {
  const sizeClassMap = {
    large: 'p-1.5 border-2 border-dark-100 rounded-lg',
    small: 'p-0.5 rounded',
  }

  const buttonClass = cn(
    'ru-icon-button',
    'flex text-light-500',
    hasHover
      ? 'hover:bg-light-700 hover:border-light-700 hover:text-dark-900'
      : '',
    sizeClassMap[size],
  )
  return (
    <button
      onClick={onClick}
      className={cn(buttonClass, className)}
      type='button'
      {...args}
    >
      {icon || <ActionMenu size={size === 'large' ? 24 : 16} />}
    </button>
  )
}
