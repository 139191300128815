import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import { currencyFormatter } from 'utils/digit'

export type TLeftListProps = {
  loading: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  sortData: {
    sort_by: string
    sort: string
  }
  onPageNoChange: (page: number) => void
  handleFilterChange: (type: string, value: any) => void
}

export default function LeftList({
  loading,
  total,
  current,
  pageSize,
  data,
  sortData,
  onPageNoChange,
  handleFilterChange,
}: TLeftListProps) {
  const { sort_by, sort } = sortData
  const columns = [
    {
      key: 'brand_name',
      name: 'BRAND',
      className: 'w-204',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value || '-'}</div>
      },
    },
    {
      key: 'location_name',
      name: 'LOCATION',
      className: 'w-204',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value || '-'}</div>
      },
    },
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'status' ? sort : '',
      custom: (value: string) => {
        let color: any = ''
        let text: string = ''
        switch (value) {
          case 'pending':
            color = 'blue'
            text = 'Pending'
            break
          case 'available':
            color = 'green'
            text = 'Available'
            break
          case 'invalid':
            color = 'red'
            text = 'Lost'
            break
          case 'claimed':
            color = 'default'
            text = 'Claimed'
            break
        }
        return (
          <div className='flex'>
            {value && color ? <BadgeText value={text} color={color} /> : '-'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'status',
          value,
        })
      },
    },
    {
      key: 'commission',
      name: 'AMOUNT',
      className: 'w-120',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'amount' ? sort : '',
      custom: (value: any, row: any) => {
        return (
          <div>
            {row.status === 'available'
              ? '+'
              : row.status === 'claimed'
              ? '-'
              : ''}{' '}
            {currencyFormatter(value)}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'amount',
          value,
        })
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      className: 'w-120',
      custom: (value: any, row: any) => {
        return (
          <div>
            {value === 'claim' || row.status === 'claimed'
              ? '-'
              : value.charAt(0).toUpperCase() + value.slice(1)}
          </div>
        )
      },
    },
    {
      key: 'created_at',
      name: 'DATE',
      align: 'right',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'date' ? sort : '',
      custom: (value: string) => {
        return (
          <div className='flex justify-end'>
            <DateAndTime
              className='text-light-500'
              format='MMM DD, YYYY'
              parse='YYYY-MM-DD HH:mm:ss'
              value={value}
            />
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'date',
          value,
        })
      },
    },
  ]

  return (
    <Table
      containerClassName='flex-1 px-10 py-6'
      loading={loading}
      columns={columns}
      data={data}
      paginationShow
      total={total}
      current={current}
      resizePageSize={pageSize}
      onPageNoChange={onPageNoChange}
    />
  )
}
