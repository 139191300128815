import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'

import OnboardingTaskItem from 'components/OnboardingTask'

type TTask = {
  id: any
  name: string
  checked: boolean
  loading?: boolean
  isTpl?: boolean
  [propName: string]: any
}

interface Tprops {
  list: TTask[]
  disabled?: boolean
  onChecked?: (value: boolean, item: TTask, index: number) => void
  onDelete?: (item: TTask, index: number) => void
  onEdit?: (item: TTask, index: number) => void
  onSort?: (list: TTask[]) => void
}

export default function SortableList({
  list,
  disabled,
  onChecked,
  onDelete,
  onEdit,
  onSort,
}: Tprops) {
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(mouseSensor)

  // 拖拽结束后的操作
  function onDragEnd(props: any) {
    const { active, over } = props
    if (!active || !over) {
      return null
    }
    const activeIndex = list.findIndex(item => item.id === active.id)
    const overIndex = list.findIndex(item => item.id === over.id)
    const sortedList = arrayMove(list, activeIndex, overIndex)
    onSort?.(sortedList)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableContext disabled={disabled} items={list || []}>
        {list?.map((item, index) => {
          return (
            <OnboardingTaskItem
              key={item.id}
              id={item.id}
              name={item.name}
              disabled={disabled}
              checked={item.checked}
              isTpl={item.isTpl}
              loading={item.loading}
              onChecked={value => onChecked?.(value, item, index)}
              onDelete={() => onDelete?.(item, index)}
              onEdit={() => onEdit?.(item, index)}
            />
          )
        })}
      </SortableContext>
    </DndContext>
  )
}
