import axiosInstance from 'utils/axios'

export async function getAreaListApi(query?: string) {
  const response = await axiosInstance.request({
    url: 'google_place/autofill',
    method: 'get',
    params: { query },
  })
  return response.data
}

// accounts/cuisine_typs
export async function getCuisineTypesApi() {
  const response = await axiosInstance.request({
    url: 'accounts/cuisine_types',
    method: 'get',
  })
  return response.data
}

export type TbrandTtype =
  | 'signups'
  | 'accounts'
  | 'orders'
  | 'credits'
  | 'disputes'
export async function getBrandsApi(
  type: TbrandTtype,
  brand_name: string,
  options: any,
) {
  const response = await axiosInstance.request({
    url: `${type}/brands`,
    method: 'get',
    params: { brand_name, ...options },
  })
  return response.data
}

type TAccess = {
  order_id?: string
  location_id?: string
}
export async function getAccess(params: TAccess) {
  const response = await axiosInstance.request({
    url: 'get_access',
    method: 'post',
    data: params,
  })
  return response.data
}
