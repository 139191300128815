import { useState } from 'react'
import { PopupModal, useCalendlyEventListener } from 'react-calendly'

import Button from 'components/Button'
import Label from 'components/Label'
import { RUSHABLETIMEZONE } from 'data/constant'

import { DateAndTimePicker } from 'components/DatePicker'
import PersonName from 'components/PersonName'

interface Tprops {
  onboardingInfo: Record<string, any>
  onSuccess: () => void
}
export default function SuccessCall({ onboardingInfo, onSuccess }: Tprops) {
  const [calendlyOpen, setCalendary] = useState(false)

  useCalendlyEventListener({
    onProfilePageViewed: e => console.log(e.data, 'onProfilePageViewed'),
    onDateAndTimeSelected: e => console.log(e.data, 'onDateAndTimeSelected'),
    onEventTypeViewed: e => console.log(e.data, 'onEventTypeViewed'),
    onEventScheduled: async e => {
      const eventId = e.data.payload.event.uri.substring(
        e.data.payload.event.uri.lastIndexOf('/'),
      )
      console.log('onEventScheduled', e.data, eventId)
    },
  })

  const { account, crm_closer, welcome_call_links } = onboardingInfo || {}
  const schedule = account?.account_call_schedule || {}
  // account_call_schedule。invitee calendly_reschedule_link   start_time
  return (
    <>
      {schedule.start_time ? (
        <>
          <div className='mt-4 flex'>
            <div className='mr-4 flex-1'>
              <Label className='mb-2'>LOCAL SUCCESS CALL TIME</Label>
              <DateAndTimePicker
                value={schedule.start_time}
                disabled={true}
                onChange={(date: any, dateString: any) => {}}
                timezone={onboardingInfo?.address?.timezone || RUSHABLETIMEZONE}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
            <div className='flex-1'>
              <Label className='mb-2'>RUSHABLE SUCCESS CALL TIME</Label>
              <DateAndTimePicker
                value={schedule.start_time}
                timezone={RUSHABLETIMEZONE}
                disabled={true}
                onChange={(date: any, dateString: any) => {}}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
          </div>
          <div className='flex mt-4'>
            <div className='mr-4 flex-1 bg-dark-700 rounded-lg px-4 py-2 flex items-center'>
              <PersonName
                avatarSize={20}
                avatar={crm_closer?.profile_photo}
                name={`${crm_closer?.first_name} ${crm_closer?.last_name}`}
              />
            </div>
            <Button
              className='flex-1'
              theme='outlined'
              color='secondary'
              onClick={() => setCalendary(true)}
            >
              RESCHEDULE
            </Button>
          </div>
          <div className='mt-4 bg-dark-700 rounded-lg px-4 py-2 flex items-center'>
            <PersonName avatarSize={20} name={schedule.invitee || '-'} />
          </div>
        </>
      ) : (
        <div>
          <Label className='mb-2'>SUCCESS CALL</Label>
          <Button
            className='w-full'
            theme='outlined'
            color='secondary'
            onClick={() => setCalendary(true)}
          >
            SCHEDULE CALL
          </Button>
        </div>
      )}

      <PopupModal
        url={welcome_call_links || 'https://calendly.com/lei-18/30min'}
        onModalClose={() => setCalendary(false)}
        open={calendlyOpen}
        rootElement={document.getElementById('root')!}
      />
    </>
  )
}
