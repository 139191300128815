import { useState } from 'react'
import cn from 'classnames'
import { SolidCalendar } from '@rushable/icons'
import DatePicker from 'antd/es/date-picker'
import type { DatePickerProps } from 'antd'
import DateAndTime from 'components/DateAndTime'
import type { Moment } from 'moment'
import moment from 'moment'

type TextraProps = {
  className?: string
  value?: string | Moment
  timezone?: string
  noTransformTz?: boolean // 不根据时区自动转换时间，只为了显示 ET CT 等标记
  disabled?: boolean
  onChange: (value: Moment | null, dateString: string) => void
}

export type TDateAndTimePicker = DatePickerProps | TextraProps

export default function DateAndTimePicker({
  className,
  value,
  // @ts-ignore
  timezone,
  // @ts-ignore
  noTransformTz = false,
  disabled,
  onChange,
  ...props
}: TDateAndTimePicker): JSX.Element {
  const [open, setOpen] = useState(false)
  const getInputRender = () => {
    return (
      <div className='w-full flex justify-between items-center'>
        <SolidCalendar size={16} className='text-light-300' />
        {!value && <div className='text-sm'> &nbsp;</div>}
        <DateAndTime
          value={value}
          timezone={timezone}
          noTransformTz={noTransformTz}
          parse='YYYY-MM-DD HH:mm:ss'
          format='MMM DD @ hh:mm A'
        />
      </div>
    )
  }
  return (
    <DatePicker
      className={cn(
        'w-full py-2 px-4 bg-transparent border-2 border-dark-300 text-light-100 rounded-lg cursor-text',
        { 'pointer-events-none bg-dark-700 border-transparent': disabled },
        className,
      )}
      onClick={() => setOpen(!open)}
      defaultValue={value ? moment(value) : moment()}
      suffixIcon={null}
      open={open}
      // @ts-ignore
      showTime={{ format: 'HH:mm' }}
      onChange={onChange}
      onOpenChange={flag => setOpen(flag)}
      inputReadOnly={true}
      allowClear={!!value}
      placeholder='Select'
      inputRender={getInputRender}
      {...props}
    />
  )
}
