import { DAYS } from 'data/enum'
import { TOpenHour } from 'types/openHour'

let lastId = 0

export const newId = (prefix = 'id') => {
  lastId++
  return `${prefix}${lastId}`
}

export const filterDayHours = (openHours: TOpenHour[], day: string) => {
  const dayHours = openHours.filter(item => {
    return item.day === day
  })
  return dayHours
}

export const returnHoursInDays = (openHours: TOpenHour[]) => {
  let hoursInDays = {}
  DAYS.forEach(day => {
    const dayHours = filterDayHours(openHours, day.abbr)
    hoursInDays = {
      ...hoursInDays,
      [day.abbr]: dayHours,
    }
  })
  return hoursInDays
}
