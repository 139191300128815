import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import Modal from 'components/Modal'
import ConditionsForm from './ConditionsForm'
import { setFilterCount } from 'redux/account'
import type { TFilterParams } from 'pages/Accounts/helpers/constant'

export type TAccountsFilterModalProps = {
  open: boolean
  toggle: () => void
  onConfirm: (params: TFilterParams) => void
}

export default function AccountsFilterModal({
  open,
  toggle,
  onConfirm,
}: TAccountsFilterModalProps) {
  const dispatch = useAppDispatch()
  const { filterParams } = useAppSelector(state => state.account)
  const [conditionsForm, setConditionsForm] = useState<TFilterParams>({
    page: 1,
    stage: '',
    brand_name: '',
    team_id: '',
    closer_id: '',
    issue: '',
    gbp: '',
    feature: '',
    assignee_id: '',
  })

  const onFormChange = (option: { name: string; value: any }) => {
    if (option.name === 'stage') {
      setConditionsForm({
        page: 1,
        stage: option.value,
        brand_name: '',
        team_id: '',
        closer_id: '',
        issue: '',
        gbp: '',
        feature: '',
        assignee_id: '',
      })
    } else if (option.name === 'team_id') {
      setConditionsForm({
        ...conditionsForm,
        team_id: option.value,
        closer_id: '',
      })
    } else {
      setConditionsForm({
        ...conditionsForm,
        [option.name]: option.value,
      })
    }
  }

  const onFormConfirm = () => {
    onConfirm(conditionsForm)
    toggle()
  }

  useEffect(() => {
    setConditionsForm({
      ...conditionsForm,
      ...filterParams,
      stage: filterParams.stage || 'all',
    })
    let count = 0
    Object.keys(filterParams).forEach(key => {
      if (
        key !== 'page' &&
        key !== 'brand_id' &&
        key !== 'stage' &&
        // @ts-ignore
        filterParams[key]
      ) {
        count += 1
      }
    })
    dispatch(setFilterCount(count))
  }, [filterParams])
  return (
    <Modal
      open={open}
      toggle={toggle}
      title='FILTER RESTAURANT'
      okButtonProps={{ disabled: !conditionsForm.stage }}
      onOk={onFormConfirm}
    >
      <ConditionsForm
        conditionsForm={conditionsForm}
        onFormChange={onFormChange}
      />
    </Modal>
  )
}
