import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { components, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import useDebounce from 'hooks/useDebounce'
import {
  customStyles,
  customTheme,
  Control,
  BrandIndicatorsContainer,
  IOptions,
} from './help'
import Label from 'components/Label'
import { getBrandsApi, TbrandTtype } from 'redux/common'

export interface IFormAsyncSelectProps {
  className?: string
  controlIcon?: string | React.ReactElement
  label?: string | React.ReactElement
  disabled?: boolean
  placeholder?: string
  value?: any | any[]
  defaultValue?: any | any[]
  isShowAllBrands?: boolean // 展示 all brands 搜索时使用
  isShowLocation?: boolean // 展示 locations 创建时使用
  isShowCreateBrand?: boolean
  apiType?: TbrandTtype // 预设 api 请求类型，传此参数则不必再传 requestApi
  paramsOptions?: any
  onChange: (item: any, rest: any) => void
  requestApi?: (
    inputValue: string,
    type?: any,
    paramsOptions?: any,
  ) => Promise<IOptions>
}

const prefix = 'ru-form-brand-select'

const CREATENEWBRAND = 'createNewBrand'

const getAsyncBrandData = (
  inputValue: string,
  apiType: TbrandTtype,
  paramsOptions: any,
): Promise<any> => {
  return getBrandsApi(apiType, inputValue, paramsOptions)
}

// @ts-ignore
const Input = props => {
  return <components.Input {...props} id='brand-select-input' />
}

const FormBrandSelect = ({
  className,
  label,
  disabled,
  placeholder = '',
  value = '',
  isShowAllBrands = true,
  isShowLocation = true,
  isShowCreateBrand = true,
  paramsOptions,
  apiType,
  onChange,
  requestApi = getAsyncBrandData,
}: IFormAsyncSelectProps) => {
  const [asyncOptions, setAsyncOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const selectRef = useRef<any>()

  const customSearchOption = (props: OptionProps<IOptions>) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps
    const newProps = { ...props, innerProps: rest }
    const { data } = props
    let el = null
    if (data.optionChildren) {
      el = data.optionChildren
    } else if (isShowCreateBrand && data.value === CREATENEWBRAND) {
      el = (
        <div className='w-full px-4 space-x-1 mb-2 text-yellow'>
          + Create a “{data.name}” brand
        </div>
      )
    } else {
      el = renderCustomOptions(data)
    }
    // 优化 react-select
    // https://codesandbox.io/s/codesandboxer-example-forked-x6jpsv
    // https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data
    return <components.Option {...newProps}>{el}</components.Option>
  }

  const renderCustomOptions = (data: IOptions) => {
    let name = data.name || ''
    var reg = new RegExp(inputValue, 'gi')
    name = name.replace(reg, function (txt: any) {
      return `<span style='color:#00a5eb'>${txt}</span>`
    })

    if (!isShowLocation) {
      return (
        <div
          className='px-4 flex whitespace-pre'
          dangerouslySetInnerHTML={{ __html: name }}
        ></div>
      )
    }
    return (
      <div className='w-full px-4 flex justify-between space-x-1'>
        <div
          className='flex whitespace-pre'
          dangerouslySetInnerHTML={{ __html: name }}
        ></div>
        {data.locations?.length > 0 && (
          <div className='flex'>
            {data.locations.map(
              (item: {
                brand_id: number
                state: string
                location_count: number
              }) => {
                return (
                  <div
                    className='ml-2 px-1 text-xxs leading-none h-4.5 flex items-center border border-dark-100 rounded'
                    key={`${item.brand_id}-${item.state}`}
                  >
                    {item.state} {item.location_count}
                  </div>
                )
              },
            )}
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const getOptions = (value: string, callback: any) => {
    requestApi(value, apiType, paramsOptions)
      .then((res: any) => {
        const create = {
          value: CREATENEWBRAND,
          name: value,
          id: '',
        }
        let datas = []
        if (isShowLocation) {
          datas = isShowCreateBrand ? res.concat(create) : res
        } else {
          datas = isShowAllBrands
            ? [
                {
                  value: 'all',
                  name: 'All Brands',
                },
                ...res,
              ]
            : res
        }
        callback(datas)
        setAsyncOptions(datas)
      })
      .catch(() => {
        callback([])
        setAsyncOptions([])
      })
  }

  const getOptionsDebounce = useDebounce(getOptions, 500)

  // @ts-ignore
  const handleChange = item => {
    setInputValue(item.name || '')
    onChange(item.name, item)
  }

  // @ts-ignore
  const handleInputChange = (e, { action }) => {
    if (action === 'input-change') {
      setInputValue(e)
    }
    if (action === 'menu-close' && inputValue?.length === 0) {
      onChange('', {})
    }
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: IOptions[]) => void,
  ) => {
    if (!inputValue) {
      return
    }
    getOptionsDebounce(inputValue, callback)
  }

  return (
    <div className={cn(className)}>
      {label && <Label className='mb-2'>{label}</Label>}
      <AsyncSelect
        className={cn(prefix)}
        // @ts-ignore
        ref={selectRef}
        inputValue={inputValue}
        // menuIsOpen={true}
        isDisabled={disabled}
        blurInputOnSelect={true}
        cacheOptions={asyncOptions}
        defaultOptions={asyncOptions}
        loadOptions={loadOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        hideSelectedOptions={false}
        styles={{
          ...customStyles,
          input: (provided, state) => {
            return {
              ...customStyles?.input?.(provided, state),
              overflow: 'hidden',
            }
          },
        }}
        theme={customTheme}
        components={{
          Input,
          Control,
          IndicatorsContainer: BrandIndicatorsContainer,
          Option: customSearchOption,
        }}
      />
    </div>
  )
}
export default FormBrandSelect
