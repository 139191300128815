import { useState, useEffect } from 'react'
import Map, { Marker, NavigationControl, ScaleControl } from 'react-map-gl'
import GeocoderControl from './geocoder-control'
import Pin from './pin'
import { MAPBOX_ACCESSTOKEN } from 'data/constant'

export type Tpoint = {
  latitude: number
  longitude: number
  brandId?: string | number
  onboarding?: boolean // true 绿色 false 蓝色
  checked?: boolean
  city?: string
}

interface Tprops {
  points: Tpoint[]
  center?: Tpoint
  onChange: (point: Tpoint, index: number) => void
  onSearch: (points: Tpoint) => void
}

const initViewState = {
  longitude: 0,
  latitude: 0,
  zoom: 10,
}

export default function MapComps({
  points,
  center,
  onChange,
  onSearch,
}: Tprops) {
  const [viewState, setViewState] = useState(initViewState)

  useEffect(() => {
    if (center) {
      setViewState(prev => ({ ...prev, ...center }))
    }
  }, [center])

  return (
    <Map
      {...viewState}
      onMove={evt => setViewState(evt.viewState)}
      mapStyle='mapbox://styles/mapbox/dark-v9'
      mapboxAccessToken={MAPBOX_ACCESSTOKEN}
    >
      <GeocoderControl
        position='top-right'
        mapboxAccessToken={MAPBOX_ACCESSTOKEN!}
        onResult={point => {
          onSearch({
            longitude: point?.center?.[0],
            latitude: point?.center?.[1],
          })
        }}
      />

      <NavigationControl position='top-right' />
      <ScaleControl />
      {points.map((item, index, ary) => {
        return (
          <Marker
            key={`marker-${index}`}
            longitude={item.longitude}
            latitude={item.latitude}
            anchor='bottom'
            style={{ zIndex: item.checked ? 999 : 1 }}
          >
            <Pin
              checked={item.checked}
              onboarding={item.onboarding}
              onClick={() => onChange(item, index)}
            />
          </Marker>
        )
      })}
    </Map>
  )
}
