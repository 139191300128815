import React, { useState, useEffect } from 'react'
import SingleInput from 'components/SingleInput'
import { Select, SearchSelect } from 'components/Select'
import { IOptions } from 'components/Select/help'
import { getBrands } from 'redux/credit'
import { LinedSearch } from '@rushable/icons'
import { TFilterForm, statusOptions } from '../helpers/constant'

export type TSearchFilterProps = {
  filterForm: TFilterForm
  handleFormChange: (type: string, value: string | number) => void
}

export default function SearchFilter({
  filterForm,
  handleFormChange,
}: TSearchFilterProps) {
  const [brandOptions, setBrandOptions] = useState<IOptions[]>([
    { value: '', label: 'All Brands' },
  ])
  const [locationOptions, setLocationOptions] = useState<IOptions[]>([
    { value: '', label: 'All Locations' },
  ])

  const { status, brand_id, location_id, order_id } = filterForm

  const getBrandOptions = async () => {
    // 请求 api
    const res = await getBrands({ brand_name: '', type: 'list' })
    if (res && res.length > 0) {
      setBrandOptions(
        [{ value: '', label: 'All Brands' }].concat(
          res.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              locations: item.locations,
            }
          }),
        ),
      )
    }
  }

  useEffect(() => {
    if (brand_id) {
      const locations = brandOptions.filter(v => v.value === brand_id)[0]
        ?.locations
      const options: IOptions[] = [{ value: '', label: 'All Locations' }]
      if (locations && locations.length >= 0) {
        setLocationOptions(
          options.concat(
            locations.map((item: any) => {
              return {
                value: item.id,
                label: item.name,
              }
            }),
          ),
        )
      }
    }
  }, [brand_id])

  useEffect(() => {
    getBrandOptions()
  }, [])

  return (
    <div className='flex space-x-4'>
      <Select
        className='w-216'
        options={statusOptions}
        value={status}
        onChange={value => handleFormChange('status', value)}
      />
      <Select
        className='w-216'
        options={brandOptions}
        value={brand_id || 1}
        onChange={value => handleFormChange('brand_id', value)}
      />
      {brand_id ? (
        <Select
          className='w-216'
          options={locationOptions}
          value={location_id || 1}
          onChange={value => handleFormChange('location_id', value)}
        />
      ) : null}
      <SingleInput
        name='Order_ID'
        className='w-60'
        placeholder='Search Order ID'
        iconAfter={<LinedSearch className='text-light-300' size={16} />}
        value={order_id}
        onChange={e => {
          handleFormChange('order_id', e.target.value)
        }}
      />
    </div>
  )
}
