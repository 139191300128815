import { Routes, Route } from 'react-router-dom'
import AffiliatePage from './AffiliatePage'
import MainPage from './MainPage'
import MyCommission from './components/MyCommission'

export default function AccountsPage() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />} />
      <Route path='/:id' element={<AffiliatePage />} />
      <Route path='/my-commission/:id' element={<MyCommission />} />
    </Routes>
  )
}
