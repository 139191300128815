import { ReactElement } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { AngleLeft } from '@rushable/icons'
import Button from 'components/Button'

export type TAdminPageTitleProp = {
  className?: string
  title?: string | ReactElement
  leftText?: string
  left?: ReactElement
  right?: ReactElement | ReactElement[]
  leftCb?: () => void
}

export default function AdminPageTitle({
  title,
  leftText = 'GO BACK',
  left,
  right,
  leftCb,
  className,
}: TAdminPageTitleProp): JSX.Element {
  const navigate = useNavigate()
  return (
    <div
      className={cn(
        'sticky z-50 top-0 bg-dark-900 flex items-center justify-center h-72 px-10 border-b border-dark-100',
        className,
      )}
    >
      <div className='absolute left-10'>
        {left ? (
          left
        ) : (
          <Button
            theme='text'
            color='secondary'
            iconBefore={<AngleLeft size={12} />}
            onClick={() => {
              if (leftCb) {
                leftCb()
              } else {
                navigate(-1)
              }
            }}
          >
            {leftText}
          </Button>
        )}
      </div>
      {title && (
        <div className='text-light-700 text-center font-bold text-xl'>
          {title}
        </div>
      )}
      {right && <div className='absolute right-10 flex'>{right}</div>}
    </div>
  )
}
