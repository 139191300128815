import React from 'react'
import { SolidLink } from '@rushable/icons'
import ConditionsItemForm from './ConditionsItemForm'
import { GBPLinkOptions } from '../help/constant'
import type { TFilterItem } from '../help/constant'

export default function GBPLinkIssueFilter({
  className,
  value,
  onChange,
}: TFilterItem) {
  return (
    <ConditionsItemForm
      className={className}
      icon={<SolidLink size={16} className='text-light-100' />}
      label='GBP links'
      value={value}
      options={GBPLinkOptions}
      onChange={onChange}
    />
  )
}
