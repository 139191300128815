/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  Ref,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { Percentage } from '@rushable/icons'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash/cloneDeep'
import { useAppSelector } from 'redux/hooks'
import SingleInput from 'components/SingleInput'
import ParagraphInput from 'components/ParagraphInput'
import UnsavedPrompt from 'components/UnsavedPrompt'
import BusinessHours, { returnHoursInDays } from 'components/BusinessHours'
import { Select, SearchSelect, BrandSelect } from 'components/Select'
import SignupRight, { Tfee } from './SignupRight'

import { useCompare } from 'hooks/useDebounce'
import { LEADSOURCE, WELLCOMECALL, PRIMARYLANGUAGE } from 'data/enum'
import {
  createSignsupApi,
  editSignsupApi,
  getReferrerListApi,
} from 'redux/account'
import { getCuisineTypesApi } from 'redux/common'
import { THoursInDay } from 'types/openHour'
import { formatDigitOnly } from 'utils/digit'

interface Iprops {
  editId?: string
  formDisabled: boolean
  signupForm?: Record<string, any> // singups api 详情
}

type TFormStatus = {
  isDirty: boolean
}

const FORMDATAINIT = {
  brand_name: '',
  brand_id: '',
  cuisine_type_ids: [],
  source: '',
  referrer_id: '',
  hubspot_deal_link: '',
  welcome_note:
    'We have combined the Welcome Call for this location with your other locations, so our team can go over onboarding process for all locations with you in one call 😀',
  need_welcome_call: '1',
  user_email: '',
  user_phone: '',
  user_first_name: '',
  user_last_name: '',
  user_primary_language: '',
  location_address_query: '',
  location_line_2: '',
  location_name: '',
  location_email: '',
  location_phone: '',
  location_hours: undefined,
  sales_tax: '',
  legal_name: '',
  business_ein: '',
  products: [],
}

function SignupForm(
  { editId, formDisabled, signupForm }: Iprops,
  ref: Ref<any>,
) {
  const [searchParams] = useSearchParams()
  const compareFunc = useCompare()
  const authMap = useAppSelector(s => s.auth.authMap)
  const isAffiliate =
    searchParams.get('from') === 'affiliate' ||
    !!(authMap?.roleId && authMap?.roleId >= 101 && authMap?.roleId <= 199)
  const [formInit, setFormInit] = useState(FORMDATAINIT)
  const [formData, setFormData] = useState(FORMDATAINIT)
  const [cuisineOptions, setCuisineOption] = useState([])
  const [formStatus, setFormStatus] = useState<TFormStatus>({
    isDirty: false,
  })
  const [allReferrerList, setAllReferrerList] = useState<any[]>([])
  const [referrerData, setReferrerData] = useState({
    email: '',
    value: '',
    label: '',
  })
  useEffect(() => {
    if (isAffiliate) {
      setFormData({
        ...formData,
        source: 'affiliate',
        need_welcome_call: '1',
      })
    }
  }, [isAffiliate])

  // 控制离开页面时的询问框
  useEffect(() => {
    compareFunc(formData, formInit, (flag: boolean) => {
      setFormStatus(prev => ({ ...prev, isDirty: flag }))
    })
  }, [formData, formInit])

  useEffect(() => {
    getCuisineTypeList()
  }, [])

  useEffect(() => {
    if (signupForm?.id) {
      initFormData()
    }
  }, [signupForm?.id])

  // 暴露接口
  useImperativeHandle(ref, () => ({ handleSubmit }))

  const initFormData = () => {
    const {
      brand_id,
      brand_name,
      business_ein,
      business_legal_name,
      signup_form_cuisine_types,
      hubspot_deal_link,
      sales_tax,
      welcome_note,
      source,
      referrer_id,
      location_name,
      need_welcome_call,
      language,
      crm_representative,
      location_email,
      location_phone,
      location_address,
      open_hours_collection,
      signup_products,
    } = signupForm || {}

    const { email, phone, first_name, last_name } = crm_representative || {}

    const { line_1, line_2, city, state, zipcode } = location_address || {}
    const loc_add_query = `${line_1 || ''} ${city || ''} ${state || ''} ${
      zipcode || ''
    }`

    const cuisine_type_ids = signup_form_cuisine_types?.map(
      (v: any) => v.cuisine_type_id,
    )

    const products = signup_products?.map((item: any) => ({
      subscription: item.subscription_amount,
      discount: item.subscription_discount,
      dispatchFee: item.dispatch_fee,
      setupFee: item.signup_fee,
      setupFeeDiscount: item.signup_fee_discount,
      isFreeWebsite: item.mention_free_website,
    }))

    const obj = {
      brand_name,
      brand_id,
      cuisine_type_ids,
      source,
      referrer_id,
      hubspot_deal_link,
      welcome_note,
      need_welcome_call,
      user_email: email || '',
      user_phone: phone || '',
      user_first_name: first_name || '',
      user_last_name: last_name || '',
      user_primary_language: language,
      location_address_query: loc_add_query,
      location_line_2: line_2 || '',
      location_name,
      location_email,
      location_phone,
      location_hours: returnHoursInDays(
        open_hours_collection?.open_hours || [],
      ) as any,
      sales_tax,
      legal_name: business_legal_name,
      business_ein,
      products, // 需做格式化处理
    }
    setFormInit(obj)
    setFormData(cloneDeep(obj))
  }

  const getCuisineTypeList = async () => {
    try {
      const res = await getCuisineTypesApi()
      const options = res.cuisine_types?.map((v: any) => ({
        label: v.name,
        value: v.id,
      }))
      setCuisineOption(options)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const onChangeForm = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value })
  }

  // format location_hours
  const formatHoursArrs = (hours: THoursInDay = {}) => {
    return Object.keys(hours).reduce((acc: any, cur: any) => {
      return [...acc, ...hours[cur]]
    }, [])
  }
  // format products
  const formatProduct = (products: Tfee[]) => {
    return products.map(item => ({
      subscription_amount: +item.subscription,
      subscription_discount: +item.discount,
      dispatch_fee: +item.dispatchFee,
      signup_fee: +item.setupFee,
      signup_fee_discount: +item.setupFeeDiscount,
      mention_free_website: item.isFreeWebsite,
      type: 'enssential_online_presence',
    }))
  }

  const handleSubmit = (status: 'signup' | 'draft') => {
    const params = {
      ...formData,
      status,
      user_phone: formatDigitOnly(formData.user_phone),
      location_phone: formatDigitOnly(formData.location_phone),
      location_hours: formatHoursArrs(formData.location_hours!),
      products: formatProduct(formData.products!),
    }
    setFormStatus(prev => ({ ...prev, isDirty: false }))
    const api = editId ? editSignsupApi : createSignsupApi
    return new Promise((resolve, reject) => {
      api(params, editId)
        .then(res => {
          toast.success(res.message)
          resolve(res)
        })
        .catch(err => {
          toast.error(err.message || 'Request error')
          reject(err)
        })
    })
  }

  const getReferrerList = async (inputValue?: string): Promise<any> => {
    const res = await getReferrerListApi(inputValue)
    return res.map((item: any) => ({
      label: item?.name || '',
      value: item?.id?.toString() || '',
      email: item?.email || '',
    }))
  }

  const customOption = (data: any, el: any) => {
    return (
      <div className='ml-4'>
        <div dangerouslySetInnerHTML={{ __html: el }}></div>
        <div className='text-xs'>{data?.email}</div>
      </div>
    )
  }

  useEffect(() => {
    if (
      allReferrerList?.length > 0 &&
      formData?.source === 'referral' &&
      formData?.referrer_id
    ) {
      setReferrerData(
        allReferrerList.find(
          v => Number(v.value) === Number(formData?.referrer_id),
        ),
      )
    }
  }, [formData, allReferrerList])

  useEffect(() => {
    getReferrerList().then(items => {
      setAllReferrerList(items)
    })
  }, [])

  return (
    <div className='flex'>
      <div className='flex-1 mr-8'>
        <BrandSelect
          className='mb-6'
          label='BRAND NAME'
          placeholder='Select'
          value={formData.brand_name}
          disabled={formDisabled}
          apiType='signups'
          onChange={(label, item) => {
            setFormData({
              ...formData,
              brand_id: item.id,
              brand_name: label,
            })
          }}
        />
        <Select
          className='mb-6'
          label='CUISINE TYPE(S)'
          disabled={formDisabled}
          placeholder='Select'
          isMulti
          value={formData.cuisine_type_ids}
          options={cuisineOptions}
          onChange={(value, item) => {
            onChangeForm('cuisine_type_ids', value)
          }}
        />
        {!isAffiliate && (
          <>
            <div className='flex mb-6 items-end'>
              <Select
                className='flex-1'
                label='LEAD SOURCE'
                placeholder='Select'
                disabled={formDisabled}
                value={formData.source}
                options={LEADSOURCE}
                onChange={(value, item) => {
                  onChangeForm('source', value)
                }}
              />
              {formData.source === 'referral' && (
                <SearchSelect
                  className='ml-4 flex-1'
                  label=''
                  placeholder='Referral Partner'
                  disabled={formDisabled}
                  value={referrerData.label}
                  customOption={customOption}
                  defaultOptions={allReferrerList}
                  requestApi={getReferrerList}
                  onChange={(label, item) => {
                    setReferrerData(item)
                    onChangeForm('referrer_id', item.value)
                  }}
                />
              )}
            </div>
            <SingleInput
              value={formData.hubspot_deal_link || ''}
              disabled={formDisabled}
              className='mb-6'
              name='HUBSPOT_DEAL_LINK'
              label='HUBSPOT DEAL LINK'
              placeholder='Input'
              onChange={e => onChangeForm('hubspot_deal_link', e.target.value)}
            />
            <Select
              className='mb-4'
              label='WELCOME CALL'
              placeholder='Select'
              disabled={formDisabled}
              value={String(formData.need_welcome_call)}
              options={WELLCOMECALL}
              onChange={(value, item) => {
                onChangeForm('need_welcome_call', value)
              }}
            />
            {formData.need_welcome_call == '0' && (
              <ParagraphInput
                maxLength={300}
                disabled={formDisabled}
                name='welcome_note'
                placeholder='Input'
                value={formData.welcome_note || ''}
                onChange={e => onChangeForm('welcome_note', e.target.value)}
              />
            )}
          </>
        )}
        <div className='w-full my-8 border border-dark-100 border-dashed' />
        <SingleInput
          value={formData.user_email || ''}
          disabled={formDisabled}
          className='mb-6'
          name='USER_EMAIL'
          label='USER EMAIL'
          placeholder='Input'
          onChange={e => onChangeForm('user_email', e.target.value)}
        />
        <SingleInput
          value={formData.user_phone || ''}
          disabled={formDisabled}
          className='mb-6'
          name='USER_CELLPHONE'
          label='USER CELLPHONE'
          type='tel'
          placeholder='Input'
          onChangePhone={value => onChangeForm('user_phone', value)}
        />
        <SingleInput
          value={formData.user_first_name || ''}
          disabled={formDisabled}
          className='mb-6'
          name='FIRST_NAME'
          label='FIRST NAME'
          placeholder='Input'
          onChange={e => onChangeForm('user_first_name', e.target.value)}
        />
        <SingleInput
          value={formData.user_last_name || ''}
          disabled={formDisabled}
          className='mb-6'
          name='LAST_NAME'
          label='LAST NAME'
          placeholder='Input'
          onChange={e => onChangeForm('user_last_name', e.target.value)}
        />
        <Select
          label='PRIMARY LANGUAGE'
          placeholder='Select'
          disabled={formDisabled}
          value={formData.user_primary_language}
          options={PRIMARYLANGUAGE}
          onChange={(value, item) => {
            onChangeForm('user_primary_language', value)
          }}
        />
        <div className='w-full my-8 border border-dark-100 border-dashed' />
        <SearchSelect
          className='mb-6'
          label='LOCATION ADDRESS LINE 1'
          placeholder='Search'
          disabled={formDisabled}
          value={formData.location_address_query}
          onChange={(label, item) =>
            onChangeForm('location_address_query', label)
          }
        />
        <SingleInput
          className='mb-6'
          name='LOCATION_ADDRESS_LINE 2'
          label='LOCATION ADDRESS LINE 2'
          placeholder='Input'
          disabled={formDisabled}
          value={formData.location_line_2 || ''}
          onChange={e => onChangeForm('location_line_2', e.target.value)}
        />
        <SingleInput
          className='mb-6'
          name='LOCATION_NICKNAME'
          label='LOCATION NICKNAME (geographic label, e.g. city name, street name)'
          placeholder='Input'
          disabled={formDisabled}
          value={formData.location_name || ''}
          onChange={e => onChangeForm('location_name', e.target.value)}
        />
        <SingleInput
          className='mb-6'
          name='RESTAURANT_EMAIL'
          label='RESTAURANT EMAIL'
          placeholder='Input'
          disabled={formDisabled}
          value={formData.location_email || ''} // 向后端询问字段名称
          onChange={e => onChangeForm('location_email', e.target.value)}
        />
        <SingleInput
          value={formData.location_phone || ''}
          disabled={formDisabled}
          className='mb-6'
          name='RESTAURANT_PHONE'
          label='RESTAURANT PHONE'
          type='tel'
          placeholder='Input'
          onChangePhone={value => onChangeForm('location_phone', value)}
        />
        <BusinessHours
          disabled={formDisabled}
          autoFill={true}
          hoursInDay={formData.location_hours}
          onChange={hours => onChangeForm('location_hours', hours)}
        />
        <div className='w-full my-8 border border-dark-100 border-dashed' />
        <SingleInput
          className='mb-6'
          name='SALES_TAX_RATE'
          label='SALES TAX RATE'
          placeholder='Input'
          disabled={formDisabled}
          isDigit={true}
          decimalPlaces={2}
          iconAfter={<Percentage />}
          value={formData.sales_tax || ''}
          onChange={e => {
            const value = e.target.value
            if (+value <= 100) {
              onChangeForm('sales_tax', value)
            }
          }}
        />
        <SingleInput
          className='mb-6'
          name='LEGAL_ENTITY_NAME'
          label='LEGAL ENTITY NAME'
          placeholder='Input'
          disabled={formDisabled}
          value={formData.legal_name || ''}
          onChange={e => onChangeForm('legal_name', e.target.value)}
        />
        <SingleInput
          className='mb-6'
          name='LEGAL_EIN'
          label='LEGAL EIN'
          placeholder='Input'
          disabled={formDisabled}
          value={formData.business_ein || ''}
          onChange={e => onChangeForm('business_ein', e.target.value)}
        />
        <UnsavedPrompt when={formStatus.isDirty} />
      </div>
      <SignupRight
        className='flex-1'
        disabled={formDisabled}
        products={formData.products}
        onChange={info => onChangeForm('products', info)}
      />
    </div>
  )
}

export default forwardRef(SignupForm)
