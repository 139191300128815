import StageSelect, { TMode } from 'components/StageSelect'
import { MarkX } from '@rushable/icons'
import Button from 'components/Button'
import Switch from 'components/Switch'

import { useAppSelector } from 'redux/hooks'

export type TAccountsFilterProps = {
  setOpen: (arg1: boolean) => void
  handleModeChange: (arg1: TMode) => void
  handleOnlyChange: (arg1: boolean) => void
  clearFilter: () => void
}

export default function AccountsFilter({
  setOpen,
  handleModeChange,
  handleOnlyChange,
  clearFilter,
}: TAccountsFilterProps) {
  const authMap = useAppSelector(state => state.auth.authMap)
  const { total, onlySelf, filterCount, mode, counts } = useAppSelector(
    state => state.account,
  )
  return (
    <div className='flex justify-between'>
      <div className='flex'>
        <StageSelect
          mode={mode}
          count={[
            counts.draft + counts.signup,
            counts.onboarding,
            counts.live,
            counts.canceling + counts.canceled,
            counts.all,
          ]}
          onChange={newMode => handleModeChange(newMode)}
        />
        <Button
          className='ml-4'
          theme='outlined'
          color='secondary'
          onClick={() => setOpen(true)}
        >
          Filter Restaurant
        </Button>
        {filterCount > 0 && (
          <div className='ml-4 flex items-center'>
            <span className='pr-4 text-light-300'>
              {total} results from {filterCount} conditions
            </span>
            <Button
              theme='text'
              color='secondary'
              iconBefore={<MarkX size={12} className='text-light-700' />}
              onClick={clearFilter}
            >
              CLEAR
            </Button>
          </div>
        )}
      </div>
      <div className='flex'>
        <Switch
          checked={onlySelf === '1' ? true : false}
          onChange={e => {
            handleOnlyChange(e.target.checked)
          }}
        >
          Show My Accounts Only
        </Switch>
        {authMap?.roleId! < 20 && (
          <Button
            className='ml-6'
            theme='contained'
            color='secondary'
            as='Link'
            href='/accounts/detail/create'
          >
            Initiate Signup
          </Button>
        )}
      </div>
    </div>
  )
}
