import { createReducer } from '@reduxjs/toolkit'
import { TFilterForm } from 'pages/Payout/helpers/constant'
import { setTotal, setFilterForm, setData } from './action'

type TState = {
  total: number
  filterForm: TFilterForm
  data: []
}

const initialState: TState = {
  total: 0,
  filterForm: {
    status: '',
    type: '',
    sort_by: '',
    sort: '',
    page: 1,
    per_page: 40,
  },
  data: [],
}

export const payoutsReducer = createReducer(initialState, builder => {
  builder.addCase(setTotal, (state, action) => {
    state.total = action.payload
  })
  builder.addCase(setFilterForm, (state, action) => {
    state.filterForm = action.payload
  })
  builder.addCase(setData, (state, action) => {
    state.data = action.payload
  })
})

export default payoutsReducer
