export const copyText = (
  text: string,
  success?: () => void,
  fail?: () => void,
) => {
  if (typeof text !== 'string') {
    return false
  }
  if (typeof text === 'string' && text.length === 0) {
    return false
  }
  const input = document.createElement('input')
  document.body.appendChild(input)
  input.setAttribute('value', text)
  input.select()
  try {
    document.execCommand('copy')
    document.body.removeChild(input)
    success && success()
  } catch (e) {
    document.body.removeChild(input)
    fail && fail()
  }
}

/**
 * 只能处理两层数据
 * @param obj
 * @returns formData
 */
export function formatParamsFormData(obj: Record<string, any>) {
  const formData = new FormData()
  Object.keys(obj).forEach(key => {
    const value = obj[key]
    if (getDataType(value) === 'Object') {
      Object.keys(value).forEach((key2: string) => {
        formData.append(`${key}[${key2}]`, value[key2])
      })
    } else {
      formData.append(key, value)
    }
  })
  return formData
}

function getDataType(data: any): string {
  return Object.prototype.toString.call(data).slice(8, -1)
}

export const formatCurrency = (moneyString: string | number, decimal = 2) => {
  if (isNaN(Number(moneyString))) {
    return 0
  }

  if (typeof moneyString === 'string') {
    moneyString = parseFloat(moneyString)
  }

  const value = moneyString * 100
  const value2: any = Math.round(value) / 100
  return parseFloat(value2).toFixed(decimal)
}
