import cn from 'classnames'
import { SolidStore } from '@rushable/icons'
import { BrandSelect } from 'components/Select'

export type TBrandSearchFilterProps = {
  className?: string
  value: string
  onChange: (item: any, rest: any) => void
}

export default function BrandSearchFilter({
  className,
  value,
  onChange,
}: TBrandSearchFilterProps): JSX.Element {
  return (
    <div
      className={cn('ru-ConditionsItemForm flex justify-between', className)}
    >
      <div className='flex items-center'>
        <SolidStore size={16} className='text-light-100' />
        <span className='ml-2 mr-6 w-40'>Brand name</span>
      </div>
      <div className='flex-1'>
        <BrandSelect
          placeholder='Search'
          value={value}
          apiType='accounts'
          isShowLocation={false}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
