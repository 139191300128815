import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Label from 'components/Label'
import ParagraphInput from 'components/ParagraphInput'
import { updateOnboardingApi } from 'redux/account'
export type Tprops = {
  className?: string
  disabled?: boolean
  onboardingId: string
  attention?: string
  onSuccess?: () => void
}

export default function AttentionModal({
  attention,
  className,
  disabled,
  onboardingId,
  onSuccess,
}: Tprops) {
  const [value, setValue] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValue(attention!)
  }, [open])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const res = await updateOnboardingApi(onboardingId, { attention: value })
      toast.success(res.message)
      setValue('')
      setOpen(false)
      onSuccess?.()
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(false)
  }
  return (
    <>
      <div className={className}>
        <Label>STICKY ATTENTION</Label>
        <Button
          className='w-full mt-2'
          color='secondary'
          theme='outlined'
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          EDIT ATTENTION
        </Button>
      </div>
      <Modal
        open={open}
        toggle={() => setOpen(!open)}
        onOk={handleSubmit}
        title='EDIT ATTENTION'
        okText='UPDATE'
        cancelText='CLOSE'
        okButtonProps={{ loading }}
      >
        <div className='w-full text-left'>
          <ParagraphInput
            name='DESCRIPTION'
            label='DESCRIPTION'
            placeholder='Input'
            value={value || ''}
            onChange={e => setValue(e.target.value)}
          />
        </div>
      </Modal>
    </>
  )
}
