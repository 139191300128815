import { ReactElement } from 'react'
import MenuNavs from './MenuNavs'

type TAdminLayoutProps = {
  showNavs?: boolean
  children: ReactElement | ReactElement[]
}
export default function AdminLayout({
  showNavs = true,
  children,
}: TAdminLayoutProps): JSX.Element {
  return (
    <div className='min-w-1440 mx-auto text-sm text-light-500 bg-dark-900'>
      {showNavs && <MenuNavs />}
      {children}
    </div>
  )
}
