import { createReducer } from '@reduxjs/toolkit'

type TState = {}

const initialState: TState = {}

export const blockListReducer = createReducer(initialState, builder => {
  builder.addCase('', state => {})
})

export default blockListReducer
