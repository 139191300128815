import React from 'react'

import cn from 'classnames'

interface IBadgeTextProp {
  value: string
  color?: 'default' | 'blue' | 'green' | 'yellow' | 'orange' | 'red'
  className?: string
}
export default function BadgeText({
  color = 'default',
  value,
  className,
}: IBadgeTextProp) {
  const colorClassMap = {
    blue: 'bg-blue-16 text-blue',
    default: 'bg-dark-100 text-light-700',
    green: 'bg-green-16 text-green',
    yellow: 'bg-yellow-16 text-yellow',
    orange: 'bg-orange-16 text-orange',
    red: 'bg-red-16 text-red',
  }
  if (!value) {
    return null
  }
  return (
    <div
      className={cn(
        className,
        'py-0.5 px-2 rounded inline-flex items-center justify-center text-xs font-medium',
        colorClassMap[color],
      )}
    >
      {value}
    </div>
  )
}
