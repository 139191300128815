import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Logo } from '@rushable/icons'
import SingleInput from 'components/SingleInput'
import Button from 'components/Button'
import { loginApi } from 'redux/auth'
import { saveAccessToken } from 'utils/auth'

export default function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    try {
      const res = await loginApi(email, pwd)
      const { access_token, expires_at } = res.data || {}
      saveAccessToken(access_token, expires_at)
      toast.success(res.message)
      navigate('/')
    } catch (e: any) {
      toast.error(e.message)
    }
    setLoading(false)
  }

  return (
    <div className='pt-40'>
      <div className='text-center mb-6'>
        <Logo className='mb-2 w-88' style={{ mixBlendMode: 'screen' }} />
        <h1 className='text-light-900 text-32 font-bold'>HUB SYSTEM 3.0</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='w-400 m-auto p-10 bg-dark-500 border border-dark-100 rounded-lg'>
          <SingleInput
            label='EMAIL'
            className='mb-6'
            name='EMAIL'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='Input'
          />
          <SingleInput
            label='PASSWORD'
            name='PASSWORD'
            type='password'
            value={pwd}
            onChange={e => setPwd(e.target.value)}
            placeholder='Input'
          />
          <Button type='submit' loading={loading} className='mt-10 w-full'>
            SIGN IN
          </Button>
        </div>
      </form>
    </div>
  )
}
