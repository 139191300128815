import cn from 'classnames'

import { SolidMapPin } from '@rushable/icons'

interface Iprops {
  onClick?: () => void
  checked?: boolean
  onboarding?: boolean
}
export default function Pin({ onClick, checked = false, onboarding }: Iprops) {
  const fillColor = onboarding ? 'text-blue' : 'text-green'
  return (
    <span onClick={onClick} className='cursor-pointer'>
      <SolidMapPin
        size={32}
        className={cn(checked ? 'text-white' : fillColor)}
      />
    </span>
  )
}
