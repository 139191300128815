import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { Loading } from '@rushable/icons'

export type TButtonProp = {
  children: React.ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void

  color?: TButtonColorType
  size?: 'large' | 'default' | 'small'
  theme?: 'contained' | 'outlined' | 'text'
  disabled?: boolean
  iconBefore?: React.ReactElement
  iconAfter?: React.ReactElement

  loading?: boolean
  // a tag props
  as?: 'a' | 'Link'
  href?: string
  replace?: boolean
  target?: '_blank' | '_parent' | '_self' | '_top'

  [propName: string]: any
}

export default function Button({
  children,
  className = '',
  color = 'primary',
  size = 'default',
  theme = 'contained',
  disabled = false,
  iconBefore,
  iconAfter,

  loading = false,
  as = 'a',
  href,
  replace = false,
  target = '_self',
  onClick,
  ...args
}: TButtonProp): JSX.Element {
  const sizeClassMap = {
    large: 'min-w-160 py-3 px-6 border-2',
    default: 'min-w-120 py-2 px-4 border-2',
    small: 'min-w-120 py-1 px-4 border-2',
  }

  const fontSizeClassMap = {
    large: 'text-base',
    default: 'text-sm',
    small: 'text-xs',
  }

  const disabledClass =
    'disabled:text-light-100 disabled:bg-dark-700 disabled:border-dark-700'

  const getThemeClass = (): string => {
    if (theme === 'contained') {
      const colorClassMap = {
        primary: 'bg-blue text-white border-blue',
        secondary: 'bg-light-700 text-dark-900 border-light-700',
        warning: 'bg-red text-white border-red',
      }
      return `${sizeClassMap[size]} ${colorClassMap[color]} ${disabledClass}`
    } else if (theme === 'outlined') {
      const colorClassMap = {
        primary: 'bg-transparent text-blue border-blue',
        secondary: 'bg-transparent text-light-700 border-dark-100',
        warning: 'bg-transparent text-red border-red',
      }
      return `${sizeClassMap[size]} ${colorClassMap[color]} ${disabledClass}`
    } else {
      // theme: text
      const colorClassMap = {
        primary: 'text-blue',
        secondary: 'text-light-700',
        warning: 'text-red',
      }
      const disabledClass = 'disabled:text-light-100'
      return `${colorClassMap[color]} ${disabledClass}`
    }
  }

  const renderElement = () => {
    let before = iconBefore
    if (loading) {
      before = <Loading size={size === 'large' ? 16 : 12} />
    }
    return (
      <>
        {before && (
          <div className={cn('flex', size === 'large' ? 'mr-2' : 'mr-1')}>
            {before}
          </div>
        )}
        <span className={cn('flex whitespace-nowrap', fontSizeClassMap[size])}>
          {children}
        </span>
        {iconAfter && (
          <div className={cn('flex', size === 'large' ? 'ml-2' : 'ml-1')}>
            {iconAfter}
          </div>
        )}
      </>
    )
  }

  const buttonClass = cn(
    'ru-button',
    'rounded-lg	font-bold',
    'flex justify-center items-center',
    { 'pointer-events-none': loading || disabled },
    getThemeClass(),
  )

  if (href) {
    const text = (
      <button className={cn(buttonClass, className)} type='button'>
        {renderElement()}
      </button>
    )
    if (as === 'Link') {
      return (
        <Link to={href} replace={replace}>
          {text}
        </Link>
      )
    }
    return (
      <a href={href} target={target} {...args}>
        {text}
      </a>
    )
  }
  return (
    <button
      onClick={onClick}
      className={cn(buttonClass, className)}
      disabled={disabled}
      type='button'
      {...args}
    >
      {renderElement()}
    </button>
  )
}
