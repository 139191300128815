import { forwardRef, HTMLAttributes, ForwardedRef } from 'react'
import cn from 'classnames'
import Label from 'components/Label'
import FieldError from 'components/FieldError'

interface InputProps extends HTMLAttributes<HTMLTextAreaElement> {
  required?: boolean
  disabled?: boolean
  label?: string
  name: string
  value: string
  maxLength?: number
  errorText?: string
  autoComplete?: string
  className?: string
  onChange?: (event: any) => void
}
type InputRef = ForwardedRef<never>

const ParagraphInput = forwardRef(
  (
    {
      required,
      disabled,
      label,
      name,
      value,
      maxLength,
      errorText,
      autoComplete = 'on',
      className,
      onChange,
      ...prop
    }: InputProps,
    ref: InputRef,
  ) => {
    return (
      <div className={cn('ru-ParagraphInput w-full', className)}>
        {label && (
          <div className='flex justify-between text-light-300 text-xs mb-2'>
            <Label htmlFor={name}>{label}</Label>
            {maxLength && (
              <div className='whitespace-nowrap'>
                ({value.length}/{maxLength} characters)
              </div>
            )}
          </div>
        )}
        {/* 不加下面的 flex div 的话，textarea bottom 会多一部分 */}
        <div className='flex'>
          <textarea
            className={cn(
              'w-full h-[100px] px-4 py-2 text-sm border-2  rounded-lg  placeholder-light-100 text-light-700 resize-none focus:outline-none focus:border-light-700',
              !!errorText ? 'border-red' : 'border-dark-300',
              disabled
                ? 'bg-dark-700 border-dark-700 text-light-500'
                : 'bg-dark-300 hover:outline-none hover:border-light-700',
            )}
            aria-required={required}
            value={value}
            disabled={disabled}
            id={name}
            name={name}
            ref={ref}
            maxLength={maxLength}
            autoComplete={autoComplete}
            onChange={onChange}
            {...prop}
          />
        </div>
        {!!errorText && <FieldError className='mt-1'>{errorText}</FieldError>}
      </div>
    )
  },
)

export default ParagraphInput
