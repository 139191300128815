import React from 'react'

import { AngleRight, Loading, SolidTrashCan } from '@rushable/icons'
import Button from 'components/Button'
import Table from 'components/Table'
import DateAndTime from 'components/DateAndTime'
import BadgeText from 'components/BadgeText'

import type { TRowProp, TNowParams } from '../helpers/constant'

export type TEffortPointsCalTableProp = {
  className?: string
  data: Array<TRowProp>
  total: number
  loading?: boolean
  deleteLoading: {
    show: boolean
    id: null | number
  }
  page?: number
  pageSize?: number
  sortData: {
    sort_by: string
    sort: string
  }
  handleGetList: (params: TNowParams) => void
  handleDeleteOther: (effortPoint: number) => void
}

export default function EffortPointsCalTable({
  className = '',
  loading,
  data,
  total,
  deleteLoading,
  page,
  pageSize,
  sortData,
  handleGetList,
  handleDeleteOther,
}: TEffortPointsCalTableProp): JSX.Element {
  const { sort_by, sort } = sortData
  const columns = [
    {
      key: 'activity_name',
      name: 'ACTIVITY',
      className: 'w-40',
      sortable: true,
      sortDirection: sort_by === 'activity' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'activity' : '',
            sort: value,
          },
        })
      },
    },
    {
      key: 'value',
      name: 'EFFORT',
      className: 'w-104',
      custom: (value: any) => {
        return <BadgeText value={`+ ${value} pt`} />
      },
    },
    {
      key: 'asso_contact',
      name: 'ASSO. CONTACT',
      sortable: true,
      sortDirection: sort_by === 'asso_contact' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'asso_contact' : '',
            sort: value,
          },
        })
      },
      custom: (value: any, row: any) => {
        return (
          <div className={`${row.activity === 'Other' && 'text-yellow'}`}>
            {value ?? '-'}
          </div>
        )
      },
    },
    {
      key: 'asso_company',
      name: 'ASSO. COMPANY',
      sortable: true,
      sortDirection: sort_by === 'asso_company' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'asso_company' : '',
            sort: value,
          },
        })
      },
      custom: (value: any, row: any) => {
        return (
          <div className={`${row.activity === 'Other' && 'text-yellow'}`}>
            {value ?? '-'}
          </div>
        )
      },
    },
    {
      key: 'done_at',
      name: 'DATE & TIME',
      sortable: true,
      sortDirection: sort_by === 'done_at' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: value ? 'done_at' : '',
            sort: value,
          },
        })
      },
      align: 'right',
      className: 'w-48',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            <DateAndTime
              className='text-light-500'
              parse='YYYY-MM-DD HH:mm:ss'
              format='MMM DD @ hh:mm A'
              timezone='America/New_York'
              value={value}
            />
          </div>
        )
      },
    },
    {
      key: 'type',
      name: 'VIEW DETAIL',
      align: 'right',
      className: 'w-120',
      custom: (value: string, row: any) => {
        if (value === 'other') {
          return (
            <div className='flex justify-end'>
              <Button
                size='small'
                theme='text'
                onClick={() => handleDeleteOther(row.id)}
              >
                <div className={'text-red flex space-x-1 items-center'}>
                  <span>Remove</span>
                  {deleteLoading.show && deleteLoading.id === row.id ? (
                    <Loading size={12} />
                  ) : (
                    <SolidTrashCan size={12} />
                  )}
                </div>
              </Button>
            </div>
          )
        }
        return (
          <div className='flex justify-end'>
            <Button
              size='small'
              theme='text'
              href={row?.source_link || ''}
              target='_blank'
            >
              <div className={'text-blue flex space-x-1 items-center'}>
                <span>
                  {value === 'demo' || value === 'deal' ? 'Deal' : 'Engagement'}
                </span>
                <AngleRight size={12} />
              </div>
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <div className='mt-8'>
      <Table
        loading={loading}
        current={page}
        columns={columns}
        data={data}
        total={total}
        resizePageSize={pageSize}
        onPageNoChange={value =>
          handleGetList({
            nowPage: value,
          })
        }
      />
    </div>
  )
}
