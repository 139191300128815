import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getCommission, handleClaim } from 'redux/affiliate'
import { AdminLayout, AdminPageTitle } from 'components/Layout'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { currencyFormatter } from 'utils/digit'
import LeftList from './LeftList'
import RightTable from './RightTable'

export default function MyCommission() {
  const params = useParams()
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any[]>([])
  const [summary, setSummary] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const authMap = useAppSelector(s => s.auth.authMap)
  const [claimLoading, setClaimLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [sortData, setSortData] = useState({
    sort_by: '',
    sort: '',
  })
  const [filterForm, setFilterForm] = useState<any>({
    page: 1,
    per_page: 40,
  })

  const handleFilterChange = (type: string, value: any) => {
    let newForm = { ...filterForm }
    if (type === 'sort') {
      const sortForm = {
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
      newForm = {
        ...filterForm,
        ...sortForm,
      }
      setSortData(sortForm)
    } else {
      newForm = {
        ...filterForm,
        [type]: value,
      }
    }
    getTable({ ...newForm, page: 1 })
  }

  const getTable = async (form?: any) => {
    // 请求 api
    setLoading(true)
    try {
      setFilterForm(form)
      const res = await getCommission(params.id, form)
      setData(res.detail.data)
      setSummary(res.summary)
      setTotal(res.detail.total)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const getClaim = async () => {
    setClaimLoading(true)
    // 请求 api
    try {
      const res = await handleClaim(params.id, {
        commission: Number(summary.available_amount || '0'),
      })
      toast.success(res.message)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setClaimLoading(false)
  }

  const onPageNoChange = (page: number) => {
    getTable({ ...filterForm, page })
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  return (
    <AdminLayout showNavs={false}>
      <AdminPageTitle
        title='My Commission'
        right={
          <>
            {authMap?.type === 'affiliate' && (
              <Button
                theme='outlined'
                color='secondary'
                onClick={() => setOpen(true)}
              >
                CLAIM {currencyFormatter(summary.available_amount || 0)}
              </Button>
            )}
          </>
        }
      />
      <div className='flex'>
        <LeftList
          loading={loading}
          total={total}
          current={filterForm.page}
          pageSize={filterForm.per_page}
          data={data}
          sortData={sortData}
          onPageNoChange={onPageNoChange}
          handleFilterChange={handleFilterChange}
        />
        <RightTable summary={summary} />
      </div>
      <Modal
        open={open}
        toggle={() => setOpen(false)}
        title={`Confirm to claim ${currencyFormatter(
          summary.available_amount,
        )}`}
        okButtonProps={{ loading: claimLoading }}
        okText='CONFIRM'
        cancelText='CLOSE'
        onOk={() => getClaim()}
      ></Modal>
    </AdminLayout>
  )
}
