import React from 'react'
import cn from 'classnames'
import { Select } from 'components/Select'
import type { IOptions } from 'components/Select/help'

export type TConditionsItemFormProps = {
  className?: string
  icon: React.ReactElement
  label: string
  value: string | boolean
  options: IOptions[]
  onChange: (item: any, rest: any) => void
}

export default function ConditionsItemForm({
  className,
  icon,
  label,
  value,
  options,
  onChange,
}: TConditionsItemFormProps): JSX.Element {
  return (
    <div
      className={cn('ru-ConditionsItemForm flex justify-between', className)}
    >
      <div className='flex items-center'>
        {icon}
        <span className='ml-2 mr-6 w-40'>{label}</span>
      </div>
      <div className='flex-1'>
        <Select
          value={value}
          options={options}
          onChange={onChange}
          placeholder='No specification'
        />
      </div>
    </div>
  )
}
