import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/hooks'

import Button from 'components/Button'
import AlertText from 'components/AlertText'
import Drawer from 'components/Drawer'
import SingleInput from 'components/SingleInput'
import { Select, BrandSelect } from 'components/Select'
import Label from 'components/Label'
import ParagraphInput from 'components/ParagraphInput'
import Checkbox from 'components/Checkbox'
import { Dollar, SolidTrashCan } from '@rushable/icons'
import {
  getBrands,
  getLocations,
  getCreditOrders,
  approveCredit,
  discardCredit,
  createCreditOrder,
} from 'redux/credit'
import { currencyFormatter } from 'utils/digit'
import { formatCurrency } from 'utils/tools'

import {
  creditTypeOptions,
  assoOrderOptions,
  orderItemOptions,
} from '../helpers/constant'
import { toast } from 'react-toastify'

export type TCreditDrawerProp = {
  creditModal: any
  toggle: () => void
  formDisabled: boolean
  handleUpdate: () => void
}

const FORMDATA = {
  id: 0,
  brand_id: 0,
  brand_name: '',
  location_id: 0,
  location_name: '',
  credit_type: '',
  confirmation: '',
  comment: '',
  assoOrderType: '',
  order_id: '',
  amount: '',
  status: '',
  orderData: null,
}

export default function CreditDrawer({
  creditModal,
  formDisabled,
  toggle,
  handleUpdate,
}: TCreditDrawerProp): JSX.Element {
  const [formData, setFormData] = useState<any>(FORMDATA)
  const [selectCreditData, setSelectCreditData] = useState<any>({})
  const [orderTotal, setOrderTotal] = useState(0)
  const [ordering, setOrdering] = useState(false)
  const [creating, setCreating] = useState(false)
  const [discarding, setDiscarding] = useState(false)
  const [approving, setApproving] = useState(false)
  const [locationOptions, setLocationOptions] = useState([])

  const canApprove = useAppSelector(s => s.auth.authMap)?.roleId === 1

  const handleFormChange = (type: string, value: any) => {
    setFormData({
      ...formData,
      [type]: value,
    })
  }

  const getAsyncBrandData = async (inputValue?: any): Promise<any> => {
    const res = await getBrands({
      brand_name: inputValue || '',
      type: 'create',
    })
    const brands = res.map((item: any) => {
      return { ...item, value: item.id }
    })
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(brands)
      }, 500)
    })
  }

  const getCreditOrder = async () => {
    setOrdering(true)
    try {
      const res = await getCreditOrders({
        orderId: formData.order_id,
        locationId: formData.location_id,
        type: formData.credit_type,
      })
      if (res.message) {
        toast.error(res.message)
      } else {
        handleFormChange('orderData', res)
      }
    } catch (e: any) {
      handleFormChange('orderData', null)
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setOrdering(false)
  }

  const getLocation = async (id: number) => {
    try {
      const res = await getLocations(id)
      if (res.message) {
        toast.error(res.message)
      } else {
        setLocationOptions(
          res.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            }
          }),
        )
      }
    } catch (e: any) {
      setLocationOptions([])
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  useEffect(() => {
    if (creditModal.open) {
      getAsyncBrandData()
    } else {
      setFormData({ ...FORMDATA })
    }
  }, [creditModal.open])

  useEffect(() => {
    if (creditModal.type === 'edit') {
      const newData = creditModal.data
      let newAssoOrder = ''
      if (newData.reason === 'other') {
        newAssoOrder = newData.order_id ? 'yes' : 'no'
      }

      setFormData({
        id: newData.id,
        brand_id: newData.location.brand.id,
        brand_name: newData.location.brand.name,
        location_id: newData.location.id,
        location_name: newData.location.name,
        credit_type: newData.reason,
        confirmation: newData.confirmation,
        comment: newData.comment,
        assoOrderType: newAssoOrder,
        order_id: newData.order_id,
        amount: newData.amount,
        status: newData.status,
        orderData: newData.order,
      })
      const CreditObj: any = {}
      newData.credit_items.forEach((item: any) => {
        CreditObj[item.name] = item.amount
      })
      setSelectCreditData(CreditObj)

      if (newData.location.brand.id) {
        getLocation(newData.location.brand.id)
      }
    }
  }, [creditModal.data])

  useEffect(() => {
    let result = 0
    Object.values(selectCreditData).forEach(price => {
      if (Number(price) > 0) {
        result += Number(price)
      }
    })
    setOrderTotal(result)
  }, [selectCreditData])

  const handleSelectOrder = (type: string, price?: string | number) => {
    if (formDisabled) {
      return
    }
    if (type === 'subtotal') {
      if (!price) {
        if (selectCreditData.subtotal) {
          const data = JSON.parse(JSON.stringify(selectCreditData))
          delete data[type]
          setSelectCreditData(data)
          return
        }
      }
      setSelectCreditData({
        ...selectCreditData,
        [type]: price,
      })
      return
    }
    if (selectCreditData[type]) {
      const data = JSON.parse(JSON.stringify(selectCreditData))
      delete data[type]
      setSelectCreditData(data)
    } else {
      setSelectCreditData({
        ...selectCreditData,
        [type]: price,
      })
    }
  }

  const handleSelectBlur = (price: string | number) => {
    if (
      price &&
      Number(price) > 0 &&
      Number(price) <= Number(formData.orderData.subtotal)
    ) {
      setSelectCreditData({
        ...selectCreditData,
        subtotal: `${Number(price)}`,
      })
      return
    }
    setSelectCreditData({
      ...selectCreditData,
      subtotal: '',
    })
  }

  const handleSelectFocus = (price: string | number) => {
    if (Number(price) > 0) {
      setSelectCreditData({
        ...selectCreditData,
        subtotal: Number(price),
      })
      return
    }
    setSelectCreditData({
      ...selectCreditData,
      subtotal: '',
    })
  }

  const handleCreateCredit = async () => {
    setCreating(true)
    try {
      const params = {
        location_id: formData.location_id,
        order_id: !(
          formData.credit_type === 'other' && formData.assoOrderType === 'no'
        )
          ? formData.order_id
          : undefined,
        reason: formData.credit_type,
        comment: formData.comment,
        confirmation:
          formData.credit_type === 'odd' ? formData.confirmation : undefined,
        credit_amount:
          formData.credit_type === 'other' && formData.assoOrderType === 'no'
            ? Number(formData.amount)
            : orderTotal,
        credit_items: !(
          formData.credit_type === 'other' && formData.assoOrderType === 'no'
        )
          ? Object.keys(selectCreditData).map(key => {
              return {
                name: key,
                amount: Number(selectCreditData[key]),
              }
            })
          : undefined,
      }
      const res = await createCreditOrder(params)
      if (res.message) {
        toast.success(res.message)
        handleUpdate()
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setCreating(false)
  }

  const handleApprove = async () => {
    setApproving(true)
    try {
      const res = await approveCredit({
        credit_id: formData.id,
        status: 'approved',
      })
      if (res.message) {
        toast.success(res.message)
        handleUpdate()
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setApproving(false)
  }

  const handleDiscard = async () => {
    setDiscarding(true)
    try {
      const res = await discardCredit(formData.id)
      if (res.message) {
        toast.success(res.message)
        handleUpdate()
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setDiscarding(false)
  }

  return (
    <div>
      <Drawer
        title='ISSUE CREDIT'
        open={creditModal.open}
        isCancelBtn
        cancelText='CANCEL'
        okText='SUBMIT REQUEST'
        okButtonProps={{
          loading: creating,
          disabled: !(
            (formData.orderData && formData.credit_type === 'odd') ||
            (formData.credit_type === 'other' &&
              formData.assoOrderType === 'no') ||
            (formData.credit_type === 'other' &&
              formData.assoOrderType === 'yes' &&
              formData.orderData)
          ),
        }}
        onOk={() => handleCreateCredit()}
        footer={
          creditModal.type === 'create' ? undefined : creditModal.type ===
              'edit' && formData.status === 'pending' ? (
            <div className='flex space-x-6'>
              <Button
                loading={discarding}
                color='warning'
                theme='text'
                iconBefore={<SolidTrashCan size={12} />}
                onClick={() => handleDiscard()}
              >
                DISCARD
              </Button>
              <AlertText color='yellow' text='Pending Approval' />
            </div>
          ) : null
        }
        toggle={toggle}
      >
        <div className='flex flex-col'>
          <Label className='mb-2'>TARGET RESTAURANT</Label>
          <BrandSelect
            className='mb-3'
            placeholder='Search brand'
            value={formData.brand_name}
            disabled={formDisabled}
            isShowCreateBrand={false}
            requestApi={getAsyncBrandData}
            onChange={(label, item) => {
              setFormData({
                ...formData,
                ...{ brand_name: label, brand_id: item.id },
              })
              getLocation(item.id)
            }}
          />
          <Select
            disabled={formDisabled}
            placeholder='Search Location'
            value={formData.location_id}
            onChange={(label, item) => {
              handleFormChange('location_id', label)
            }}
            options={locationOptions}
          />
          <Select
            label={'CREDIT TYPE'}
            className='mt-6'
            placeholder='Select'
            value={formData.credit_type}
            onChange={(label, item) => {
              handleFormChange('credit_type', label)
            }}
            options={creditTypeOptions}
            disabled={formDisabled}
          />
          {formData.brand_id && formData.credit_type ? (
            <>
              {formData.credit_type === 'other' && (
                <div className='mt-6 flex items-end space-x-4'>
                  <Select
                    label={'ASSO. ORDER'}
                    placeholder='Select'
                    value={formData.assoOrderType}
                    onChange={(value, item) => {
                      handleFormChange('assoOrderType', value)
                    }}
                    options={assoOrderOptions}
                    disabled={formDisabled}
                  />
                  {formData.assoOrderType === 'yes' && (
                    <>
                      <SingleInput
                        value={formData.order_id}
                        name='ASSO_ORDER_ID'
                        label='ASSO. ORDER ID'
                        onChange={e =>
                          handleFormChange('order_id', e.target.value)
                        }
                        disabled={formDisabled}
                      />
                      <Button
                        loading={ordering}
                        disabled={formDisabled}
                        color='secondary'
                        theme='outlined'
                        onClick={() => getCreditOrder()}
                      >
                        SYNC
                      </Button>
                    </>
                  )}
                </div>
              )}

              <ParagraphInput
                disabled={formDisabled}
                className='mt-6'
                value={formData.comment}
                name='CREDIT_REASON'
                label='CREDIT REASON'
                onChange={e => handleFormChange('comment', e.target.value)}
              />
              {formData.credit_type === 'odd' && (
                <SingleInput
                  className='mt-6'
                  disabled={formDisabled}
                  value={formData.confirmation}
                  name='DD_CONFIRMATION_LINK'
                  label='DD CONFIRMATION LINK'
                  onChange={e =>
                    handleFormChange('confirmation', e.target.value)
                  }
                />
              )}
              {formData.credit_type === 'odd' && (
                <div className='mt-6 flex items-end space-x-4'>
                  <SingleInput
                    disabled={formDisabled}
                    value={formData.order_id}
                    name='ASSO_ORDER_ID'
                    label='ASSO. ORDER ID'
                    onChange={e => handleFormChange('order_id', e.target.value)}
                  />
                  <Button
                    loading={ordering}
                    disabled={formDisabled}
                    color='secondary'
                    theme='outlined'
                    onClick={() => getCreditOrder()}
                  >
                    SYNC
                  </Button>
                </div>
              )}

              {formData.credit_type === 'other' &&
              formData.assoOrderType === 'no' ? (
                <SingleInput
                  disabled={formDisabled}
                  className='mt-6'
                  value={formData.amount}
                  textAlign='right'
                  name='CREDIT_AMOUNT'
                  label='CREDIT AMOUNT'
                  isDigit={true}
                  decimalPlaces={2}
                  iconBefore={<Dollar size={16} className='text-light-300' />}
                  onChange={e => {
                    handleFormChange('amount', e.target.value)
                  }}
                />
              ) : null}

              {formData.orderData &&
              (formData.credit_type === 'odd' ||
                (formData.credit_type === 'other' &&
                  formData.assoOrderType === 'yes')) ? (
                <>
                  <div className='mt-6 flex flex-col space-y-3'>
                    {Object.keys(orderItemOptions).map(
                      (item: any, index: number) => {
                        return (
                          <>
                            {!!formData.orderData[orderItemOptions[item]] && (
                              <div className='flex space-x-4' key={index}>
                                <div className='h-9 flex-1 rounded-lg px-4 py-2 bg-dark-700 flex justify-between text-light-500 text-sm'>
                                  <div>{item}</div>
                                  <div>
                                    {currencyFormatter(
                                      formData.orderData[
                                        orderItemOptions[item]
                                      ],
                                    )}
                                  </div>
                                </div>
                                {item === 'Subtotal' && (
                                  <SingleInput
                                    inputClassName='text-right'
                                    disabled={formDisabled}
                                    className='w-104'
                                    value={selectCreditData.subtotal || ''}
                                    name='subtotal'
                                    isDigit={true}
                                    decimalPlaces={2}
                                    iconBefore={
                                      <Dollar
                                        size={16}
                                        className='text-light-300'
                                      />
                                    }
                                    onChange={e => {
                                      handleSelectOrder(
                                        'subtotal',
                                        e.target.value,
                                      )
                                    }}
                                    onBlur={e => {
                                      const value = formatCurrency(
                                        e.target.value,
                                      )
                                      handleSelectBlur(value)
                                    }}
                                    onFocus={e => {
                                      handleSelectFocus(e.target.value)
                                    }}
                                  />
                                )}
                                <Checkbox
                                  size={22}
                                  checked={
                                    !!selectCreditData[orderItemOptions[item]]
                                  }
                                  onChange={value => {
                                    handleSelectOrder(
                                      orderItemOptions[item],
                                      formData.orderData[
                                        orderItemOptions[item]
                                      ],
                                    )
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )
                      },
                    )}
                    <div className='h-9 flex-1 rounded-lg px-4 py-2 bg-dark-700 flex items-center justify-center text-green text-sm font-bold'>
                      Total Credit:{' '}
                      {orderTotal && Number(orderTotal) > 0
                        ? `${currencyFormatter(orderTotal)}`
                        : '-'}
                    </div>
                  </div>
                </>
              ) : null}
              {canApprove && formData.status === 'pending' && (
                <Button
                  loading={approving}
                  className='mt-6'
                  color='secondary'
                  theme='contained'
                  onClick={() => handleApprove()}
                >
                  APPROVE TO ISSUE CREDIT
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </Drawer>
    </div>
  )
}
