import React, { useState, useEffect } from 'react'
import { AdminLayout } from 'components/Layout'
import Modal from 'components/Modal'
import BlocklistFilter from './components/BlocklistFilter'
import BlocklistTable from './components/BlocklistTable'
import { toast } from 'react-toastify'
import { getBlockList, getUnblocked } from 'redux/blocklist'
import useDebounce from 'hooks/useDebounce'

import type { TBlocklist } from './helpers/constant'

export default function BlocklistPage() {
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [unBlocking, setUnBlocking] = useState(false)
  const [blockModal, setBlockModal] = useState({ open: false, id: '' })

  const [blocklist, setBlocklist] = useState<TBlocklist>({
    name: '',
    type: '',
  })

  const [filterForm, setFilterForm] = useState<any>({
    type: '',
    keyword: '',
    begin_time: '',
    end_time: '',
    page: 1,
    per_page: 40,
  })

  const handleFormChange = (type: string, value: any) => {
    const page = type === 'page' ? value : 1
    let newForm = { ...filterForm }
    if (type === 'dates') {
      newForm = {
        ...filterForm,
        page,
        begin_time: !value
          ? ''
          : value[0]
              ?.startOf('day')
              ?.add(3, 'hours')
              ?.format('YYYY-MM-DD HH:mm:ss') || '',
        end_time: !value
          ? ''
          : value[1]
              ?.endOf('day')
              ?.add(3, 'hours')
              ?.format('YYYY-MM-DD HH:mm:ss') || '',
      }
    } else {
      newForm = {
        ...filterForm,
        page,
        [type]: value,
      }
    }
    setFilterForm(newForm)
    if (type === 'keyword') {
      handleKeyword(newForm)
    } else {
      setFilterForm(newForm)
      getTable(newForm)
    }
  }

  const handleKeyword = useDebounce((form: any) => {
    getTable(form)
  }, 500)

  const getTable = async (
    form: any,
    newBlocklist?: {
      name: string
      type: string
    },
  ) => {
    // 请求 api
    setLoading(true)
    try {
      const params = newBlocklist ? newBlocklist : blocklist
      if (params.name && params.type) {
        form['sort_by'] = params.name
        form['sort'] = params.type
      }
      const res = await getBlockList(form)
      setData(res.data)
      setTotal(res.total)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  const handleUnblock = async () => {
    setUnBlocking(true)
    try {
      const res = await getUnblocked(blockModal.id)
      if (res.message) {
        toast.success(res.message)
        setBlockModal({ open: false, id: '' })
        getTable(filterForm)
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setUnBlocking(false)
  }

  const onPageNoChange = (page: number) => {
    handleFormChange('page', page)
  }

  const handleBlocklistChange = (newBlocklist: TBlocklist) => {
    setBlocklist(newBlocklist)
    getTable(
      {
        ...filterForm,
      },
      newBlocklist,
    )
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <BlocklistFilter
          filterForm={filterForm}
          handleFormChange={handleFormChange}
        />
        <div className='mt-8'>
          <BlocklistTable
            loading={loading}
            total={total}
            current={filterForm.page}
            pageSize={filterForm.per_page}
            data={data}
            blocklist={blocklist}
            handleBlocklistChange={handleBlocklistChange}
            onPageNoChange={onPageNoChange}
            clickedRow={row => setBlockModal({ open: true, id: row.id })}
          />
        </div>
      </div>
      <Modal
        title='UNBLOCK'
        open={blockModal.open}
        toggle={() => setBlockModal({ open: false, id: '' })}
        okButtonProps={{ color: 'warning', loading: unBlocking }}
        cancelText={'CLOSE'}
        okText={'UNBLOCK'}
        onOk={() => handleUnblock()}
      >
        <div className='text-light-500 text-sm text-center'>
          Are sure to unblock this customer or payment method?
        </div>
      </Modal>
    </AdminLayout>
  )
}
