import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import SearchFilter from './components/SearchFilter'
import DisputeTable from './components/DisputeTable'
import DisputeHandlingModal from './components/DisputeHandlingModal'
import { getDisputes } from 'redux/dispute'

import { TFilterForm } from './helpers/constant'

export default function DisputePage() {
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(40)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectItem, setSelectItem] = useState<any>()

  const [filterForm, setFilterForm] = useState<TFilterForm>({
    status: '',
    brand_name: '',
    location_id: '',
    page: 1,
    sort: '',
    sort_by: '',
  })

  const handleFormChange = (type: string, value: any) => {
    const page = type === 'page' ? value : 1
    let newForm: TFilterForm = { ...filterForm }
    if (type === 'brand_name') {
      newForm = { ...filterForm, page, brand_name: value, location_id: '' }
    } else if (type === 'sort') {
      newForm = { ...filterForm, page, sort: value.value, sort_by: value.type }
    } else {
      newForm = { ...filterForm, page, [type]: value }
    }
    setFilterForm(newForm)
    getTable(newForm)
  }
  const getTable = async (form?: TFilterForm) => {
    const nowForm = form ? form : filterForm
    // 请求 api
    setLoading(true)
    try {
      const res = await getDisputes({
        ...nowForm,
        brand_name:
          nowForm.brand_name === 'All Brands' ? '' : nowForm.brand_name,
      })
      setLoading(false)
      setData(res.data)
      setTotal(res.total)
      setPageSize(res.per_page)
    } catch (e: any) {
      setLoading(false)
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }
  const onPageNoChange = (page: number) => {
    handleFormChange('page', page)
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])
  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <SearchFilter
          filterForm={filterForm}
          handleFormChange={handleFormChange}
        />
        <DisputeTable
          filterForm={filterForm}
          handleFormChange={handleFormChange}
          loading={loading}
          total={total}
          current={filterForm.page}
          pageSize={pageSize}
          data={data}
          onPageNoChange={onPageNoChange}
          setOpen={setOpen}
          setSelectItem={setSelectItem}
        />
        <DisputeHandlingModal
          open={open}
          item={selectItem}
          toggle={() => setOpen(!open)}
          getTable={getTable}
        />
      </div>
    </AdminLayout>
  )
}
