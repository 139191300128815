import { Routes, Route } from 'react-router-dom'
import MainPage from './MainPage'
import Detail from './Detail'
export default function AccountsPage() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}></Route>
      <Route path='/detail/:id' element={<Detail />} />
    </Routes>
  )
}
