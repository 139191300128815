import axiosInstance from 'utils/axios'

export async function getDomainsList(params: any) {
  const response = await axiosInstance.request({
    url: 'domain_registrations',
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getBrands(keyword?: string) {
  const response = await axiosInstance.request({
    url: 'domain_registrations/brands',
    method: 'get',
    params: {
      keyword,
    },
  })
  return response.data
}

export async function handleUpdateRenewed(id: string | number) {
  const response = await axiosInstance.request({
    url: `domain_registrations/${id}`,
    method: 'put',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function handleCheck(params: any) {
  const response = await axiosInstance.request({
    url: 'domain_registrations/search_new_domain',
    method: 'get',
    params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function handlePurchase(params: any) {
  const response = await axiosInstance.request({
    url: 'domain_registrations/purchase_new_domain',
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getTLDs() {
  const response = await axiosInstance.request({
    url: 'domain_registrations/tlds',
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
