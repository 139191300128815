import React from 'react'
import { useAppSelector } from 'redux/hooks'

import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import PersonName from 'components/PersonName'

import currency from 'currency.js'

export type TStatsByPeriodTableProp = {
  loading?: boolean
  onPageNoChange?: (value: number) => void
  handleFilterChange: (type: string, value: any) => void
  goDetail: (item: any) => void
}

export default function StatsByPeriodTable({
  loading,
  onPageNoChange,
  handleFilterChange,
  goDetail,
}: TStatsByPeriodTableProp): JSX.Element {
  const { page, total, filterForm, data, pageSize } = useAppSelector(
    state => state.sales,
  )
  const { sort_by, sort } = filterForm
  const columns = [
    {
      key: 'rank',
      name: 'RANK',
      className: 'w-16',
      align: 'center',
      custom: (value: number) => {
        let rank = ''
        switch (value) {
          case 1:
            rank = '🥇'
            break
          case 2:
            rank = '🥈'
            break
          case 3:
            rank = '🥉'
            break
          default:
            rank = value.toString()
            break
        }
        return <div>{rank}</div>
      },
    },
    {
      key: 'closer',
      name: 'SALES REP',
      custom: (item: any) => {
        const { profile_photo, first_name, last_name } = item
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={profile_photo}
              name={`${first_name} ${last_name}`}
            />
          </div>
        )
      },
    },
    {
      key: 'effort',
      name: 'EFFORT',
      className: 'w-96',
      sortable: true,
      sortDirection: sort_by === 'effort' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'effort',
          value,
        })
      },
      align: 'right',
      custom: (value: any) => {
        return <div>{Number(value) || 0}</div>
      },
    },
    {
      key: 'actual_quantity',
      name: 'ACTUAL',
      className: 'w-88',
      sortable: true,
      sortDirection: sort_by === 'actual' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'actual',
          value,
        })
      },
      align: 'right',
    },
    {
      key: 'sales_goal',
      name: 'QUOTA',
      className: 'w-20',
      align: 'right',
    },
    {
      key: 'pacing',
      name: 'PACING',
      className: 'w-120',
      custom: (value: number) => {
        const pacing = value ? value : 0
        const color = pacing >= 1 ? 'green' : pacing < 0.8 ? 'red' : 'orange'
        const newValue =
          pacing >= 1 ? 'On Track' : pacing < 0.8 ? 'Off Track' : 'Progressing'
        return <BadgeText value={newValue} color={color} />
      },
    },
    {
      key: 'closer_percentage',
      name: 'TEAM CONT',
      className: 'w-112',
      align: 'center',
      sortable: true,
      sortDirection: sort_by === 'team_cont' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'team_cont',
          value,
        })
      },
      custom: (value: string) => {
        return <div>{value}%</div>
      },
    },
    {
      key: 'volume_amount',
      name: 'VOLUME',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'volume' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'volume',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value, { precision: 0 }).format()}
          </div>
        )
      },
    },
    {
      key: 'signup_amount',
      name: 'SIGNUP $',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'signup' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'signup',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value, { precision: 0 }).format()}
          </div>
        )
      },
    },
    {
      key: 'self_gen_amount',
      name: 'SELF-GEN $',
      className: 'w-112',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'self_gen' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'self_gen',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value, { precision: 0 }).format()}
          </div>
        )
      },
    },
    {
      key: 'setup_fee_amount',
      name: 'SETUP $',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'setup_fee' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'setup_fee',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value, { precision: 0 }).format()}
          </div>
        )
      },
    },
    {
      key: 'adjustment_amount',
      name: 'ADJ $',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'adj' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'adj',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            ({currency(value, { precision: 0 }).format()})
          </div>
        )
      },
    },
    {
      key: 'total_amount',
      name: 'TOTAL $',
      className: 'w-112',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'total' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'total',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value, {
              negativePattern: '(!#)',
              precision: 0,
            }).format()}
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Table
        loading={loading}
        current={page}
        resizePageSize={pageSize}
        columns={columns}
        data={data}
        total={total}
        onPageNoChange={value => onPageNoChange?.(value)}
        clickedRow={goDetail}
      />
    </div>
  )
}
