import React, { useState, useEffect } from 'react'

import { Plus } from '@rushable/icons'
import Button from 'components/Button'
import Modal from 'components/Modal'
import SingleInput from 'components/SingleInput'

import type { TCount } from '../helpers/constant'

export type TEffortPointsCalSideBarProp = {
  className?: string
  loading: boolean
  pointTotal: number
  count: TCount[]
  open: boolean
  toggle: () => void
  handleAddOther: (description: string, hoursSpent: string) => void
}

export default function EffortPointsCalSideBar({
  className = '',
  loading,
  pointTotal,
  count,
  open,
  toggle,
  handleAddOther,
}: TEffortPointsCalSideBarProp): JSX.Element {
  const [description, setDescription] = useState('')
  const [hourSpent, setHourSpent] = useState('')

  useEffect(() => {
    if (open) {
      setDescription('')
      setHourSpent('')
    }
  }, [open])

  return (
    <div
      className='w-400 border-r border-dark-100 text-light-300 text-xs'
      style={{ minHeight: 'calc(100vh - 72px)' }}
    >
      <div className='pt-3 pb-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='font-bold'>Total Effort Points</div>
        <div className='text-right font-bold text-base text-green'>
          {pointTotal} pt
        </div>
      </div>
      {count.map(item => {
        return (
          <div
            className='py-4 px-10 space-x-4  flex items-center border-b border-dark-100'
            key={item.id}
          >
            <div className='w-104 font-bold'>{item.activity_name}</div>
            <div className='w-12 text-right'>{item.unit_pt || 0} pt</div>
            <div className='w-12 text-right'>x {item.quantity || 0}</div>
            <div className='w-72 text-right font-bold text-base text-light-700'>
              {item.value || 0} pt
            </div>
          </div>
        )
      })}
      <div className='mt-4 pl-10'>
        <Button theme='text' color='primary' onClick={toggle}>
          <Plus size={12} className='mr-1' />
          ADD OTHER EFFORT
        </Button>
      </div>
      <Modal
        title='Add Other Effort'
        cancelText='CLOSE'
        okText='CONFIRM'
        open={open}
        onOk={() => handleAddOther(description, hourSpent)}
        okButtonProps={{
          disabled: description.length === 0 || hourSpent.length === 0,
          loading,
        }}
        toggle={() => toggle()}
      >
        <>
          <SingleInput
            value={description}
            name='OTHER_DESCRIPTION'
            label='DESCRIPTION'
            placeholder='Input'
            onChange={event => setDescription(event.target.value)}
          />
          <SingleInput
            className='mt-6'
            value={hourSpent}
            name='HOURS_SPENT'
            label='HOURS SPENT'
            placeholder='Input'
            onChange={event => setHourSpent(event.target.value)}
          />
        </>
      </Modal>
    </div>
  )
}
