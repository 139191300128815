import React, { useState, useEffect } from 'react'

import cn from 'classnames'
import { SolidStore, SolidMapPin, SolidUser } from '@rushable/icons'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import PaymentInfo from 'components/PaymentInfo'
import DateAndTime from 'components/DateAndTime'
import currency from 'currency.js'

import type { TBlocklist } from '../helpers/constant'

type TRowProp = {
  [index: string]: any
}

export type TBlocklistTableProp = {
  className?: string
  data: Array<TRowProp>
  total: number
  loading?: boolean
  current?: number
  pageSize?: number
  blocklist: TBlocklist
  handleBlocklistChange: (newBlocklist: TBlocklist) => void
  onPageNoChange?: (value: any) => void
  clickedRow: (value: any) => void
}

export default function BlocklistTable({
  className = '',
  loading,
  data,
  total,
  current,
  pageSize,
  blocklist,
  handleBlocklistChange,
  onPageNoChange,
  clickedRow,
}: TBlocklistTableProp): JSX.Element {
  const columns = [
    {
      key: 'type',
      name: 'TYPE',
      className: 'w-160',
      custom: (value: any) => {
        let color: any = ''
        let status: string = ''
        switch (value) {
          case 'payment':
            color = 'red'
            status = 'Payment Blocked'
            break
          case 'user':
            color = 'default'
            status = 'User Blocked'
            break
        }
        return <BadgeText value={status} color={color} />
      },
    },
    {
      key: 'location',
      name: 'SOURCE BRAND',
      className: 'w-48',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <SolidStore size={16} className='mr-1 text-light-100' />
            <div className='text-ellipsis'>{value?.brand?.name}</div>
          </div>
        )
      },
    },
    {
      key: 'location',
      name: 'SOURCE LOCATION',
      className: 'w-48',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <SolidMapPin size={16} className='mr-1 text-light-100 ' />
            <div className='text-ellipsis'>{value?.name}</div>
          </div>
        )
      },
    },
    {
      key: 'customer',
      name: 'CUSTOMER',
      className: 'w-48',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <SolidUser size={16} className='mr-1 text-light-100' />
            <div className='text-ellipsis'>
              {value?.first_name} {value?.last_name}
            </div>
          </div>
        )
      },
    },
    {
      key: 'reason',
      name: 'REASON',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value || '-'}</div>
      },
    },
    {
      key: 'order',
      name: 'PAYMENT',
      className: 'w-32',
      custom: (value: any) => {
        return (
          <PaymentInfo
            brand={value?.payment_card_brand || 'unknown'}
            last4={value?.payment_card_last_4}
          />
        )
      },
    },
    {
      key: 'amount',
      name: 'AMOUNT',
      align: 'right',
      className: 'w-24',
      sortable: true,
      sortDirection: blocklist.name === 'amount' ? blocklist.type : '',
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {value ? currency(value).format() : '-'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleBlocklistChange({
          name: 'amount',
          type: value,
        })
      },
    },
    {
      key: 'created_at',
      name: 'BLOCKED SINCE',
      align: 'right',
      className: 'w-196',
      sortable: true,
      sortDirection: blocklist.name === 'blocked_since' ? blocklist.type : '',
      custom: (value: string, item: any) => {
        return (
          <div className='flex justify-end'>
            <DateAndTime
              format='MMM DD @ hh:mm A'
              parse='YYYY-MM-DD HH:mm:ss'
              timezone={item?.location?.address?.timezone}
              value={value}
              noExpired={true}
            />
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleBlocklistChange({
          name: 'blocked_since',
          type: value,
        })
      },
    },
  ]

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={value => onPageNoChange?.(value)}
        clickedRow={row => clickedRow(row)}
      />
    </div>
  )
}
