import { createReducer } from '@reduxjs/toolkit'

import { getReferrals, getReferrers } from './action'

type TReferralState = {
  getReferralsRequest: boolean
}

const initialState: TReferralState = {
  getReferralsRequest: false,
}

export const referralReducer = createReducer(initialState, builder => {
  builder.addCase('', state => {
    state.getReferralsRequest = true
  })
})

export default referralReducer
