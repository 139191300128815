export type TFilterForm = {
  status:
    | ''
    | 'needs_response'
    | 'under_review'
    | 'won'
    | 'charge_refunded'
    | 'lost'
  brand_name: string
  location_id: string
  page: number
  sort_by: string
  sort: string
}

export const statusOptions = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Won',
    value: 'won',
  },
  {
    label: 'Lost',
    value: 'lost',
  },
  {
    label: 'Needs Response',
    value: 'needs_response',
  },
  {
    label: 'Under Review',
    value: 'under_review',
  },
  {
    label: 'Early Refunded',
    value: 'charge_refunded',
  },
]
