import React from 'react'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import AccountStage from 'components/AccountStage'
import { DuoToneBank } from '@rushable/icons'
import currency from 'currency.js'

type TRowProp = {
  [index: string]: any
}

export type TPayoutDetailTableProps = {
  loading: boolean
  total: number
  current: number
  pageSize: number
  data: Array<TRowProp>
  sortData: {
    sort_by: string
    sort: string
  }
  handleFilterChange: (type: string, value: any) => void
  onPageNoChange: (page: number) => void
  clickedRow: (row: any) => void
}

export default function PayoutDetailTable({
  loading,
  total,
  current,
  pageSize,
  data,
  sortData,
  handleFilterChange,
  onPageNoChange,
  clickedRow,
}: TPayoutDetailTableProps) {
  const { sort_by, sort } = sortData
  const columns = [
    {
      key: 'stripe_status',
      name: 'STATUS',
      className: 'w-120 bg-dark-900',
      sticky: true,
      stickyClassName: '0px',
      custom: (value: string) => {
        let color: any = ''
        switch (value) {
          case 'pending':
            color = 'default'
            value = 'Scheduled'
            break
          case 'paid':
            color = 'green'
            value = 'Done'
            break
          case 'in_transit':
            color = 'orange'
            value = 'In Transit'
            break
          case 'failed':
            color = 'red'
            value = 'Stuck'
            break
        }
        return <BadgeText value={value} color={color} />
      },
    },
    {
      key: 'location',
      name: 'BRAND',
      className: 'w-200 bg-dark-900',
      sticky: true,
      stickyClassName: '120px',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.brand?.name || '-'}</div>
      },
    },
    {
      key: 'location',
      name: 'LOCATION',
      className: 'w-40 bg-dark-900',
      sticky: true,
      stickyClassName: '320px',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.name || '-'}</div>
      },
    },
    {
      key: 'location',
      name: 'BALANCE',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'balance' ? sort : '',

      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {currency(value?.current_balance?.stripe_amount).format()}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'balance',
          value,
        })
      },
    },
    {
      key: 'stripe_amount',
      name: 'PAYOUT',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'payout' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'payout',
          value,
        })
      },
    },
    {
      key: 'last_4',
      name: 'BANK ACCOUNT',
      sortable: true,
      className: 'w-60',
      sortDirection: sort_by === 'last_4' ? sort : '',

      custom: (value: string, item: any) => {
        return (
          <div className='flex items-center'>
            <DuoToneBank size={20} className='mr-2' />

            {value || item?.location?.location_payment_method?.last_4 ? (
              <>
                •••• {value || item?.location?.location_payment_method?.last_4}
                <div className='ml-1 text-xxs-z text-light-100 text-ellipsis'>
                  {item.bank_name}
                </div>
              </>
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'last_4',
          value,
        })
      },
    },
    {
      key: 'raw_total',
      name: 'RAW TOTAL',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'raw_total' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'raw_total',
          value,
        })
      },
    },
    {
      key: 'sales_tax',
      name: 'SALES TAX',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'sales_tax' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'sales_tax',
          value,
        })
      },
    },
    {
      key: 'custom_fees',
      name: 'CUST. FEES',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'custom_fees' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'custom_fees',
          value,
        })
      },
    },
    {
      key: 'restaurant_fees',
      name: 'RESTO. FEES',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'restaurant_fees' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'restaurant_fees',
          value,
        })
      },
    },
    {
      key: 'refunds',
      name: 'REFUNDS',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'refunds' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'refunds',
          value,
        })
      },
    },
    {
      key: 'adjustment',
      name: 'ADJ',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'adjustment' ? sort : '',

      custom: (value: any) => {
        return <div className='text-ellipsis'>{currency(value).format()}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'adjustment',
          value,
        })
      },
    },
    {
      key: 'stage_days',
      name: 'STAGEDAYS',
      align: 'right',
      sortable: true,
      className: 'w-120',
      sortDirection: sort_by === 'stage_days' ? sort : '',
      custom: (value: any, row: any) => {
        return <>{value ? <div>{value} days</div> : '-'}</>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'stage_days',
          value,
        })
      },
    },
    {
      key: 'stage',
      name: 'STAGE',
      align: 'right',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'stage' ? sort : '',

      custom: (value: any, row: any) => {
        return <AccountStage className='w-20 ml-auto' status={value} />
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'stage',
          value,
        })
      },
    },
  ]

  return (
    <div>
      <Table
        containerClassName='overflow-hidden overflow-x-scroll'
        className='mb-20'
        sticky={true}
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={clickedRow}
      />
    </div>
  )
}
