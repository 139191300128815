import { createReducer } from '@reduxjs/toolkit'

type TState = {
  getCreditsRequest: boolean
}

const initialState: TState = {
  getCreditsRequest: false,
}

export const creditReducer = createReducer(initialState, builder => {
  builder.addCase('', state => {
    state.getCreditsRequest = true
  })
})

export default creditReducer
