import axiosInstance from 'utils/axios'

export type TCreditParams = {
  brand_id: string
  location_id: string
  order_id: string
  page: number
  status: string
  sort_by: string
  sort: string
}

export async function getCredits(params: TCreditParams) {
  const response = await axiosInstance.request({
    url: 'credits',
    method: 'get',
    params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getCreditDetail(credit_id: string) {
  const response = await axiosInstance.request({
    url: `credits/${credit_id}`,
    method: 'get',
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getBrands(params: { type: string; brand_name: string }) {
  const response = await axiosInstance.request({
    url: 'credits/option/brands',
    method: 'get',
    params: {
      type: params.type,
      brand_name: params.brand_name,
    },
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getLocations(id: number) {
  const response = await axiosInstance.request({
    url: 'credits/option/locations',
    method: 'get',
    params: {
      brand_id: id,
    },
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getCreditOrders(params: any) {
  const response = await axiosInstance.request({
    url: 'credits/option/order',
    method: 'get',
    params: {
      order_id: params.orderId,
      location_id: params.locationId,
      type: params.type,
    },
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function createCreditOrder(params: any) {
  const response = await axiosInstance.request({
    url: 'credits',
    method: 'post',
    data: params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function approveCredit(params: any) {
  const response = await axiosInstance.request({
    url: 'credits/option/approve',
    method: 'post',
    data: params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function discardCredit(creditId: number) {
  const response = await axiosInstance.request({
    url: `credits/${creditId}`,
    method: 'delete',
    cache: { ignoreCache: true },
  })
  return response.data
}
