import React from 'react'

import cn from 'classnames'

export type TLabelProp = {
  children?: React.ReactNode
  className?: string
  htmlFor?: string
}

export default function Label({
  children,
  className = '',
  htmlFor,
}: TLabelProp): JSX.Element {
  return (
    <label
      className={cn(
        'ru-label w-full text-light-300 text-xs font-bold select-all block',
        className,
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  )
}
