import { ReactElement, useRef } from 'react'
import cn from 'classnames'

import useOnClickOutside from 'hooks/useOnClickOutside'

export type TActionDropdownProp = {
  open: boolean
  children?: ReactElement | ReactElement[]
  closeDropdown?: () => void
  className?: string
}

export default function ActionDropdown({
  open,
  children,
  closeDropdown,
  className,
}: TActionDropdownProp): JSX.Element {
  const ref = useRef(null)

  const handleClickOutside = () => {
    closeDropdown && closeDropdown()
  }
  useOnClickOutside(ref, handleClickOutside)

  return (
    <>
      {open && (
        <div
          ref={ref}
          className={cn(
            'absolute z-50 max-w-max bg-dark-500 border border-solid border-dark-100 rounded-lg px-4 py-3 w-96',
            className,
          )}
        >
          {children}
        </div>
      )}
    </>
  )
}
