import React from 'react'
import type {
  ControlProps,
  StylesConfig,
  OptionProps,
  MultiValueGenericProps,
  IndicatorsContainerProps,
} from 'react-select'
import { components } from 'react-select'
import {
  AngleDown,
  AngleUp,
  Check,
  SolidStore,
  SolidMapPin,
  SolidUserCircled,
} from '@rushable/icons'
import PersonName from 'components/PersonName'

export interface IOptions {
  label: string
  value: string | number
  isDisabled?: boolean
  isLoading?: boolean
  optionChildren?: React.ReactElement
  [propName: string]: any
}

export const customStyles: StylesConfig<IOptions> = {
  container: styles => {
    return {
      ...styles,
      borderRadius: 8,
      height: '40px',
    }
  },

  placeholder: styles => {
    return {
      ...styles,
      marginLeft: '0px',
      marginRight: '0px',
      color: '#4C515C',
      fontSize: '14px',
      lineHeight: '140%',
      paddingTop: '8px',
      paddingBottom: '8px',
    }
  },

  menuList: provided => ({
    ...provided,
    borderTop: '1px solid #292A33',
  }),

  menu: provided => ({
    ...provided,
    backgroundColor: '#000016',
    padding: 0,
    marginTop: '-2px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderLeft: '2px solid #E9E9E9',
    borderRight: '2px solid #E9E9E9',
    borderBottom: '2px solid #E9E9E9',
    overflow: 'hidden',
    boxShadow: 'none',
    zIndex: 11,
  }),
  control: (provided, state) => {
    const borderBottom = state.menuIsOpen
      ? '2px solid #363636'
      : '2px solid transparent'
    const borderBottomLeftRadius = state.menuIsOpen ? 0 : 8
    const borderBottomRightRadius = state.menuIsOpen ? 0 : 8
    const backgroundColor = state.menuIsOpen ? '#000016' : '#292a33'
    const borderColor = state.menuIsOpen ? '#E9E9E9' : '#292a33'

    return {
      ...provided,
      borderWidth: 2,
      cursor: 'pointer',
      borderBottom,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      boxShadow: 'none',
      paddingLeft: 16,
      paddingRight: 16,
      minHeight: 20,
      height: '100%',
      backgroundColor: state.isDisabled ? '#121217' : backgroundColor,
      borderColor: state.isDisabled ? '#121217' : borderColor,
      ':focus': {
        borderColor: '#E9E9E9',
        borderBottomColor: '#363636',
      },
      ':active': {
        borderColor: '#E9E9E9',
        borderBottomColor: '#363636',
      },
      ':hover': {
        borderColor: '#E9E9E9',
        borderBottomColor: state.menuIsOpen ? '#363636' : '#E9E9E9',
      },
    }
  },
  valueContainer: styles => {
    return {
      ...styles,
      height: '100%',
      padding: 0,
    }
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: 0,
      padding: '8px 0px',
      fontSize: '14px',
      lineHeight: '140%',
      color: state.isDisabled ? '#B2B2B7' : '#E9E9E9',
      position: 'relative',
      visibility: 'visible',
    }
  },

  option: (provided, state) => {
    return {
      ...provided,
      padding: 0,
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      fontSize: '14px',
      lineHeight: '140%',
      color: state.isMulti && state.isSelected ? '#E9E9E9' : '#74747F',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: '#292a33',
      },
    }
  },

  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
    backgroundColor: 'transparent',
  }),

  singleValue: (provided, state) => {
    const transition = 'opacity 300ms'
    return {
      ...provided,
      fontSize: '14px',
      lineHeight: '140%',
      color: state.isDisabled ? '#B2B2B7' : '#E9E9E9',
      transition,
      margin: 0,
    }
  },
}

// @ts-ignore
export const customTheme = theme => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary25: '#292A33',
    primary: '#292A33',
  },
})

export const Control = ({ children, ...props }: ControlProps<any, false>) => {
  // @ts-ignore
  const { icon } = props.selectProps
  return (
    <components.Control {...props}>
      {icon || ''}
      {children}
    </components.Control>
  )
}

// 上下箭头图标
export const IndicatorsContainer = (
  props: IndicatorsContainerProps<IOptions>,
) => {
  return (
    <components.IndicatorsContainer {...props}>
      <div className='pl-2 pr-0'>
        {!props.isDisabled && (
          <div style={{ color: 'rgb(116, 116, 127)', display: 'flex' }}>
            {props.selectProps.menuIsOpen ? <AngleUp /> : <AngleDown />}
          </div>
        )}
      </div>
    </components.IndicatorsContainer>
  )
}
// 定位图标
export const AreaIndicatorsContainer = (
  props: IndicatorsContainerProps<IOptions>,
) => {
  return (
    <components.IndicatorsContainer {...props}>
      <SolidMapPin size={16} className='text-light-300' />
    </components.IndicatorsContainer>
  )
}

export const EmptyIndicatorsContainer = (
  props: IndicatorsContainerProps<IOptions>,
) => {
  return (
    <components.IndicatorsContainer {...props}>
      <div></div>
    </components.IndicatorsContainer>
  )
}

export const BrandIndicatorsContainer = (
  props: IndicatorsContainerProps<IOptions>,
) => {
  return (
    <components.IndicatorsContainer {...props}>
      <div className='pl-2 pr-0'>
        {!props.isDisabled && (
          <div style={{ color: 'rgb(116, 116, 127)', display: 'flex' }}>
            {props.selectProps.menuIsOpen ? (
              <AngleUp />
            ) : (
              <SolidStore size={16} />
            )}
          </div>
        )}
      </div>
    </components.IndicatorsContainer>
  )
}

export const customMultiOptions = (props: OptionProps<IOptions>) => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className='w-full'>
        {data.optionChildren ? (
          data.optionChildren
        ) : (
          <div className='px-4 flex justify-between items-center'>
            {data.label}
            <Check className={`${props.isSelected && 'text-blue'}`} />
          </div>
        )}
      </div>
    </components.Option>
  )
}

export const customSingleOption = (props: OptionProps<IOptions>) => {
  const { data } = props
  return (
    <components.Option {...props}>
      {data.optionChildren ? (
        data.optionChildren
      ) : (
        <span className={'w-full pl-4 pr-2.5'}>{data.label}</span>
      )}
    </components.Option>
  )
}

export const customPersonOption = (props: OptionProps<IOptions>) => {
  const { data } = props
  return (
    <components.Option {...props}>
      <PersonName
        className='pl-4'
        avatarSize={20}
        avatar={data.img_url}
        name={data.label}
      />
    </components.Option>
  )
}

// 自定义多选 control 内的样式
export const MultiValueContainer = (
  props: MultiValueGenericProps<IOptions>,
) => {
  const { data, selectProps } = props
  const len = selectProps.value?.length
  // @ts-ignore
  const isComma = selectProps.value?.[len - 1].value !== data.value
  return (
    <div className='text-light-700 text-sm'>
      <span>{data.label}</span>
      {isComma && <span>,&nbsp;</span>}
    </div>
  )
}
