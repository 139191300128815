import { useState, useContext, useEffect } from 'react'
import cn from 'classnames'
import currency from 'currency.js'
import { useSearchParams, useParams } from 'react-router-dom'
import {
  DuoToneMoney,
  Check,
  LinedPenEdit,
  Dollar,
  SolidFile,
  AngleLeft,
  AngleRight,
  SolidTrashCan,
} from '@rushable/icons'
import { useCompare } from 'hooks/useDebounce'
import { useAppSelector } from 'redux/hooks'

import { toast } from 'react-toastify'
import Label from 'components/Label'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Drawer from 'components/Drawer'
import DragBar from 'components/DragBar'
import SingleInput from 'components/SingleInput'
import { Select } from 'components/Select'
import OnlinePresence from './SalesTable'
import { approveSignupsApi } from 'redux/account'
import SignupFormContext from './context'

interface Iprops {
  className?: string
  disabled: boolean
  products: Tfee[] | []
  onChange: (info: Tfee[]) => void
}

export type Tfee = {
  subscription: string
  discount: string
  dispatchFee: string
  setupFee: string
  setupFeeDiscount: string
  isFreeWebsite: number // 1/0 是否提供免费网站
}

const INITINFO = {
  subscription: '149.00',
  discount: '0.00',
  dispatchFee: '1.50',
  setupFee: '199.00',
  setupFeeDiscount: '',
  isFreeWebsite: 1,
}

const AFFILIATEINITINFO = {
  subscription: '149.00',
  discount: '0.00',
  dispatchFee: '0.50',
  setupFee: '199.00',
  setupFeeDiscount: '',
  isFreeWebsite: 1,
}

// Subscription discount  CONVENIENCE FEE
const getCalcInfo = (info: Tfee): any => {
  // Subscription discount= 白色块 = SUBSCRIPTION - discount
  const discountSub = currency(info.subscription).subtract(
    info.discount || 0,
  ).value

  // 公式 =1.49-(黄色块-49)*0.00496
  const convenienceFee = currency(1.49).subtract(
    currency(info.subscription).subtract(49).multiply(0.00496),
  ).value

  return {
    discountSub,
    convenienceFee,
  }
}
// ESSENTIAL OFFERING / ENSSENTIAL ONLINE PRESENCE
const getOfferingTableData = (info: Tfee) => {
  const { discountSub, convenienceFee } = getCalcInfo(info)

  return [
    {
      item: 'Monthly fee',
      amount: (
        <>
          {currency(info.discount).value !== 0 && (
            <span className='line-through'>
              {currency(info.subscription).format()}
            </span>
          )}
          <span className='mx-1'>{currency(discountSub).format()}</span>
        </>
      ),
      occurrence: 'per month',
    },
    {
      item: 'Convenience fee',
      amount: currency(convenienceFee).format(),
      occurrence: 'per order',
    },
    {
      item: 'Dispatch fee',
      amount: currency(info.dispatchFee).format(),
      occurrence: 'per delivery',
    },
    {
      item: 'Setup fee',
      amount: currency(info.setupFee).subtract(info.setupFeeDiscount).format(),
      occurrence: +info.setupFee === 199 ? 'at launch' : 'delayed',
    },
  ]
}

// ENSSENTIAL SALES VOLUME
const getSalesTableData = (info: Tfee) => {
  const { convenienceFee } = getCalcInfo(info)
  return [
    {
      item: 'Monthly fee volume',
      amount: currency(info.subscription).format(),
      x: 'x12',
      volume: currency(info.subscription).multiply(12).format(),
    },
    {
      item: 'Monthly fee discount',
      amount: `-${currency(info.discount).format()}`,
      x: 'x12',
      volume: <span>-{currency(info.discount).multiply(12).format()}</span>,
    },
    {
      item: 'Convenience fee volume',
      amount: currency(convenienceFee).format(),
      x: 'x720',
      volume: currency(convenienceFee).multiply(720).format(),
    },
    {
      item: 'Dispatch fee',
      amount: currency(info.dispatchFee).format(),
      x: 'x72',
      volume: currency(info.dispatchFee).multiply(72).format(),
    },
  ]
}

// Total volume
export const getSalesTotal = (info: Tfee = {} as any) => {
  const { convenienceFee } = getCalcInfo(info)
  const subscription = currency(info.subscription).multiply(12)
  const discount = currency(info.discount).multiply(12)
  const dispatchFee = currency(info.dispatchFee).multiply(72)
  const convenience_fee_volume = currency(convenienceFee).multiply(720)
  return subscription
    .subtract(discount)
    .add(dispatchFee)
    .add(convenience_fee_volume)
    .format()
}

const MIN = '49.00'
const MAX = '349.00'

export default function SignupRight({
  className,
  products,
  disabled,
  onChange,
}: Iprops) {
  const [searchParams] = useSearchParams()
  const params = useParams()
  const context = useContext(SignupFormContext)
  const authMap = useAppSelector(s => s.auth.authMap)
  const isAffiliate =
    searchParams.get('from') === 'affiliate' ||
    !!(authMap?.roleId && authMap?.roleId >= 101 && authMap?.roleId <= 199)
  const [salesName, setSalesName] = useState('1')
  const [showSalesModal, setShowSalesModal] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showFeeModal, setShowFeeModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [feeInfo, setFeeInfo] = useState({ ...INITINFO })
  const [formStatus, setFormStatus] = useState(true)

  const canApprove = useAppSelector(s => s.auth.authMap)?.roleId === 1

  const compareFunc = useCompare()

  // 控制 confirm 按钮显示隐藏
  useEffect(() => {
    if (products?.length) {
      compareFunc(products[0], feeInfo, (flag: boolean) => {
        setFormStatus(flag)
      })
    }
  }, [products, feeInfo])

  const handleSaveProduct = (isDelete = false) => {
    const arr = isDelete ? [] : [{ ...feeInfo }]
    onChange(arr)
    setShowDrawer(false)
  }
  const handleApprove = async () => {
    setLoading(true)
    try {
      const res = await approveSignupsApi(params.id!)
      toast.success(res.message)
      context.handleInit()
    } catch (error: any) {
      toast.error(error.message || 'Request error')
    }
    setLoading(false)
  }
  const { discountSub, convenienceFee } = getCalcInfo(feeInfo)
  const signupForm = context.data
  const approvals = signupForm?.signup_form_approvals || []

  return (
    <div className={cn(className)}>
      <Label className='mb-2'>SALES OFFERING</Label>
      {products.length < 1 && (
        <Button
          className='w-full'
          theme='outlined'
          color='secondary'
          disabled={disabled}
          onClick={() => {
            setShowSalesModal(true)
            setFormStatus(true)
            // 初始化赋值
            setFeeInfo(isAffiliate ? { ...AFFILIATEINITINFO } : { ...INITINFO })
          }}
        >
          ADD PRODUCT
        </Button>
      )}
      {!!products.length && (
        <div className='p-4 my-6 bg-dark-500 rounded-lg'>
          <div className='flex items-center'>
            <DuoToneMoney size={24} />
            <span className='text-base text-light-700 ml-2'>
              Total sales volume
            </span>
            <span className='flex-1 text-right font-bold text-light-700 text-2xl'>
              {getSalesTotal(products[0])}
            </span>
          </div>
          {!!approvals.length && (
            <div className='p-4 mt-3 border border-dark-100 rounded-lg'>
              <p className='text-xs text-light-300'>
                Items need approval or adjustment:
              </p>
              {approvals?.map((item: any, index: number) => {
                const isPass = !!item.approved_at && !!item.approved_by
                return (
                  <div className='my-2 flex justify-between' key={index}>
                    <span className='text-xs text-yellow'>{item.name}</span>
                    <Check className={isPass ? 'text-green' : ''} />
                  </div>
                )
              })}
              {canApprove && signupForm.isApprove && (
                <Button
                  className='w-full mt-4'
                  color='secondary'
                  loading={loading}
                  onClick={handleApprove}
                >
                  APPROVE
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {/* 目前仅有一种情况 ENSSENTIAL ONLINE PRESENCE 后续可能需要遍历多个 */}
      {/* 外部展现数据靠 products 提供，drawer 内的数据由组件内 feeinfo 提供 */}
      {products.length >= 1 && (
        <OnlinePresence
          type='online'
          className='flex-1'
          data={getOfferingTableData(products[0])}
          label='ENSSENTIAL ONLINE PRESENCE'
          labelRight={
            <>
              {!disabled && (
                <Button
                  className='font-bold text-blue'
                  theme='text'
                  size='small'
                  iconBefore={<SolidFile size={12} />}
                  onClick={() => {
                    setShowDrawer(true)
                    setFormStatus(false)
                    setFeeInfo({ ...products[0] })
                  }}
                >
                  EDIT OFFERING
                </Button>
              )}
            </>
          }
        />
      )}

      <Modal
        title='SALES OFFERING'
        cancelText='CLOSE'
        open={showSalesModal}
        onOk={() => {
          setShowSalesModal(false)
          setTimeout(() => {
            setShowDrawer(true)
          }, 500)
        }}
        toggle={() => setShowSalesModal(false)}
      >
        <Select
          label='Label name'
          placeholder='Select...'
          value={salesName}
          onChange={(value, item) => {
            setSalesName(value)
          }}
          options={[{ label: 'Enssential Online Presence', value: '1' }]}
        />
      </Modal>
      <Drawer
        title='ESSENTIAL ONLINE PRESENCE'
        cancelText='CLOSE'
        open={showDrawer}
        footer={
          <div className='w-full flex justify-between'>
            <div className='flex'>
              {!!products?.length && (
                <Button
                  color='warning'
                  theme='text'
                  iconBefore={<SolidTrashCan size={12} />}
                  onClick={() => handleSaveProduct(true)}
                >
                  DELETE
                </Button>
              )}
            </div>
            <div className='flex'>
              <Button color='secondary' onClick={() => setShowDrawer(false)}>
                CLOSE
              </Button>
              {formStatus && (
                <Button className='ml-4' onClick={() => handleSaveProduct()}>
                  CONFIRM
                </Button>
              )}
            </div>
          </div>
        }
        toggle={() => setShowDrawer(false)}
      >
        <div className='mb-2 flex justify-between text-xs text-light-300'>
          <span>SUBSCRIPTION</span>
          <span>CONVENIENCE FEE</span>
        </div>
        <div className='flex justify-between font-bold text-base text-white'>
          <div className='flex items-center'>
            {currency(feeInfo.discount).value !== 0 && (
              <span className='text-yellow line-through'>
                {currency(feeInfo.subscription).format()}
              </span>
            )}
            <span className=' mx-1'>{currency(discountSub).format()}/mo</span>
            <Button
              theme='text'
              color='secondary'
              onClick={() => setShowFeeModal(true)}
            >
              <LinedPenEdit size={16} />
            </Button>
          </div>
          <div className=''>{currency(convenienceFee).format()}/order</div>
        </div>
        <DragBar
          className='mt-2.5'
          min={+feeInfo.discount > +MIN ? +feeInfo.discount : +MIN}
          discount={+feeInfo.discount}
          max={Number(MAX)}
          value={+feeInfo.subscription}
          onChange={value => {
            setFeeInfo(prev => ({ ...prev, subscription: value?.toFixed(2) }))
          }}
        />
        {isAffiliate ? (
          <></>
        ) : (
          <div className='mt-18 mb-10 text-sm text-light-500'>
            <div className='flex justify-between items-center'>
              <span>Subscription discount</span>
              <SingleInput
                value={feeInfo.discount}
                iconBefore={<Dollar />}
                textAlign='right'
                decimalPlaces={2}
                className='w-28 text-right'
                name='subscript_discount'
                placeholder='Input...'
                onBlur={(e: any) => {
                  setFeeInfo(prev => ({
                    ...prev,
                    discount: Number(e.target.value)?.toFixed(2),
                  }))
                }}
                onChange={(e: any) => {
                  const value = parseInt(e.target.value || 0)
                  if (value <= Number(feeInfo.subscription)) {
                    setFeeInfo(prev => ({
                      ...prev,
                      discount: value + '',
                    }))
                  }
                }}
              />
            </div>
            <div className='flex justify-between items-center my-4'>
              <span>Dispatch fee</span>
              <SingleInput
                value={feeInfo.dispatchFee}
                iconBefore={<Dollar />}
                textAlign='right'
                isDigit={true}
                decimalPlaces={2}
                className='w-28 text-right'
                name='subscript_dispatchFee'
                placeholder='Input...'
                onChange={(e: any) =>
                  setFeeInfo(prev => ({
                    ...prev,
                    dispatchFee: e.target.value || 0,
                  }))
                }
              />
            </div>
            <div className='flex justify-between items-center my-4'>
              <span>Setup fee schedule</span>
              <Select
                className='w-184'
                value={feeInfo.setupFee}
                onChange={(value, item) => {
                  setFeeInfo(prev => ({ ...prev, setupFee: value }))
                }}
                options={[
                  { label: '$199.00 at launch', value: '199.00' },
                  { label: '$299.00 delayed', value: '299.00' },
                ]}
              />
            </div>
            <div className='flex justify-between items-center'>
              <span>Setup fee discount</span>
              <SingleInput
                value={feeInfo.setupFeeDiscount}
                iconBefore={<Dollar />}
                textAlign='right'
                isDigit={true}
                decimalPlaces={2}
                max={100}
                className='w-28 text-right'
                name='subscript_setupFee'
                placeholder='Input'
                onChange={(e: any) => {
                  const value = e.target.value
                  if (+value <= 100) {
                    setFeeInfo(prev => ({
                      ...prev,
                      setupFeeDiscount: value,
                    }))
                  }
                }}
              />
            </div>
            <div className='flex justify-between items-center my-4'>
              <span>Mention free website</span>
              <Select
                className='w-28'
                disabled
                value={feeInfo.isFreeWebsite}
                onChange={(value, item) => {
                  setFeeInfo(prev => ({ ...prev, isFreeWebsite: value }))
                }}
                options={[
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 0 },
                ]}
              />
            </div>
          </div>
        )}
        <OnlinePresence
          type='online'
          className='mb-6 mt-8'
          label='ESSENTIAL OFFERING'
          data={getOfferingTableData(feeInfo)}
        />
        {isAffiliate ? (
          <></>
        ) : (
          <OnlinePresence
            type='sales'
            className='mb-6'
            label='ENSSENTIAL SALES VOLUME'
            data={getSalesTableData(feeInfo)}
            footer={
              <>
                <span>Total volume</span>
                <span>{getSalesTotal(feeInfo)}</span>
              </>
            }
          />
        )}
      </Drawer>
      {/* 修改 SUBSCRIPTION modal */}
      <Modal
        title='SUBSCRIPTION FEE'
        open={showFeeModal}
        toggle={() => setShowFeeModal(false)}
      >
        <Label className='mb-2'>SUBSCRIPTION FEE AMOUNT</Label>
        <div className='flex'>
          <Button
            theme='outlined'
            color='secondary'
            iconBefore={<AngleLeft />}
            onClick={() => {
              const min = +MIN < +feeInfo.discount ? +feeInfo.discount : +MIN
              setFeeInfo(prev => ({ ...prev, subscription: min?.toFixed(2) }))
            }}
          >
            MIN
          </Button>
          <SingleInput
            value={feeInfo.subscription}
            iconBefore={<Dollar />}
            textAlign='right'
            min={+MIN}
            decimalPlaces={2}
            className='w-132 mx-4 text-right'
            name='subscript_subscription'
            placeholder='Input...'
            onBlur={(e: any) => {
              const value = +e.target.value
              const min = +MIN > +feeInfo.discount ? +MIN : +feeInfo.discount
              const num = value > min ? value : min
              setFeeInfo(prev => ({ ...prev, subscription: num?.toFixed(2) }))
            }}
            onChange={(e: any) => {
              const value = parseInt(e.target.value || 0)
              if (value <= Number(MAX)) {
                setFeeInfo(prev => ({
                  ...prev,
                  subscription: value + '',
                }))
              }
            }}
          />
          <Button
            theme='outlined'
            color='secondary'
            iconAfter={<AngleRight />}
            onClick={() =>
              setFeeInfo(prev => ({
                ...prev,
                subscription: Number(MAX)?.toFixed(2),
              }))
            }
          >
            MAX
          </Button>
        </div>
      </Modal>
    </div>
  )
}
