import { useRef, useState } from 'react'
import Modal from 'components/Modal'
import { useBlocker } from './useBlocker'

type Iprops = {
  when: boolean
}

export default function UnsavedPrompt({ when }: Iprops): JSX.Element {
  const [open, setOpen] = useState(false)
  const blockRef = useRef<any>(null)
  useBlocker(tx => {
    setOpen(true)
    blockRef.current = tx
  }, when)
  return (
    <Modal
      open={open}
      toggle={() => setOpen(false)}
      title='UNSAVED CHANGE'
      cancelText='EXIT WITHOUT SAVING'
      okText='GO BACK TO REVIEW'
      onCancel={() => blockRef.current?.retry()}
      onOk={() => setOpen(false)}
    >
      <p className='text-center text-light-700 text-sm'>
        You have unsaved change, exit without saving?
      </p>
    </Modal>
  )
}
