import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { components, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import useDebounce from 'hooks/useDebounce'
import {
  customStyles,
  customTheme,
  Control,
  IndicatorsContainer,
  AreaIndicatorsContainer,
  EmptyIndicatorsContainer,
  IOptions,
} from './help'
import Label from 'components/Label'
import { getAreaListApi } from 'redux/common'

export interface IFormAsyncSelectProps {
  className?: string
  options?: any
  defaultOptions?: any
  controlIcon?: string | React.ReactElement
  label?: string | React.ReactElement
  disabled?: boolean
  placeholder?: string
  value?: any | any[]
  defaultValue?: any | any[]
  indicators?: 'arrow' | 'mappin' | 'empty' // 指示器图标
  customOption?: (item: any, el: any) => React.ReactElement
  onChange: (item: any, rest: any) => void
  requestApi?: (inputValue: string) => Promise<IOptions>
}

const prefix = 'ru-form-search-select'

// 查询地址接口 自定义 options
const getAddressData = async (inputValue: string): Promise<any> => {
  const res = await getAreaListApi(inputValue)
  return res.map((item: string) => ({ label: item, value: item }))
}

// @ts-ignore
const Input = props => {
  return <components.Input {...props} id='search-select-input' />
}

const IndicatorMap = {
  arrow: IndicatorsContainer,
  mappin: AreaIndicatorsContainer,
  empty: EmptyIndicatorsContainer,
}

const FormSearchSelect = ({
  className,
  options = [],
  defaultOptions,
  label,
  disabled,
  placeholder = '',
  value = '',
  indicators = 'arrow',
  controlIcon,
  customOption,
  onChange,
  requestApi = getAddressData,
}: IFormAsyncSelectProps) => {
  const [asyncOptions, setAsyncOptions] = useState(options)
  const [inputValue, setInputValue] = useState('')
  const selectRef = useRef<any>()

  const customSearchOption = (props: OptionProps<IOptions>) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps
    const newProps = { ...props, innerProps: rest }
    const { data } = props
    let el = null
    if (data.optionChildren) {
      el = data.optionChildren
    } else {
      el = data.label || ''
      var reg = new RegExp(inputValue, 'gi')
      el = el.replace(reg, function (txt: any) {
        return `<span style='color:#00a5eb'>${txt}</span>`
      })
    }
    return (
      <components.Option {...newProps}>
        {customOption ? (
          customOption(data, el)
        ) : (
          <div
            className='pl-4'
            dangerouslySetInnerHTML={{ __html: el as string }}
          ></div>
        )}
      </components.Option>
    )
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const getOptions = (value: string, callback: any) => {
    requestApi(value)
      .then((res: any) => {
        callback(res)
        setAsyncOptions(res)
      })
      .catch(() => {
        callback([])
        setAsyncOptions([])
      })
  }

  const getOptionsDebounce = useDebounce(getOptions, 500)

  // @ts-ignore
  const handleChange = item => {
    setInputValue(item.label || '')
    onChange(item.label, item)
  }

  // @ts-ignore
  const handleInputChange = (e, { action }) => {
    if (action === 'input-change') {
      setInputValue(e)
    }
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: IOptions[]) => void,
  ) => {
    if (!inputValue) {
      return
    }
    getOptionsDebounce(inputValue, callback)
  }

  return (
    <div className={cn(className)}>
      {label && <Label className='mb-2'>{label}</Label>}
      <AsyncSelect
        className={cn(prefix)}
        // @ts-ignore
        ref={selectRef}
        // @ts-ignore
        icon={controlIcon}
        inputValue={inputValue}
        // menuIsOpen={true}
        isDisabled={disabled}
        blurInputOnSelect={true}
        cacheOptions={asyncOptions}
        defaultOptions={defaultOptions || asyncOptions}
        loadOptions={loadOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        hideSelectedOptions={false}
        styles={customStyles}
        theme={customTheme}
        components={{
          Input,
          Control,
          IndicatorsContainer: IndicatorMap[indicators],
          Option: customSearchOption,
        }}
      />
    </div>
  )
}
export default FormSearchSelect
