import React, { useState } from 'react'
import cn from 'classnames'
import moment from 'moment'
import { currentStepMap1, currentStepMap2 } from './helpers/constant'
import AccountStage from 'components/AccountStage'
import StageProgressTooltip from 'components/StageProgressTooltip'
import {
  RUSHABLE_MONTHLY_PLAN_ID,
  RUSHABLE_ANNUAL_PLAN_ID,
} from 'utils/constant'
import type { TStatus } from 'components/AccountStage'
import type { TOnboarding, TSignUpForm, TStageName } from 'types'

export type TStatusProcessProps = {
  className?: string
  statusName?: TStageName // 优先传入 statusName，若不传入则走内部判断逻辑
  onboarding: TOnboarding
  signupForm: TSignUpForm
  popTop?: boolean
  popLeft?: boolean
}

export default function StageProgress({
  className,
  statusName,
  onboarding,
  signupForm,
  popTop = true,
  popLeft = true,
}: TStatusProcessProps): JSX.Element | null {
  const [open, setOpen] = useState(false)
  if (!onboarding) {
    return null
  }
  const { menu_link, onboarding_menus, status } = onboarding
  const MenuSubmitted =
    menu_link ||
    (onboarding_menus && onboarding_menus.length && onboarding_menus.length > 0)
  const verifcationCheck =
    signupForm &&
    signupForm.crm_representative &&
    signupForm.crm_representative.phone_verified_at !== null
      ? true
      : false
  const { hello_process_id } = signupForm
  const currentStepMap =
    hello_process_id === 2 ? currentStepMap2 : currentStepMap1
  const current_step = signupForm ? signupForm.current_step ?? '' : ''
  let completeWelcomeCall: number | boolean = false
  let menuWorkassigned: number | boolean = false
  let menuWorkSentForApproval: number | boolean = false
  let menuWorkApproval: number | boolean = false
  let shippedOut: number | boolean = false
  let deliveredCheck: number | boolean = false
  let websiteConfiguration: number | boolean = false

  let gglUpdated: string | boolean = false
  let statusCheck: boolean = false
  let isCanceled: boolean = false

  if (onboarding) {
    let {
      complete_welcome_call,
      menu_faq,
      menu_work_sent_for_approval,
      menu_work_approval,
      shipped_out,
      delivered,
      website_configuration,
      google_listing_updated,
      status,
    } = onboarding
    isCanceled = status ? (status === 'canceled' ? true : false) : false
    statusCheck = status ? (status === 'done' ? true : false) : false
    completeWelcomeCall = (statusCheck || complete_welcome_call) ?? false
    menuWorkassigned = (statusCheck || menu_faq) ?? false
    menuWorkSentForApproval =
      (statusCheck || menu_work_sent_for_approval) ?? false
    menuWorkApproval = (statusCheck || menu_work_approval) ?? false
    shippedOut = (statusCheck || shipped_out) ?? false
    deliveredCheck = (statusCheck || delivered) ?? false
    websiteConfiguration = (statusCheck || website_configuration) ?? false

    gglUpdated = (statusCheck || google_listing_updated) ?? false
  }

  const signUpArr = [
    {
      checked: verifcationCheck,
      text: 'User account created',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 1,
      text: 'Location information confirmed',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 2,
      text: 'Payment processing acknowledged  ',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 3,
      text: 'Privacy and Terms display approved',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 4,
      text: 'Preference Q&A answered',
      checkedColor: '',
    },
    {
      checked: !!MenuSubmitted,
      text: 'Menu submitted',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 5,
      text: 'Subscription and billing confirmed',
      checkedColor: 'orange',
      // sub_text: currentStepMap[current_step] > 5 ? plan_name : '',
    },
    {
      checked:
        hello_process_id === 1
          ? currentStepMap[current_step] > 7
          : currentStepMap[current_step] > 6,
      text: 'Bank account for payout provided',
      checkedColor: '',
    },
    {
      checked:
        hello_process_id === 1
          ? currentStepMap[current_step] > 10
          : currentStepMap[current_step] > 9,
      text: 'Business verification completed',
      checkedColor: '',
    },
    {
      checked: currentStepMap[current_step] > 10,
      text: 'Signup completed',
      subText:
        Number(current_step) === 9
          ? 'Failed'
          : Number(current_step) === 10
          ? 'Verified'
          : Number(current_step) === 8
          ? 'Pending'
          : '',
      checkedColor: 'purple',
      subTextColor: Number(current_step) === 11 ? 'red' : '',
    },
  ]
  const onboardArr = onboarding?.onboarding_tasks?.length
    ? onboarding?.onboarding_tasks.map(tasks => {
        return {
          checked: !!tasks.completed_at,
          text: tasks.name,
          checkedColor: '',
        }
      })
    : [
        {
          checked: completeWelcomeCall,
          text: 'Obtain and request menu build',
          checkedColor: '',
        },
        {
          checked: menuWorkassigned,
          text: 'Review and share menu',
          checkedColor: '',
        },
        {
          checked: menuWorkSentForApproval,
          text: 'Menu build approved',
          checkedColor: '',
        },
        {
          checked: menuWorkApproval,
          text: 'Obtain the free domain',
          checkedColor: '',
        },
        {
          checked: shippedOut,
          text: 'Complete dashboard setting',
          checkedColor: '',
        },
        {
          checked: deliveredCheck,
          text: '👋 Welcome call',
          checkedColor: '',
        },
        {
          checked: websiteConfiguration,
          text: 'Automated campaign suggestion and setting',
          checkedColor: '',
        },
        {
          checked: gglUpdated,
          text: '🚀 Launch call ',
          checkedColor: '',
        },

        {
          checked: statusCheck || isCanceled,
          text: 'Verifiy GBP update',
          checkedColor: '',
        },
      ]

  const nowStatusAndStep = () => {
    const { location, rushable_plan_id } = signupForm
    const onboarding_done_at = onboarding?.done_at
    if (location && onboarding_done_at) {
      const { stripe_subscription_ends_at = '' } = location
      if (!stripe_subscription_ends_at) {
        return {
          status: 'live',
          step: 100,
        }
      }
      if (moment().isBefore(stripe_subscription_ends_at)) {
        let count = 0
        // 月付
        if (rushable_plan_id) {
          if (rushable_plan_id === Number(RUSHABLE_MONTHLY_PLAN_ID)) {
            const days =
              moment(stripe_subscription_ends_at).diff(moment(), 'days') + 1
            count = parseInt((((31 - days) * 100) / 31).toString())
          }
          // 年付
          if (rushable_plan_id === Number(RUSHABLE_ANNUAL_PLAN_ID)) {
            const days =
              moment(stripe_subscription_ends_at).diff(moment(), 'days') + 1
            count = parseInt((((366 - days) * 100) / 366).toString())
          }
          return {
            status: 'cancelling',
            step: count,
          }
        } else {
          const days =
            moment(stripe_subscription_ends_at).diff(moment(), 'days') + 1
          count = parseInt((((31 - days) * 100) / 31).toString())
          return {
            status: 'cancelling',
            step: count,
          }
        }
      }
      if (moment().isAfter(stripe_subscription_ends_at)) {
        return {
          status: 'canceled',
          step: 100,
        }
      }
    }
    if (status === 'draft') {
      return {
        status: 'draft',
        step: 100,
      }
    }
    if (status === 'signup_ready') {
      let count = 0
      signUpArr.forEach(item => {
        if (item.checked) {
          count += 1
        }
      })
      if (count === 0) {
        return {
          status: 'ready',
          step: 0,
        }
      } else {
        return {
          status: 'signing_up',
          step: (count * 100) / 10,
        }
      }
    }
    if (status === 'signup_done') {
      let count = 0
      onboardArr.forEach(item => {
        if (item.checked) {
          count += 1
        }
      })
      return {
        status: 'onboarding',
        step: (count * 100) / onboardArr.length,
      }
    }
    if (status === 'canceled') {
      return {
        status: 'canceled',
        step: 100,
      }
    }
    return {
      status: 'draft',
      step: 100,
    }
  }
  const { status: nowStatus, step } = nowStatusAndStep()
  return (
    <div
      className={cn('relative w-20', className)}
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
    >
      <AccountStage
        status={nowStatus as TStatus}
        step={step}
        statusName={statusName}
      />
      <StageProgressTooltip
        className={cn(
          'absolute z-50',
          popTop ? 'bottom-0' : 'top-0',
          popLeft ? 'right-20' : 'left-20',
        )}
        open={open}
        status={nowStatus as TStatus}
        signUpArr={signUpArr}
        onboardArr={onboardArr}
      />
    </div>
  )
}
