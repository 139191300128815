import DayHoursCollection from './DayHoursCollectionForm'
import { returnHoursInDays } from './help/index'
import { THoursInDay, TOpenHour } from 'types/openHour'

export { returnHoursInDays }

export type TBusinessHoursProp = {
  hoursInDay?: THoursInDay
  onChange: (hoursInDay: THoursInDay, day?: string) => void
  className?: string
  disabled?: boolean
  autoFill?: boolean
}

export default function BusinessHours({
  hoursInDay = returnHoursInDays([]),
  onChange,
  className,
  disabled,
  autoFill,
}: TBusinessHoursProp): JSX.Element {
  const onHourChange = (
    index: number,
    day: string,
    name: string,
    value: string,
  ) => {
    const dayHours = hoursInDay[day]
    if (dayHours.length) {
      dayHours[index] = {
        ...dayHours[index],
        [name]: value,
      }
    }
    const hours = { ...hoursInDay, [day]: dayHours }
    if (autoFill) {
      handleAutoFill(hours, day)
    } else {
      onChange(hours, day)
    }
  }
  // 条件满足时自动填充其他输入框
  const handleAutoFill = (hours: THoursInDay, day: string) => {
    const otherEmpty = Object.keys(hours).every((key: string) => {
      if (key !== day) {
        return !hours[key].length
      }
      return true
    })

    const autoFill =
      otherEmpty && hours[day].every((item: TOpenHour) => item.from && item.to)

    if (otherEmpty && autoFill) {
      Object.keys(hours).forEach((key: string) => {
        hours[key] = hours[day].map((item: TOpenHour) => ({
          day: key,
          from: item.from,
          to: item.to,
        }))
      })
    }
    onChange(hours, day)
  }

  const addHours = (day: string) => {
    const item = { day: day, from: '', to: '' }
    const dayHours = [...hoursInDay[day], item]
    onChange({ ...hoursInDay, [day]: dayHours })
  }

  const removeHours = (day: string, index: number) => {
    onChange({
      ...hoursInDay,
      [day]: hoursInDay[day].filter((_, i) => i !== index),
    })
  }

  return (
    <div className={className}>
      {Object.keys(hoursInDay).map(key => (
        <DayHoursCollection
          key={key}
          day={key}
          dailyHours={hoursInDay[key]}
          onHourChange={onHourChange}
          addHours={addHours}
          removeHours={removeHours}
          disabled={disabled}
        />
      ))}
    </div>
  )
}
