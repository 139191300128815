import { useState } from 'react'
import cn from 'classnames'
import { ActionMenu, Loading } from '@rushable/icons'
import IconButton from 'components/IconButton'
import ActionDropdown from 'components/ActionDropdown'
import Checkbox from 'components/Checkbox'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export type TOnboardingTaskItemProp = {
  disabled?: boolean
  openDropDown?: boolean
  name?: string
  id: any
  checked?: boolean
  loading?: boolean
  isTpl?: boolean
  onChecked: (e: boolean) => void
  onDelete: () => void
  onEdit: () => void
}

export default function OnboardingTaskItem({
  disabled,
  name,
  id,
  checked,
  loading,
  isTpl,
  onChecked,
  onDelete,
  onEdit,
}: TOnboardingTaskItemProp): JSX.Element {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div>
      <div
        ref={setNodeRef}
        {...listeners}
        style={styles}
        className={cn(
          'cursor-grab p-2 border border-solid border-dark-100 rounded-lg flex items-center',
        )}
      >
        <div className='relative '>
          <IconButton
            size='small'
            disabled={disabled}
            onClick={() => setOpen(!open)}
            icon={<ActionMenu size={16} />}
          />
          {!isTpl && (
            <ActionDropdown
              className='top-6'
              open={open}
              closeDropdown={() => setOpen(false)}
            >
              <div className='space-y-2 text-light-300 text-xs leading-14'>
                <div
                  className='cursor-pointer hover:text-light-700'
                  onClick={onEdit}
                >
                  Edit task
                </div>
                <div
                  className='cursor-pointer hover:text-light-700'
                  onClick={() => {
                    setDeleteLoading(true)
                    !deleteLoading && onDelete()
                  }}
                >
                  {deleteLoading ? <Loading size={12} /> : 'Delete task'}
                </div>
              </div>
            </ActionDropdown>
          )}
        </div>
        <div
          className={cn(
            'ml-1.5 mr-1 flex-1 text-sm',
            checked ? 'text-light-300 line-through' : 'text-light-700',
          )}
        >
          {name}
        </div>
        <Checkbox
          className='ml-auto'
          size={20}
          checked={checked}
          disabled={disabled}
          loading={loading}
          onChange={e => onChecked(e.target.checked)}
        />
      </div>
    </div>
  )
}
