import axiosInstance from 'utils/axios'

export type TAccountsParams = {
  brand_id: string
  location_id: string
  statuses: string[]
  types: string[]
  begin_time: string
  end_time: string
  page: number
}

export async function getOrders(params: TAccountsParams) {
  const response = await axiosInstance.request({
    url: 'orders',
    method: 'get',
    params: params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getBrands(keyword?: string) {
  const response = await axiosInstance.request({
    url: 'orders/brands',
    method: 'get',
    params: {
      keyword,
    },
  })
  return response.data
}
