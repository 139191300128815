export const typeOptions = [
  {
    label: 'All Type',
    value: '',
  },
  {
    label: 'Payment Blocked',
    value: 'payment',
  },
  {
    label: 'User Blocked',
    value: 'user',
  },
]

export type TBlocklist = {
  name: string
  type: string
}
