import cn from 'classnames'
import { LinedTrash, Plus } from '@rushable/icons'
import IconButton from 'components/IconButton'
import Button from 'components/Button'

export type TDayCloseProp = {
  tips?: string
  canDelete?: boolean
  addHours: () => void
  removeDays?: () => void
  disabled?: boolean
}
export default function DayClose({
  tips = 'CLOSED',
  canDelete = false,
  addHours,
  removeDays,
  disabled,
}: TDayCloseProp): JSX.Element {
  return (
    <div className='flex justify-center items-center h-full'>
      <div className='w-237 flex items-center justify-center text-light-300 text-sm'>
        {tips}
      </div>
      {!disabled && (
        <>
          <Button
            theme='text'
            color='primary'
            className='mx-6 text-blue font-bold'
            onClick={() => addHours()}
          >
            <div className='flex items-center'>
              <Plus size={12} className='mr-1' /> HOURS
            </div>
          </Button>

          <IconButton
            size='small'
            onClick={() => {
              if (canDelete && removeDays) {
                removeDays()
              }
            }}
            icon={<LinedTrash size={16} />}
          />
        </>
      )}
    </div>
  )
}
