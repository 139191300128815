import React from 'react'
import { SolidForbidden } from '@rushable/icons'
import ConditionsItemForm from './ConditionsItemForm'
import { FeatureOffOptions } from '../help/constant'
import type { TFilterItem } from '../help/constant'

export default function FeatureOffFilter({
  className,
  value,
  onChange,
}: TFilterItem) {
  return (
    <ConditionsItemForm
      className={className}
      icon={<SolidForbidden size={16} className='text-light-100' />}
      label='Feature off'
      value={value}
      options={FeatureOffOptions}
      onChange={onChange}
    />
  )
}
