import React, { useState } from 'react'

import { Select } from 'components/Select'

import {
  TFilterForm,
  statusOptions,
  detailStatusOptions,
  typeOptions,
} from '../helpers/constant'

export type TPayoutFilterProp = {
  filterForm: TFilterForm
  handleFormChange: (type: string, value: string) => void
  hideType?: boolean
}

export default function PayoutFilter({
  filterForm,
  hideType = false,
  handleFormChange,
}: TPayoutFilterProp): JSX.Element {
  const { status, type } = filterForm

  return (
    <div className='flex space-x-4'>
      <Select
        className='w-60'
        value={status}
        onChange={(value, item) => {
          handleFormChange('status', value)
        }}
        options={hideType ? detailStatusOptions : statusOptions}
      ></Select>
      {!hideType && (
        <Select
          className='w-60'
          value={type}
          onChange={(value, item) => {
            handleFormChange('type', value)
          }}
          options={typeOptions}
        ></Select>
      )}
    </div>
  )
}
