export type TFilterForm = {
  status: '' | 'new' | 'processed' | 'deposited' | 'refunded' | 'rejected'
  brand_name: string
  location_id: string
  types:
    | ''
    | 'pickup'
    | 'delivery'
    | 'daas_delivery'
    | 'curbside'
    | 'dine-in'
    | 'takeout'
  begin_time: string
  end_time: string
  page: number
}

export const statusOptions = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'Processed',
    value: 'processed',
  },
  {
    label: 'Deposited',
    value: 'deposited',
  },
  {
    label: 'Refunded',
    value: 'refunded',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
]

export const filterTypeOptions = [
  { label: 'All Types', value: '' },
  { value: 'pickup', label: 'Pickup' },
  { value: 'delivery', label: 'Delivery (in-house)' },
  { value: 'daas_delivery', label: 'Delivery (on-demand)' },
  { value: 'curbside', label: 'Curbside' },
  { value: 'dine-in', label: 'Dine-in' },
  { value: 'takeout', label: 'Takeout' },
]

export const typeOptions = [
  { value: 'pickup', label: 'Pickup' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'daas_delivery', label: 'Delivery' },
  { value: 'curbside', label: 'Curbside' },
  { value: 'dine-in', label: 'Dine-in' },
  { value: 'takeout', label: 'Takeout' },
]
