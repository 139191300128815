import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { LinedGlobe, LinedStar } from '@rushable/icons'
import { useParams } from 'react-router-dom'

import Label from 'components/Label'
import UserTable from '../../components/UserTable'
import PremiumAddOnTable from '../../components/PremiumAddOnTable'
import SetGbpInput from './SetGbpInput'
import SuccessCall from './SuccessCall'

// 下面两个和 onboarding 共用组件
import CheckList from '../OnboardingTab/CheckList'
import Communication from '../OnboardingTab/Communication'
import { getSuccessInfoApi } from 'redux/account'

export default function SuccessPage() {
  const urlParams = useParams()
  const [onboardingInfo, setOnboarding] = useState<Record<string, any>>({})

  useEffect(() => {
    getSuccessInfo()
  }, [])

  const getSuccessInfo = async () => {
    try {
      const res = await getSuccessInfoApi(urlParams.id!)
      setOnboarding(res)
    } catch (error: any) {
      toast.error(error.message)
    }
  }
  return (
    <div>
      <Label className='mb-2'>ACCOUNT USERS</Label>
      <UserTable
        className='py-2'
        items={onboardingInfo.users || []}
        hasCheck={false}
      />
      <div className='flex space-x-8 my-8'>
        <SetGbpInput
          type='website'
          icon={<LinedGlobe size={16} className='text-light-300' />}
          label='GBP WEBSITE TRACKING'
          value={
            onboardingInfo?.location_google_connection
              ?.proposed_gbp_website_link || ''
          }
          onSuccess={() => getSuccessInfo()}
        />
        <SetGbpInput
          type='ordering'
          icon={<LinedStar size={16} className='text-light-300' />}
          label='GBP ORDERING LINK TRACKING'
          value={
            onboardingInfo?.location_google_connection
              ?.proposed_gbp_ordering_link || ''
          }
          onSuccess={() => getSuccessInfo()}
        />
      </div>
      <PremiumAddOnTable
        title='SUBSCRIBED DEVICE'
        onboardingId={onboardingInfo.id}
        className='mb-8'
        onSuccess={getSuccessInfo}
        crm_closer={onboardingInfo.crm_closer || {}}
        items={onboardingInfo.onboarding_logistics || []}
      />
      <div className='mt-8 flex'>
        <div className='flex-1 mr-6'>
          <SuccessCall
            onboardingInfo={onboardingInfo}
            onSuccess={getSuccessInfo}
          />
          <CheckList
            tab='Success'
            onboardingId={onboardingInfo.id}
            onboardingInfo={onboardingInfo}
            onSuccess={getSuccessInfo}
          />
        </div>
        <div className='flex-1'>
          <Communication
            tab='Success'
            onboardingId={onboardingInfo.id}
            attention={onboardingInfo.attention}
            logs={onboardingInfo.onboarding_comments}
            onSuccess={getSuccessInfo}
          />
        </div>
      </div>
    </div>
  )
}
