import { ToastContainer, toast, Slide } from 'react-toastify'
import store from 'redux/store'
import { Provider } from 'react-redux'

import Routes from 'routes/index'

function App() {
  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        transition={Slide}
        limit={3}
      />
    </Provider>
  )
}

export default App
