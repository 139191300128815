import { useEffect, useState, useRef } from 'react'
import { AdminLayout } from 'components/Layout'
import MapPage from './MapPage'
import { toast } from 'react-toastify'
import { SearchSelect } from 'components/Select'
import Button from 'components/Button'
import { getTerritoryListApi } from 'redux/map'

export default function MapSearchPage() {
  const [curTerritory, setCurTerritory] = useState<Record<string, any>>({})
  const [territoryList, setTerritoryList] = useState<any[]>([])
  const addressRef = useRef<any>(null)

  useEffect(() => {
    getTerritoryList()
  }, [])

  const getTerritoryList = async () => {
    try {
      const res = await getTerritoryListApi()
      setTerritoryList(res)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handleSearch = (value: Record<string, any>) => {
    setCurTerritory(value)
  }

  const getSearchPage = () => {
    return (
      <div className='mt-88'>
        <div className='mb-8 flex justify-center space-x-4'>
          <SearchSelect
            placeholder='Input address'
            className='w-376'
            indicators='mappin'
            value={''}
            onChange={label => (addressRef.current = label)}
          />
          <Button onClick={() => handleSearch({ name: addressRef.current })}>
            SEARCH
          </Button>
        </div>
        <div className='w-512  m-auto'>
          <div className='flex flex-wrap justify-center ml-2'>
            {territoryList?.map((item, index) => {
              return (
                <div key={index} className='mb-2 mr-2'>
                  <Button
                    theme='outlined'
                    className='!min-w-0'
                    color='secondary'
                    onClick={() => handleSearch(item)}
                  >
                    {item.name}
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
  return (
    <AdminLayout>
      {/* 如果选中了，则展示详情页 */}
      {curTerritory.name ? (
        <MapPage territory={curTerritory} territoryList={territoryList} />
      ) : (
        getSearchPage()
      )}
    </AdminLayout>
  )
}
