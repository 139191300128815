import axiosInstance from 'utils/axios'

export async function getBlockList(params: any) {
  const response = await axiosInstance.request({
    url: 'blocklists',
    method: 'get',
    params,
    cache: { ignoreCache: true },
  })
  return response.data
}

export async function getUnblocked(blockId: string | number) {
  const response = await axiosInstance.request({
    url: `blocklists/${blockId}`,
    method: 'delete',
    cache: { ignoreCache: true },
  })
  return response.data
}
