/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { getProfile } from 'redux/auth'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getAccessToken } from 'utils/auth'

type TRequireAuthProps = {
  children: JSX.Element
}
export default function RequireAuth({
  children,
}: TRequireAuthProps): JSX.Element {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(state => state.auth)
  const hasToken = getAccessToken()
  useEffect(() => {
    if (hasToken && !profile?.id) {
      dispatch(getProfile())
    }
  }, [])

  if (!hasToken) {
    // navigate('/login', { state: { from: location } })
    window.location.replace('/login')
    return <></>
  }

  return children
}
