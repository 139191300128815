// new
export const currentStepMap2: any = {
  confirm_representative: 0,
  confirm_location: 1,
  confirm_work_with_stripe: 2,
  confirm_policy: 3,
  confirm_questions: 4,
  confirm_payment: 5,
  confirm_payout: 6,
  confirm_business: 7,
  business_pending_verification: 8,
  business_failed_verification: 9,
  confirm_welcome_call: 10,
  completed: 11,
}

// old
export const currentStepMap1: any = {
  confirm_representative: 0,
  confirm_location: 1,
  confirm_work_with_stripe: 2,
  confirm_policy: 3,
  confirm_questions: 4,
  confirm_payment: 5,
  confirm_welcome_call: 6,
  confirm_payout: 7,
  confirm_business: 8,
  business_pending_verification: 9,
  business_failed_verification: 10,
  completed: 11,
}
