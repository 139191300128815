import { createAction } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axios'

import { TFilterForm, TSalesItem } from 'pages/Sales/helpers/constant'

export const setPage = createAction<number>('sales/setPage')
export const setTotal = createAction<number>('sales/setTotal')
export const setPageSize = createAction<number>('sales/setPageSize')
export const setFilterForm = createAction<TFilterForm>('sales/setFilterForm')
export const setData = createAction<TSalesItem[]>('sales/setData')

export async function getSalesPerformances(params: {
  page: number
  model: string
  unit: string
  option: string
  sort_by: string
  sort: string
  ignoreCache?: boolean
}) {
  const response = await axiosInstance.request({
    url: 'sales_performances',
    method: 'get',
    params,
    cache: {
      ignoreCache: params.ignoreCache ? true : false,
    },
  })
  return response.data
}

export async function getSaleCrmTeams() {
  const response = await axiosInstance.request({
    url: 'sales_performances/option/crm_teams',
    method: 'get',
  })
  return response.data
}

type TDetailParams = {
  id: string
  unit: string
  option: string
  sort_by?: string
  sort?: string
  page?: number
  ignoreCache?: boolean
}

export async function getBonus({ id, unit, option }: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/bonus`,
    method: 'get',
    params: {
      unit,
      option,
    },
  })
  return response.data
}

export async function getNewSignups({
  id,
  unit,
  option,
  sort_by,
  sort,
  page,
  ignoreCache,
}: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/new_signups`,
    method: 'get',
    params: {
      unit,
      option,
      sort_by,
      sort,
      page,
    },
    cache: {
      ignoreCache: ignoreCache ? true : false,
    },
  })
  return response.data
}

export async function getSetup({
  id,
  unit,
  option,
  sort_by,
  sort,
  page,
  ignoreCache,
}: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/setup`,
    method: 'get',
    params: {
      unit,
      option,
      sort_by,
      sort,
      page,
    },
    cache: {
      ignoreCache: ignoreCache ? true : false,
    },
  })
  return response.data
}

export async function getCancellation({
  id,
  unit,
  option,
  sort_by,
  sort,
  page,
  ignoreCache,
}: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/cancellation`,
    method: 'get',
    params: {
      unit,
      option,
      sort_by,
      sort,
      page,
    },
    cache: {
      ignoreCache: ignoreCache ? true : false,
    },
  })
  return response.data
}

export async function getEffortPoints({
  id,
  unit,
  option,
  sort_by,
  sort,
  page,
  ignoreCache,
}: TDetailParams) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_users/${id}/crm_effort_points`,
    method: 'get',
    params: {
      unit,
      option,
      sort_by,
      sort,
      page,
    },
    cache: {
      ignoreCache: ignoreCache ? true : false,
    },
  })
  return response.data
}

export async function createOtherEffort(params: {
  crm_user_id: string
  description: string
  hours_spent: string
}) {
  const response = await axiosInstance.request({
    url: 'sales_performances/crm_effort_points',
    method: 'post',
    data: params,
  })
  return response.data
}

export async function deleteOtherEffort(crm_effort_point: number) {
  const response = await axiosInstance.request({
    url: `sales_performances/crm_effort_points/${crm_effort_point}`,
    method: 'delete',
  })
  return response.data
}
