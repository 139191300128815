import React from 'react'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import PersonName from 'components/PersonName'
import DateAndTime from 'components/DateAndTime'

import type { TCredit } from '../helpers/constant'

export type TCreditPageProps = {
  loading: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  credit: TCredit
  handleCreditChange: (newCredit: TCredit) => void
  onPageNoChange: (page: number) => void
  openCreditModal: (id: string) => void
}

export default function CreditPage({
  loading,
  total,
  current,
  pageSize,
  data,
  credit,
  handleCreditChange,
  onPageNoChange,
  openCreditModal,
}: TCreditPageProps) {
  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-104',
      custom: (value: string) => {
        let color: any = ''
        let status: string = ''
        switch (value) {
          case 'denied':
            color = 'red'
            status = 'Denied'
            break
          case 'pending':
            color = 'yellow'
            status = 'New'
            break
          case 'approved':
            color = 'green'
            status = 'Approved'
            break
        }
        return <BadgeText value={status} color={color} />
      },
    },

    {
      key: 'reason',
      name: 'REASON',
      className: 'w-120',
      custom: (value: string) => {
        return (
          <>
            {value === 'other' ? 'Other' : ''}
            {value === 'odd' ? 'ODD Error' : ''}
          </>
        )
      },
    },
    {
      key: 'amount',
      name: 'CREDIT',
      className: 'w-96',
      align: 'right',
      sortable: true,
      sortDirection: credit.name === 'credit' ? credit.type : '',
      custom: (value: string | number) => {
        return <div className='text-ellipsis'>${value}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleCreditChange({
          name: 'credit',
          type: value,
        })
      },
    },
    {
      key: 'order',
      name: 'ORDER',
      className: 'w-96',
      align: 'right',
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {value?.order_amount ? `$${value.order_amount}` : '-'}
          </div>
        )
      },
    },
    {
      key: 'order',
      name: 'ASSO. ORDER',
      className: 'w-160',
      custom: (value: any) => {
        return <div>{value?.id ? `#${value?.id}` : '-'}</div>
      },
    },
    {
      key: 'requested_by',
      name: 'REQUESTED BY',
      className: 'w-160',
      sortable: true,
      sortDirection: credit.name === 'requested_by' ? credit.type : '',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            {value ? (
              <PersonName
                className='w-full'
                avatar={value?.profile_photo}
                name={`${value?.first_name} ${value?.last_name}`}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleCreditChange({
          name: 'requested_by',
          type: value,
        })
      },
    },
    {
      key: 'approved_by',
      name: 'PROCESSED BY',
      className: 'w-160',
      sortable: true,
      sortDirection: credit.name === 'processed_by' ? credit.type : '',
      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            {value ? (
              <PersonName
                className='w-full'
                avatar={value?.avatar}
                name={`${value?.first_name} ${value?.last_name}`}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleCreditChange({
          name: 'processed_by',
          type: value,
        })
      },
    },
    {
      key: 'location',
      name: 'BRAND',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.brand?.name || '-'}</div>
      },
    },
    {
      key: 'location',
      name: 'LOCATION',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.name || '-'}</div>
      },
    },

    {
      key: 'order',
      name: 'ORDER DATE',
      align: 'right',
      sortable: true,
      className: 'w-32',
      sortDirection: credit.name === 'order_date' ? credit.type : '',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value?.created_at ? (
              <DateAndTime
                value={value.created_at}
                parse='YYYY-MM-DD'
                format='MMM DD, YYYY'
                noExpired={true}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleCreditChange({
          name: 'order_date',
          type: value,
        })
      },
    },
  ]

  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={row => openCreditModal(row.id)}
      />
    </div>
  )
}
