import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'

import {
  setPage,
  setTotal,
  setPageSize,
  setFilterForm,
  setData,
} from './action'

import {
  TFilterForm,
  TSalesItem,
  formatOption,
} from 'pages/Sales/helpers/constant'

type TState = {
  page: number
  total: number
  pageSize: number
  filterForm: TFilterForm
  data: TSalesItem[]
}

const initialState: TState = {
  page: 1,
  total: 0,
  pageSize: 20,
  filterForm: {
    model: 'stats_by_period',
    unit: 'monthly',
    option: formatOption('monthly', moment()),
    crm_team_id: '',
    sort_by: '',
    sort: '',
  },
  data: [],
}

export const salesReducer = createReducer(initialState, builder => {
  builder.addCase(setPage, (state, action) => {
    state.page = action.payload
  })
  builder.addCase(setTotal, (state, action) => {
    state.total = action.payload
  })
  builder.addCase(setPageSize, (state, action) => {
    state.pageSize = action.payload
  })
  builder.addCase(setFilterForm, (state, action) => {
    state.filterForm = action.payload
  })
  builder.addCase(setData, (state, action) => {
    state.data = action.payload
  })
})

export default salesReducer
