export type TFilterForm = {
  status: '' | 'new' | 'requested' | 'approved' | 'denied'
  brand_id: string
  location_id: string
  order_id: string
  page: number
  per_page: number
}

export const statusOptions = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'New',
    value: 'pending',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Denied',
    value: 'denied',
  },
]

export const creditTypeOptions = [
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'odd',
    label: 'ODD Error',
  },
]

export const assoOrderOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

export const orderItemOptions: any = {
  Subtotal: 'subtotal',
  'Custom Fee': 'custom_fee',
  'Sales Tax': 'tax',
  'Convenience Fee': 'service_fee',
  'Courier Fee (restaurant)': 'courier_fee_restaurant',
  'Courier Fee (customer)': 'courier_fee_customer',
  'Courier Tip': 'courier_tip',
  'Delivery Fee': 'delivery_fee',
  'Restaurant Tip': 'tip',
  'Dispatch Fee': 'dispatch_fee',
}

export type TCredit = {
  name: string
  type: string
}
