import React, { useState, useEffect } from 'react'

import cn from 'classnames'
import Table from 'components/Table'
import Checkbox from 'components/Checkbox'
import CopyableText from 'components/CopyableText'
import PersonName from 'components/PersonName'
import StageProgress from 'components/StageProgress'
import moment from 'moment'
import { currencyFormatter } from 'utils/digit'

import type { TReferral } from '../helpers/constant'

type TRowProp = {
  [index: string]: any
}

export type TReferralTableProp = {
  className?: string
  data: Array<TRowProp>
  total: number
  loading?: boolean
  current: number
  pageSize: number
  sentStatus: boolean
  referral: TReferral
  handleReferralChange: (newReferral: TReferral) => void
  onPageNoChange: (page: number) => void
  changeStatus: (value: boolean, row: any) => void
}

export default function ReferralTable({
  className = '',
  loading,
  data,
  total,
  current,
  pageSize,
  referral,
  sentStatus,
  handleReferralChange,
  onPageNoChange,
  changeStatus,
}: TReferralTableProp): JSX.Element {
  const columns = [
    {
      key: 'referral_info',
      name: 'SENT',
      custom: (value: any, row: any) => {
        return (
          <Checkbox
            disabled={sentStatus}
            checked={value?.status === 'sent'}
            onChange={e => changeStatus(e.target.checked, row)}
          />
        )
      },
    },
    {
      key: 'referral_info',
      name: 'AMOUNT',
      className: 'w-110',
      sortable: true,
      align: 'right',
      sortDirection: referral.name === 'amount' ? referral.type : '',
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {value?.bonus ? currencyFormatter(value.bonus) : '-'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleReferralChange({
          name: 'amount',
          type: value,
        })
      },
    },
    {
      key: 'referrer',
      name: 'REFERRER',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.name || '-'}</div>
      },
    },
    {
      key: 'referrer',
      name: 'REFERRER EMAIL',
      className: 'w-288',
      custom: (value: any) => {
        return <CopyableText text={value?.email || '-'} />
      },
    },
    {
      key: 'brand_name',
      name: 'BRAND',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value || '-'}</div>
      },
    },
    {
      key: 'location_name',
      name: 'LOCATION',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value || '-'}</div>
      },
    },
    {
      key: 'account',
      name: 'CLOSER',
      custom: (value: any) => {
        const { crm_closer } = value?.onboarding
        const closerName = crm_closer
          ? `${crm_closer?.first_name} ${crm_closer?.last_name}`
          : '-'
        const closerAvatar = crm_closer?.profile_photo

        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={closerAvatar}
              name={closerName}
            />
          </div>
        )
      },
    },
    {
      key: 'stage_days',
      name: 'STAGE DAYS',
      className: 'w-28',
      align: 'right',
      sortable: true,
      sortDirection: referral.name === 'stage_days' ? referral.type : '',
      custom: (value: any, row: any) => {
        return <div>{value ? `${value} days` : '-'}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleReferralChange({
          name: 'stage_days',
          type: value,
        })
      },
    },
    {
      key: 'stage',
      name: 'STAGE',
      className: 'w-28',
      tdNoHidden: true,
      sortable: true,
      align: 'right',
      sortDirection: referral.name === 'stage' ? referral.type : '',
      custom: (value: any, item: any, index: number) => {
        return (
          <StageProgress
            className='cursor-pointer'
            popTop={index >= 8}
            onboarding={item?.account?.onboarding}
            signupForm={item?.account?.signup_form}
            statusName={item.stage}
          />
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleReferralChange({
          name: 'stage',
          type: value,
        })
      },
    },
  ]

  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
      />
    </div>
  )
}
