import React, { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AdminLayout, AdminPageTitle } from 'components/Layout'
import Tabs from 'components/Tabs'

import BonusCalculationSideBar from './components/BonusCalculationSideBar'
import BonusCalculationTable from './components/BonusCalculationTable'
import EffortPointsCalSideBar from './components/EffortPointsCalSideBar'
import EffortPointsCalTable from './components/EffortPointsCalTable'

import {
  getBonus,
  getNewSignups,
  getSetup,
  getCancellation,
  getEffortPoints,
  createOtherEffort,
  deleteOtherEffort,
} from 'redux/sales'

import type { TBonus, TRowProp, TCount, TNowParams } from './helpers/constant'
import moment from 'moment'

export type TSalesDetailProp = {
  className?: string
}

export default function SalesDetail({
  className = '',
}: TSalesDetailProp): JSX.Element {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const unit = searchParams.get('unit')
  const option = searchParams.get('option')
  const [closer, setCloser] = useState({
    first_name: '',
    last_name: '',
  })
  const [bonus, setBonus] = useState<TBonus>({
    adjustment_amount: '',
    cancellation_quantity: '',
    closer_id: 0,
    self_gen_bonus: '',
    self_gen_quantity: '',
    setup_fee_amount: '',
    setup_fee_bonus: '',
    total_bonus: '',
    volume_amount: '',
    volume_bonus: '',
  })
  const [count, setCount] = useState<TCount[]>([])

  const [newSignupsData, setNewSignupsData] = useState<Array<TRowProp>>([])
  const [accountLaunchedData, setAccountLaunchedData] = useState<
    Array<TRowProp>
  >([])
  const [cancellationData, setCancellationData] = useState<Array<TRowProp>>([])
  const [effortPointsData, setEffortPointsData] = useState<Array<TRowProp>>([])
  const [pointTotal, setPointTotal] = useState(0)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [sortData, setSortData] = useState({
    sort_by: '',
    sort: '',
  })
  const [loading, setLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState<{
    show: boolean
    id: null | number
  }>({
    show: false,
    id: null,
  })

  const [tabValue, setTabValue] = useState('1')
  const [open, setOpen] = useState(false)

  const getBonusData = async () => {
    try {
      const res = await getBonus({ id: id!, unit: unit!, option: option! })
      if (res) {
        if (res.closer && res.closer.user) {
          setCloser({
            first_name: res.closer.user.first_name,
            last_name: res.closer.user.last_name,
          })
        }
        if (res.bonus) {
          setBonus(res.bonus)
        }
      }
    } catch (err: any) {
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }

  const getNewSignupsData = async ({
    nowPage,
    nowSort,
    ignoreCache,
  }: TNowParams) => {
    try {
      setPage(nowPage)
      setLoading(true)
      if (nowSort) {
        setSortData(nowSort)
      }
      const res = await getNewSignups({
        id: id!,
        unit: unit!,
        option: option!,
        sort_by: sortData.sort_by,
        sort: sortData.sort,
        page: nowPage,
        ...nowSort,
        ignoreCache,
      })
      setLoading(false)
      if (res) {
        setTotal(res.total)
        setPageSize(res.per_page)
        setNewSignupsData(
          res.data.map((item: any, index: number) => {
            return {
              ...item,
              index,
            }
          }),
        )
      }
    } catch (err: any) {
      setLoading(false)
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }

  const getSetupData = async ({
    nowPage,
    nowSort,
    ignoreCache,
  }: TNowParams) => {
    try {
      setPage(nowPage)
      setLoading(true)
      if (nowSort) {
        setSortData(nowSort)
      }
      const res = await getSetup({
        id: id!,
        unit: unit!,
        option: option!,
        sort_by: sortData.sort_by,
        sort: sortData.sort,
        page: nowPage,
        ...nowSort,
        ignoreCache,
      })
      setLoading(false)
      if (res) {
        setTotal(res.total)
        setPageSize(res.per_page)
        setAccountLaunchedData(
          res.data.map((item: any, index: number) => {
            return {
              ...item,
              index,
            }
          }),
        )
      }
    } catch (err: any) {
      setLoading(false)
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }
  const getCancellationData = async ({
    nowPage,
    nowSort,
    ignoreCache,
  }: TNowParams) => {
    try {
      setPage(nowPage)
      setLoading(true)
      if (nowSort) {
        setSortData(nowSort)
      }
      const res = await getCancellation({
        id: id!,
        unit: unit!,
        option: option!,
        sort_by: sortData.sort_by,
        sort: sortData.sort,
        page: nowPage,
        ...nowSort,
        ignoreCache,
      })
      setLoading(false)
      if (res) {
        setTotal(res.total)
        setPageSize(res.per_page)
        setCancellationData(
          res.data.map((item: any, index: number) => {
            return {
              ...item,
              index,
            }
          }),
        )
      }
    } catch (err: any) {
      setLoading(false)
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }
  const getEffortPointsData = async ({
    nowPage,
    nowSort,
    ignoreCache,
  }: TNowParams) => {
    try {
      setPage(nowPage)
      setLoading(true)
      if (nowSort) {
        setSortData(nowSort)
      }
      const res = await getEffortPoints({
        id: id!,
        unit: unit!,
        option: option!,
        sort_by: sortData.sort_by,
        sort: sortData.sort,
        page: nowPage,
        ...nowSort,
        ignoreCache,
      })
      setLoading(false)
      if (res) {
        if (res.detail) {
          setTotal(res.detail.total)
          setPageSize(res.detail.per_page)
          setEffortPointsData(res.detail.data)
        }
        if (res.count) {
          setCount(res.count)
        }
        if (res.point_total) {
          setPointTotal(res.point_total)
        }
      }
    } catch (err: any) {
      setLoading(false)
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }

  const handleGetList = (params: TNowParams) => {
    switch (tabValue) {
      case '1':
        getNewSignupsData(params)
        break
      case '2':
        getSetupData(params)
        break
      case '3':
        getCancellationData(params)
        break
      case '4':
        getEffortPointsData(params)
        break
    }
  }

  const handleAddOther = async (description: string, hoursSpent: string) => {
    try {
      setAddLoading(true)
      const res = await createOtherEffort({
        crm_user_id: id!,
        description,
        hours_spent: hoursSpent,
      })
      setAddLoading(false)
      if (res) {
        setOpen(false)
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: '',
            sort: '',
          },
          ignoreCache: true,
        })
      }
    } catch (err: any) {
      setAddLoading(false)
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }

  const handleDeleteOther = async (itemId: number) => {
    try {
      setDeleteLoading({
        show: true,
        id: itemId,
      })
      const res = await deleteOtherEffort(itemId)
      setDeleteLoading({
        show: false,
        id: null,
      })
      if (res) {
        setOpen(false)
        handleGetList({
          nowPage: 1,
          nowSort: {
            sort_by: '',
            sort: '',
          },
          ignoreCache: true,
        })
      }
    } catch (err: any) {
      setDeleteLoading({
        show: false,
        id: null,
      })
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }

  useEffect(() => {
    if (id && unit && option) {
      getBonusData()
    }
  }, [])

  useEffect(() => {
    if (id && unit && option) {
      handleGetList({
        nowPage: 1,
        nowSort: {
          sort_by: '',
          sort: '',
        },
      })
    }
  }, [tabValue])

  return (
    <AdminLayout showNavs={false}>
      <AdminPageTitle
        title={`${closer.first_name} ${closer.last_name} • ${moment(
          option,
          'YYYY-MM',
        ).format('MMM YYYY')}`}
      />
      <div className='flex'>
        {tabValue !== '4' ? (
          <BonusCalculationSideBar bonus={bonus} />
        ) : (
          <EffortPointsCalSideBar
            loading={addLoading}
            pointTotal={pointTotal}
            count={count}
            open={open}
            toggle={() => setOpen(!open)}
            handleAddOther={handleAddOther}
          />
        )}
        <div className='flex-1 mt-4 mb-10 px-10'>
          <Tabs activeKey={tabValue} isCache={true} onChange={setTabValue}>
            <Tabs.Panel tabKey='1' tab='New Signups'>
              <div className='flex flex-col'>
                <BonusCalculationTable
                  tabKey='1'
                  page={page}
                  data={newSignupsData}
                  total={total}
                  pageSize={pageSize}
                  loading={loading}
                  sortData={sortData}
                  handleGetList={handleGetList}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel tabKey='2' tab='Setup Fee'>
              <div className='flex flex-col'>
                <BonusCalculationTable
                  tabKey='2'
                  page={page}
                  data={accountLaunchedData}
                  total={total}
                  pageSize={pageSize}
                  loading={loading}
                  sortData={sortData}
                  handleGetList={handleGetList}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel tabKey='3' tab='Early Cancellation'>
              <div className='flex flex-col'>
                <BonusCalculationTable
                  tabKey='3'
                  page={page}
                  data={cancellationData}
                  total={total}
                  pageSize={pageSize}
                  loading={loading}
                  sortData={sortData}
                  handleGetList={handleGetList}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel tabKey='4' tab='Effort Points'>
              <EffortPointsCalTable
                page={page}
                data={effortPointsData}
                total={total}
                pageSize={pageSize}
                loading={loading}
                deleteLoading={deleteLoading}
                sortData={sortData}
                handleGetList={handleGetList}
                handleDeleteOther={handleDeleteOther}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </AdminLayout>
  )
}
