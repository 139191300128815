import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import PartTable from './components/PartTable'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getAffiliateTeam, setPartFilterForm } from 'redux/affiliate'

export default function AffiliatePage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { partData } = useAppSelector(state => state.affiliate)
  const profile = useAppSelector(s => s.auth.profile)
  const authMap = useAppSelector(s => s.auth.authMap)
  const [loading, setLoading] = useState(false)

  const handleFilterChange = (type: string, value: any) => {
    let newForm = { ...partData.filterForm }
    if (type === 'sort') {
      newForm = {
        ...partData.filterForm,
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
    } else {
      newForm = {
        ...partData.filterForm,
        [type]: value,
      }
    }
    getTable({ ...newForm, page: 1 })
  }

  const onPageNoChange = (page: number) => {
    getTable({ ...partData.filterForm, page })
  }

  const getTable = async (form?: any) => {
    // 请求 api
    setLoading(true)
    try {
      const res = await getAffiliateTeam(form)
      dispatch(
        setPartFilterForm({
          total: res.total,
          data: res.data,
          filterForm: form,
        }),
      )
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (authMap?.type === 'affiliate') {
      navigate(`/affiliate/${profile?.crm_user?.crm_team_id}`)
    } else {
      getTable(partData.filterForm)
    }
  }, [authMap])

  return (
    <AdminLayout>
      <div className='px-10 py-8'>
        <PartTable
          loading={loading}
          total={partData.total}
          current={partData.filterForm.page}
          pageSize={partData.filterForm.per_page}
          data={partData.data}
          onPageNoChange={onPageNoChange}
          handleFilterChange={handleFilterChange}
          clickedRow={value => {
            navigate(`/affiliate/${value}`)
          }}
        />
      </div>
    </AdminLayout>
  )
}
