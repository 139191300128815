import React from 'react'

import cn from 'classnames'

export type TFieldErrorProp = {
  children?: React.ReactNode
  className?: string
}

export default function FieldError({
  children,
  className = '',
}: TFieldErrorProp): JSX.Element {
  return (
    <p className={cn('ru-field-error w-full text-red text-xs', className)}>
      {children}
    </p>
  )
}
