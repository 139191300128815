import React from 'react'
import cn from 'classnames'
import { LinedGlobe, LinedStar } from '@rushable/icons'

export type TGBPTrackingProps = {
  className?: string
  orderingLink: boolean
  websiteLink: boolean
}

export default function GBPTracking({
  className,
  websiteLink,
  orderingLink,
}: TGBPTrackingProps): JSX.Element {
  return (
    <div className={cn('ru-GBPTracking w-full flex  items-center', className)}>
      <LinedGlobe
        size={20}
        className={cn(websiteLink ? 'text-yellow' : 'text-dark-100')}
      />
      <LinedStar
        size={20}
        className={cn('ml-2', orderingLink ? 'text-yellow' : 'text-dark-100')}
      />
    </div>
  )
}
