import { createAction } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axios'
import { TFilterForm } from 'pages/Payout/helpers/constant'

export const setData = createAction<any>('payouts/setData')
export const setTotal = createAction<number>('payouts/setTotal')
export const setFilterForm = createAction<TFilterForm>('payouts/setFilterForm')

export async function getPayoutDetail(params: any) {
  const response = await axiosInstance.request({
    url: 'payouts',
    method: 'get',
    params,
    cache: { ignoreCache: false },
  })
  return response.data
}

export async function getPayouts(params: any) {
  const response = await axiosInstance.request({
    url: 'payouts/summary',
    method: 'get',
    params,
    cache: { ignoreCache: false },
  })
  return response.data
}

export async function payoutRetry(params: any) {
  const response = await axiosInstance.request({
    url: `payouts/${params.id}`,
    method: 'put',
    cache: { ignoreCache: true },
  })
  return response.data
}
