import { useState } from 'react'
import { AngleRight, Loading } from '@rushable/icons'
import Modal from 'components/Modal'
import Button from 'components/Button'

import { createBlocklist, getStripeDisputes } from 'redux/dispute'
import { getAccess } from 'redux/common'
import { toast } from 'react-toastify'

const ButtonIcon = (loading: boolean) => {
  return loading ? (
    <Loading size={16} className='text-light-300' />
  ) : (
    <AngleRight size={16} className='text-light-300' />
  )
}

export type TDisputeHandlingModalProps = {
  open: boolean
  item: any
  toggle: () => void
  getTable: () => void
}

export default function DisputeHandlingModal({
  open,
  item,
  toggle,
  getTable,
}: TDisputeHandlingModalProps) {
  const [dashboardLoading, setDashboardLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [customerLoading, setCustomerLoading] = useState(false)
  const paymentDisabled = item?.order?.payment_is_blocked === 1
  const customerDisabled = item?.order?.customer?.status !== 'active'
  const handleClick = async (key: number) => {
    if (key === 1) {
      setDashboardLoading(true)
      try {
        const res = await getAccess({ order_id: item?.order_id })
        setDashboardLoading(false)
        if (res && res.message === 'Success') {
          window.open(res.redirect)
        } else {
          setDashboardLoading(false)
          toast.error('Access Error!')
        }
      } catch (err: any) {
        setDashboardLoading(false)
        if (err.message) {
          toast.error(err.message)
        }
      }
    }
    if (key === 2) {
      setStripeLoading(true)
      try {
        const res = await getStripeDisputes(item.id)
        setStripeLoading(false)
        if (res?.stripe_dispute_link) {
          window.open(res.stripe_dispute_link)
        }
      } catch (err: any) {
        setStripeLoading(false)
        if (err.message) {
          toast.error(err.message)
        }
      }
    }
    if (key === 3 || key === 4) {
      key === 3 ? setPaymentLoading(true) : setCustomerLoading(true)
      try {
        const res = await createBlocklist({
          type: key === 3 ? 'payment' : 'user',
          order_id: item?.order_id || '',
          customer_id: item?.order?.customer_id || '',
          reason: item?.reason || '',
        })
        if (res) {
          key === 3 ? setPaymentLoading(false) : setCustomerLoading(false)
          if (res.message) {
            toast.success(res.message)
            getTable()
            toggle()
          }
        }
      } catch (err: any) {
        key === 3 ? setPaymentLoading(false) : setCustomerLoading(false)
        if (err.message) {
          toast.error(err.message)
        }
      }
    }
  }
  return (
    <Modal
      open={open}
      toggle={toggle}
      title='DISPUTE HANDLING'
      okText='UPDATE'
      cancelText='CLOSE'
      footer={
        <div className='flex w-full justify-end'>
          <Button theme='contained' color='secondary' onClick={toggle}>
            CLOSE
          </Button>
        </div>
      }
    >
      <div className='flex flex-col space-y-6'>
        <Button
          theme='outlined'
          color='secondary'
          className='w-full'
          onClick={() => handleClick(1)}
        >
          <div className='py-1.5 w-[444px] flex justify-between'>
            <div className='text-light-700 text-base'>
              🧾 See order detail in dashboard
            </div>
            {ButtonIcon(dashboardLoading)}
          </div>
        </Button>
        <Button
          theme='outlined'
          color='secondary'
          className='w-full'
          onClick={() => handleClick(2)}
        >
          <div className='py-1.5 w-[444px] flex justify-between'>
            <div className='text-light-700 text-base'>
              🗃️ See dispute detail on Stripe
            </div>
            {ButtonIcon(stripeLoading)}
          </div>
        </Button>
        {!paymentDisabled && (
          <Button
            theme='outlined'
            color='secondary'
            className='w-full'
            onClick={() => handleClick(3)}
          >
            <div className='py-1.5 w-[444px] flex justify-between'>
              <div className='text-light-700 text-base'>
                💳 Block this payment method
              </div>
              {ButtonIcon(paymentLoading)}
            </div>
          </Button>
        )}
        {!customerDisabled && (
          <Button
            theme='outlined'
            color='secondary'
            className='w-full'
            onClick={() => handleClick(4)}
          >
            <div className='py-1.5 w-[444px] flex justify-between'>
              <div className='text-light-700 text-base'>
                ☠️ Block this customer account
              </div>
              {ButtonIcon(customerLoading)}
            </div>
          </Button>
        )}
      </div>
    </Modal>
  )
}
