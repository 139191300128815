import cn from 'classnames'
import moment from 'moment-timezone'

export type TDateAndTimeProp = {
  className?: string
  value: string | moment.Moment | undefined | null
  parse?: string
  format?: string
  timezone?: string
  noTransformTz?: boolean // 不根据时区自动转换时间，只为了显示 ET CT 等标记
  noExpired?: boolean
  diffThirty?: boolean
}

export default function DateAndTime({
  value,
  parse,
  format,
  timezone,
  noTransformTz = false,
  className = '',
  noExpired = false,
  diffThirty = false,
}: TDateAndTimeProp) {
  const timeValue =
    timezone && !noTransformTz
      ? moment(value, parse).tz(timezone).format(format)
      : moment(value, parse).format(format)

  const getTextColor = () => {
    if (noExpired) {
      return 'text-light-500'
    }
    // 过期前 30 天内
    if (diffThirty) {
      const isAfter = moment(value).isAfter(moment(), 'day')
      const months = moment(value).diff(moment(), 'months')
      if (isAfter && months < 1) {
        return 'text-yellow'
      }
    }

    // today
    if (moment(value).isSame(moment(), 'day')) {
      return 'text-yellow'
    }
    // pasttime
    if (moment(value).isBefore(moment(), 'day')) {
      return 'text-red'
    }
    // future
    return 'text-light-500'
  }

  if (!value) {
    return null
  }
  return (
    <div className={cn('text-sm flex items-center', getTextColor(), className)}>
      {timezone ? (
        <>
          <span className='mr-2'>{timeValue}</span>
          <div className='text-light-500 font-medium border-2 border-solid border-dark-100 rounded-lg py-1.5 px-2.5 text-xxs-z'>
            {moment
              .tz(timezone)
              .format('z')
              .replace(/(.{1})./g, '$1')}
          </div>
        </>
      ) : (
        <>{timeValue}</>
      )}
    </div>
  )
}
