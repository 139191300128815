import cn from 'classnames'
import moment from 'moment'

import {
  Visa,
  Amex,
  Master,
  Discover,
  GooglePay,
  ApplePay,
  Other,
} from '@rushable/icons'

export type TPayment =
  | 'visa'
  | 'amex'
  | 'mastercard'
  | 'discover'
  | 'googlePay'
  | 'applePay'
  | 'unknown'

export type TLabelProp = {
  className?: string
  brand: TPayment
  last4?: string | number
  expireDate?: string
}

const PAYMENTMAP = {
  visa: <Visa size={20} />,
  amex: <Amex size={20} />,
  mastercard: <Master size={20} />,
  discover: <Discover size={20} />,
  googlePay: <GooglePay size={20} />,
  applePay: <ApplePay size={20} />,
  unknown: <Other size={20} />,
}

export default function PaymentInfo({
  className = '',
  brand,
  last4,
  expireDate,
}: TLabelProp): JSX.Element {
  // default：expireDate + 1m > current
  let expClass = 'text-light-100'
  if (expireDate) {
    // expireDate < current
    if (moment(expireDate).isBefore()) {
      expClass = 'text-red'
    } else {
      const month = moment().get('month')
      const date = moment().set('month', month + 1)
      // expireDate + 1m < current
      if (moment(expireDate).isBefore(date)) {
        expClass = 'text-yellow'
      }
    }
  }
  return (
    <div className={cn('flex item-center text-sm text-light-500', className)}>
      {PAYMENTMAP[brand]}
      <div className='ml-2 mr-1'>
        {last4 ? <span>{`•••• ${last4}`}</span> : <span>Empty</span>}
      </div>
      {expireDate && (
        <div className={cn('text-xxs-z flex items-center', expClass)}>
          EXP {moment(expireDate).format('MM/YYYY')}
        </div>
      )}
    </div>
  )
}
