import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { getAccessToken, removeAccessToken } from 'utils/auth'

export default function setupInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config?.headers) {
        throw new Error(
          "Expected 'config' and 'config.headers' not to be undefined",
        )
      }
      if (!config?.url?.includes('/login')) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`
      }
      return config
    },
    error => {
      // 对请求错误做些什么
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const status = error.status || error.response?.status || 0
      if (status === 401) {
        toast.error('Please login')
        removeAccessToken()
        window.location.replace('/login')
        return
      }
      const errData = error.response?.data || {
        message: error.message,
        errors: {},
      }
      return Promise.reject(errData)
    },
  )
}
