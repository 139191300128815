import React from 'react'

import cn from 'classnames'
import { Select } from 'components/Select'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import { LinedTrash, Plus } from '@rushable/icons'
import { TOpenHour } from 'types/openHour'
import { OPEN_HOURS_OPTIONS } from 'utils/hoursConstant'

export type TDayHoursFieldProp = {
  index: number
  dayHours: TOpenHour
  onChange: (name: string, value: string) => void
  addHours: () => void
  removeHours: () => void
  disabled?: boolean
}

export default function DayHoursField({
  index,
  dayHours,
  onChange,
  addHours,
  removeHours,
  disabled,
}: TDayHoursFieldProp): JSX.Element {
  const { from, to } = dayHours
  return (
    <div
      className={cn(
        'flex justify-center items-center',
        index > 0 ? 'mt-4' : '',
      )}
    >
      <div className='w-237 flex justify-center items-center space-x-4'>
        <Select
          className='text-right'
          value={from || ''}
          onChange={value => onChange('from', value)}
          options={OPEN_HOURS_OPTIONS}
          hideIndicator
          placeholder='Select'
          disabled={disabled}
        ></Select>
        <span className='text-light-300 text-sm'>to</span>
        <Select
          className='text-right'
          value={to || ''}
          onChange={value => onChange('to', value)}
          options={OPEN_HOURS_OPTIONS}
          hideIndicator
          placeholder='Select'
          disabled={disabled}
        ></Select>
      </div>
      {!disabled && (
        <>
          <Button
            theme='text'
            color='primary'
            className='mx-6 text-blue font-bold'
            onClick={() => addHours()}
          >
            <div className='flex items-center'>
              <Plus size={12} className='mr-1' /> HOURS
            </div>
          </Button>

          <IconButton
            size='small'
            onClick={() => removeHours()}
            icon={<LinedTrash size={16} />}
          />
        </>
      )}
    </div>
  )
}
