import { useState, useEffect } from 'react'

import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import PersonName from 'components/PersonName'
import { currencyFormatter } from 'utils/digit'
import { useAppSelector } from 'redux/hooks'

type TPartTableProps = {
  loading: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  onPageNoChange: (page: number) => void
  handleFilterChange: (type: string, value: any) => void
  clickedRow: (id: number) => void
}

export default function AccountTable({
  loading,
  total,
  current,
  pageSize,
  data,
  onPageNoChange,
  handleFilterChange,
  clickedRow,
}: TPartTableProps): JSX.Element {
  const { partData } = useAppSelector(state => state.affiliate)

  const { sort_by, sort } = partData.filterForm

  const columns = [
    {
      key: 'active',
      name: 'STATUS',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'status' ? sort : '',
      custom: (value: any) => {
        return (
          <BadgeText
            value={!!value ? 'Active' : 'Disqualified'}
            color={!!value ? 'green' : 'red'}
          />
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'status',
          value,
        })
      },
    },
    {
      key: 'name',
      name: 'AFFILIATE',
    },
    {
      key: 'admin_user',
      name: 'ADMIN',
      custom: (value: any) => {
        return (
          <div className='text-ellipsis'>
            {value ? `${value?.first_name} ${value?.last_name}` : '-'}
          </div>
        )
      },
    },
    {
      key: 'started_at',
      name: 'STARTED',
      className: 'w-120',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'started' ? sort : '',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                className='text-right text-light-500'
                value={value}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'started',
          value,
        })
      },
    },
    {
      key: 'month',
      name: 'MONTH',
      className: 'w-120',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'month' ? sort : '',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? `${value} months` : '-'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'month',
          value,
        })
      },
    },
    {
      key: 'signup_count',
      name: 'SIGNUPS',
      className: 'w-24',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'signups' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'signups',
          value,
        })
      },
    },
    {
      key: 'live_count',
      name: 'LIVE',
      className: 'w-24',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'live' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'live',
          value,
        })
      },
    },
    {
      key: 'average',
      name: 'AVERAGE',
      className: 'w-24',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'average' ? sort : '',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? `${Number(value).toFixed(1)}/mo` : '-'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'average',
          value,
        })
      },
    },
    {
      key: 'pending_amount',
      name: 'PENDING',
      className: 'w-120',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'pending' ? sort : '',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{currencyFormatter(value)}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'pending',
          value,
        })
      },
    },
    {
      key: 'available_amount',
      name: 'AVAILABLE',
      className: 'w-120',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'available' ? sort : '',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{currencyFormatter(value)}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'available',
          value,
        })
      },
    },
    {
      key: 'lifeTime_amount',
      name: 'LIFETIME',
      className: 'w-120',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'lifetime' ? sort : '',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{currencyFormatter(value)}</div>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'lifetime',
          value,
        })
      },
    },
  ]
  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={row => clickedRow(row.id)}
      />
    </div>
  )
}
