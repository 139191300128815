import React from 'react'
/*
 * money input, allow: 0-9 . delete
 */
export const onMoneyKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  ;[
    'Delete',
    'Backspace',
    'Tab',
    'Escape',
    'Enter',
    'Decimal',
    'Separator',
    'Clear',
  ].indexOf(e.key) !== -1 ||
    ['End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].indexOf(
      e.key,
    ) !== -1 ||
    ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].indexOf(e.key) !==
      -1 ||
    (/a|c|v|x/.test(e.key) &&
      (e.ctrlKey === true || e.metaKey === true) &&
      (!0 === e.ctrlKey || !0 === e.metaKey)) ||
    e.preventDefault()
}

export type TCurrencyFormatter = {
  decimalNum: number
  thousandsSeparator: string
  decimalSeparator: string
  symbol: string
}
export const currencyFormatter = (
  value: number,
  options?: TCurrencyFormatter,
): string => {
  const defaultOptions = {
    decimalNum: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$',
  }
  options = { ...defaultOptions, ...options }
  const negative = value < 0
  const valueStr = Math.abs(value).toFixed(Math.max(0, ~~options.decimalNum))

  const [currency, decimal] = valueStr.split('.')
  if (!decimal) {
    return `${options.symbol}${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator,
    )}`
  }
  return `${negative ? '-' : ''}${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator,
  )}${options.decimalSeparator}${decimal}`
}

export const formatPhoneNumber = (phoneNumberString = '') => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return ''
}

/*
 * format phone to (xxx) xxx-xxxx when keyup
 */
export const onPhoneKeyUp = (
  e: React.KeyboardEvent<HTMLInputElement>,
  phoneInput: string,
) => {
  let ph = phoneInput
  ph = ph.replace(/\D/g, '').substring(0, 10)
  // Backspace and Delete keys
  const deleteKey = e.keyCode == 8 || e.keyCode == 46
  const len = ph.length
  if (len == 0) {
    console.log(ph)
  } else if (len < 3) {
    ph = '(' + ph
  } else if (len == 3) {
    ph = '(' + ph + (deleteKey ? '' : ') ')
  } else if (len < 6) {
    ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6)
  } else if (len == 6) {
    ph =
      '(' +
      ph.substring(0, 3) +
      ') ' +
      ph.substring(3, 6) +
      (deleteKey ? '' : '-')
  } else {
    ph =
      '(' +
      ph.substring(0, 3) +
      ') ' +
      ph.substring(3, 6) +
      '-' +
      ph.substring(6, 10)
  }
  return ph
}

// 部分数字格式化=>防止初始化时后端返回不完整的号码
export const formatPhoneNumberPart = (phone: string | number | undefined) => {
  let ph = String(phone || '')
  const len = ph?.length
  if (len == 0) {
    console.log(ph)
  } else if (len < 3) {
    ph = '(' + ph
  } else if (len == 3) {
    ph = '(' + ph + ') '
  } else if (len < 6) {
    ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6)
  } else if (len == 6) {
    ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6) + '-'
  } else {
    ph =
      '(' +
      ph.substring(0, 3) +
      ') ' +
      ph.substring(3, 6) +
      '-' +
      ph.substring(6, 10)
  }
  return ph
}

/*
 * remove all char to digit only 0-9
 */
export const formatDigitOnly = (inputText: string | number) => {
  if (!inputText) {
    return ''
  }
  return inputText.toString().replace(/\D/g, '')
}

/**
 * 判断变量是否是数字
 * @param str
 * @returns
 */
export const isNumber = (str: number | string | undefined): boolean => {
  if (!str) {
    return false
  }
  return !isNaN(Number(str))
}

/*
 * allow 0-9 and delete key
 */
export const onPhoneKeyDown = (e: any) => {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;-1 !== [46, 8, 9, 27, 13, 110, 189].indexOf(e.keyCode) ||
    (/65|67|86|88/.test(e.keyCode) &&
      (e.ctrlKey === true || e.metaKey === true) &&
      (!0 === e.ctrlKey || !0 === e.metaKey)) ||
    (35 <= e.keyCode && 40 >= e.keyCode) ||
    ((e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) &&
      (96 > e.keyCode || 105 < e.keyCode) &&
      e.preventDefault())
}
