import React, { useState, useRef } from 'react'
import cn from 'classnames'
import StageIndicator from 'components/StageIndicator'
import useOnClickOutside from 'hooks/useOnClickOutside'

export type TMode = '' | 'in_sales' | 'onboarding' | 'live' | 'churn' | 'all'

export type TStageSelectProps = {
  className?: string
  mode: TMode
  count: (string | number)[]
  onChange?: (mode: TMode) => void
}

export default function StageSelect({
  className,
  mode,
  count,
  onChange,
}: TStageSelectProps) {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const handleClick = (type: TMode) => {
    if (!open) {
      setOpen(true)
      return
    } else {
      onChange && onChange(type)
      setOpen(false)
    }
  }
  useOnClickOutside(ref, () => {
    setOpen(false)
  })
  return (
    <div
      ref={ref}
      className={cn(
        'ru-StageSelect relative w-256 border-2 border-dark-100 rounded-lg  bg-dark-900 cursor-pointer',
        className,
      )}
    >
      {mode === 'in_sales' && (
        <div
          className='px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700'
          onClick={() => handleClick('in_sales')}
        >
          <div
            className={cn('text-sm font-medium flex-1 select-none', {
              'text-light-700': mode === 'in_sales',
            })}
          >
            In Sales
          </div>
          <div className='flex items-center'>
            <div className='pr-2 text-light-300 text-sm'>{count[0]}</div>
            <StageIndicator stages={['draft', 'signing']} />
          </div>
        </div>
      )}
      {mode === 'onboarding' && (
        <div
          className={cn(
            'px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700',
          )}
          onClick={() => handleClick('onboarding')}
        >
          <div
            className={cn('text-sm font-medium flex-1 select-none', {
              'text-light-700': mode === 'onboarding',
            })}
          >
            Onboarding
          </div>
          <div className='flex items-center'>
            <div className='pr-2 text-light-300 text-sm'>{count[1]}</div>
            <StageIndicator stages={['onboarding']} />
          </div>
        </div>
      )}
      {mode === 'live' && (
        <div
          className={cn(
            'px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700',
          )}
          onClick={() => handleClick('live')}
        >
          <div
            className={cn('text-sm font-medium flex-1 select-none', {
              'text-light-700': mode === 'live',
            })}
          >
            Live
          </div>
          <div className='flex items-center'>
            <div className='pr-2 text-light-300 text-sm'>{count[2]}</div>
            <StageIndicator stages={['live', 'cancelling']} />
          </div>
        </div>
      )}
      {mode === 'churn' && (
        <div
          className='px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700'
          onClick={() => handleClick('churn')}
        >
          <div
            className={cn('text-sm font-medium flex-1 select-none', {
              'text-light-700': mode === 'churn',
            })}
          >
            Churn
          </div>
          <div className='flex items-center'>
            <div className='pr-2 text-light-300 text-sm'>{count[3]}</div>
            <StageIndicator stages={['cancelling', 'canceled']} />
          </div>
        </div>
      )}
      {mode === 'all' && (
        <div
          className='px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700'
          onClick={() => handleClick('all')}
        >
          <div
            className={cn('text-sm font-medium flex-1 select-none', {
              'text-light-700': mode === 'all',
            })}
          >
            All Stages
          </div>
          <div className='flex items-center'>
            <div className='pr-2 text-light-300 text-sm'>{count[4]}</div>
            <StageIndicator
              stages={[
                'draft',
                'signing',
                'onboarding',
                'live',
                'cancelling',
                'canceled',
              ]}
            />
          </div>
        </div>
      )}
      {open && (
        <div className='absolute -top-0.5 -left-0.5 z-10 w-256 border-2 border-dark-100 rounded-lg bg-dark-900'>
          <div
            className='px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700'
            onClick={() => handleClick('in_sales')}
          >
            <div
              className={cn('text-sm font-medium flex-1 select-none', {
                'text-light-700': mode === 'in_sales',
              })}
            >
              In Sales
            </div>
            <div className='flex items-center'>
              <div className='pr-2 text-light-300 text-sm'>{count[0]}</div>
              <StageIndicator stages={['draft', 'signing']} />
            </div>
          </div>
          <div
            className={cn(
              'px-4 flex items-center justify-between text-light-300 hover:text-light-700',
              open ? '' : 'py-2',
            )}
            onClick={() => handleClick('onboarding')}
          >
            <div
              className={cn('text-sm font-medium flex-1 select-none', {
                'text-light-700': mode === 'onboarding',
              })}
            >
              Onboarding
            </div>
            <div className='flex items-center'>
              <div className='pr-2 text-light-300 text-sm'>{count[1]}</div>
              <StageIndicator stages={['onboarding']} />
            </div>
          </div>
          <div
            className={cn(
              'px-4 flex items-center justify-between text-light-300 hover:text-light-700',
              open ? 'pt-2' : 'py-2',
            )}
            onClick={() => handleClick('live')}
          >
            <div
              className={cn('text-sm font-medium flex-1 select-none', {
                'text-light-700': mode === 'live',
              })}
            >
              Live
            </div>
            <div className='flex items-center'>
              <div className='pr-2 text-light-300 text-sm'>{count[2]}</div>
              <StageIndicator stages={['live', 'cancelling']} />
            </div>
          </div>
          <div
            className={cn(
              'px-4 flex items-center justify-between text-light-300 hover:text-light-700',
              open ? 'pt-2' : 'py-2',
            )}
            onClick={() => handleClick('churn')}
          >
            <div
              className={cn('text-sm font-medium flex-1 select-none', {
                'text-light-700': mode === 'churn',
              })}
            >
              Churn
            </div>
            <div className='flex items-center'>
              <div className='pr-2 text-light-300 text-sm'>{count[3]}</div>
              <StageIndicator stages={['cancelling', 'canceled']} />
            </div>
          </div>
          <div
            className='px-4 py-2 flex items-center justify-between text-light-300 hover:text-light-700'
            onClick={() => handleClick('all')}
          >
            <div
              className={cn('text-sm font-medium flex-1 select-none', {
                'text-light-700': mode === 'all',
              })}
            >
              All Stages
            </div>
            <div className='flex items-center'>
              <div className='pr-2 text-light-300 text-sm'>{count[4]}</div>
              <StageIndicator
                stages={[
                  'draft',
                  'signing',
                  'onboarding',
                  'live',
                  'cancelling',
                  'canceled',
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
