import { ReactElement, useContext } from 'react'
import cn from 'classnames'
import TabsContext from './context'

export type TPanelProp = {
  children: ReactElement | ReactElement[] | string
  className?: string
  tab: string | ReactElement
  tabKey: string | number
  disabled?: boolean
}

export default function Panel({
  className = '',
  tab,
  tabKey,
}: TPanelProp): JSX.Element {
  const tabCtx = useContext(TabsContext)
  return (
    <div
      className={cn(
        'h-9 flex-1 flex justify-center items-center rounded cursor-pointer',
        'mr-2 last:mr-0',
        tabCtx.activeKey === tabKey
          ? 'text-light-900 bg-white-16'
          : 'text-light-300',
        className,
      )}
      onClick={() => tabCtx.onChange(tabKey)}
    >
      {tab}
    </div>
  )
}
