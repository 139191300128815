import cn from 'classnames'
import { SolidCalendar } from '@rushable/icons'
import type { DatePickerProps } from 'antd'
import DatePicker from 'antd/es/date-picker'
// import 'assets/scss/components/datepicker.scss'

type TextraProps = {
  className?: string
}

export type TDatePicker = TextraProps & DatePickerProps

export default function DatePickerComps({
  className,
  ...props
}: TDatePicker): JSX.Element {
  return (
    <DatePicker
      className={cn(
        'w-full py-2 px-4 bg-dark-300 border-2 border-dark-300 text-light-100 rounded-lg hover:border-light-700',
        className,
      )}
      style={{ color: '#4C515C', borderColor: 'transparent' }}
      inputReadOnly={true}
      suffixIcon={<SolidCalendar className='text-light-300' size={16} />}
      {...props}
    />
  )
}
