import { Routes, Route } from 'react-router-dom'
import MainPage from './PayoutPage'
import PayoutDetailPage from './PayoutDetailPage'

export default function PayoutPage() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}></Route>
      <Route path='/detail' element={<PayoutDetailPage />} />
    </Routes>
  )
}
