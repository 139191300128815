import React from 'react'
import { SolidUserGroup } from '@rushable/icons'
import ConditionsItemForm from './ConditionsItemForm'
import type { IOptions } from 'components/Select/help'
import type { TFilterItem } from '../help/constant'

export default function SalesTeamFilter({
  className,
  value,
  options,
  onChange,
}: TFilterItem) {
  return (
    <ConditionsItemForm
      className={className}
      icon={<SolidUserGroup size={16} className='text-light-100' />}
      label='Sales team'
      value={value}
      options={options as IOptions[]}
      onChange={onChange}
    />
  )
}
