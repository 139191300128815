import { ReactElement } from 'react'
import cn from 'classnames'
import TabsContext from './context'

export type TTabsProp = {
  tabClass?: string
  contentClass?: string
  isCache?: boolean
  children: ReactElement[]
  activeKey: string | number
  onChange?: (value: any) => void
}

export default function Tabs({
  tabClass,
  contentClass,
  isCache = true,
  children,
  activeKey,
  onChange,
}: TTabsProp): JSX.Element {
  let panel = null
  if (isCache) {
    panel = children?.map(v => {
      const tabKey = v.props.tabKey
      return (
        <div key={tabKey} className={tabKey === activeKey ? '' : 'hidden'}>
          {v.props.children}
        </div>
      )
    })
  } else {
    panel = children.find(v => v.props?.tabKey === activeKey)?.props?.children
  }

  return (
    <>
      <div
        className={cn(
          'flex p-2 border-2 border-white-16 rounded-lg font-bold text-sm',
          tabClass,
        )}
      >
        <TabsContext.Provider value={{ activeKey, onChange }}>
          {children}
        </TabsContext.Provider>
      </div>
      <div className={contentClass}>{panel}</div>
    </>
  )
}
