import axiosInstance from 'utils/axios'

export type TDisputesParams = {
  status: string
  brand_name: string
  location_id: string
  page: number
}

export async function getDisputes(params: TDisputesParams) {
  const response = await axiosInstance.request({
    url: 'disputes',
    method: 'get',
    params: { ...params },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function createBlocklist({
  type,
  order_id,
  customer_id,
  reason,
}: {
  type: string
  order_id: string
  customer_id: string
  reason: string
}) {
  const response = await axiosInstance.request({
    url: 'blocklists',
    method: 'post',
    data: { type, order_id, customer_id, reason },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getStripeDisputes(id: string) {
  const response = await axiosInstance.request({
    url: `disputes/${id}`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
