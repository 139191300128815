import { useNavigate } from 'react-router-dom'
import Table from 'components/Table'
import AttentionStatus from 'components/AttentionStatus'
import Contribution from 'components/Contribution'
import FeatureTracking from 'components/FeatureTracking'
import PersonName from 'components/PersonName'
import SaleComparison from 'components/SaleComparison'

import { useAppSelector } from 'redux/hooks'
import { SOURCEMAP2 } from 'utils/constant'

import {
  pricingRender,
  closerRender,
  paymentRender,
  stageDaysRender,
  stageRender,
  followupRender,
  gbpRender,
  weekSalesRender,
  successFollowupRender,
} from './AccountsTableColumns'

import type { TOrder } from '../helpers/constant'
import { TMode } from 'components/StageSelect'

export type TAccountTableProp = {
  loading?: boolean
  handleOrderChange: (newSortData: TOrder) => void
  onPageNoChange?: (value: any) => void
}

export default function AccountTable({
  loading,
  handleOrderChange,
  onPageNoChange,
}: TAccountTableProp): JSX.Element {
  const { total, mode, page, pageSize, data, sortData } = useAppSelector(
    state => state.account,
  )
  const { sort_by, sort } = sortData
  const navigate = useNavigate()
  const baseColumns = [
    {
      key: 'brand',
      name: 'BRAND',
      custom: (value: string) => {
        return <div className='truncate'>{value || '-'}</div>
      },
    },
    {
      key: 'location',
      name: 'LOCATION',
      custom: (value: string) => {
        return <div className='truncate'>{value || '-'}</div>
      },
    },
  ]
  const inSalesColumns = () => {
    return [
      pricingRender({
        className: 'w-192',
        sortData,
        handleOrderChange,
      }),
      {
        key: 'team',
        name: 'TEAM',
        className: 'w-24',
      },
      {
        key: 'source',
        name: 'SOURCE',
        className: 'w-24',
        custom: (value: any) => {
          // @ts-ignore
          return <div>{SOURCEMAP2[value] || '-'}</div>
        },
      },
      closerRender('w-152', 'w-full'),
      paymentRender({
        className: 'w-184',
        sortData,
        handleOrderChange,
      }),
      stageDaysRender({
        className: 'w-28',
        sortData,
        handleOrderChange,
      }),
      stageRender({
        className: 'w-120',
        sortData,
        handleOrderChange,
      }),
    ]
  }
  const onBoardingColumns = () => {
    return [
      pricingRender({
        className: 'w-192',
        sortData,
        handleOrderChange,
      }),
      // closerRender('w-132', 'w-88'),
      closerRender('w-152', 'w-full'),
      {
        key: 'assignee',
        name: 'ASSIGNEE',
        className: 'w-152',
        custom: (value: any) => {
          return (
            <div className='flex items-center'>
              <PersonName
                className='w-full'
                avatar={value.avatar}
                name={value.name}
              />
            </div>
          )
        },
      },
      paymentRender({
        className: 'w-184',
        sortData,
        handleOrderChange,
      }),
      {
        key: 'issue',
        name: 'ISSUE',
        className: 'w-24',
        sortable: true,
        sortDirection: sort_by === 'issue' ? sort : '',
        onSortDirection: (
          key: string,
          item: { label: string; value: string },
        ) => {
          const { value } = item
          handleOrderChange({
            sort_by: 'issue',
            sort: value,
          })
        },
        custom: (value: any) => {
          return (
            <AttentionStatus
              handRaise={!!value.handRaise}
              google={!!value.google}
            />
          )
        },
      },
      followupRender({
        className: 'w-48',
        sortData,
        name: 'ONBOARDING FOLLOWUP',
        handleOrderChange,
      }),
      stageDaysRender({
        className: 'w-28',
        sortData,
        handleOrderChange,
      }),
      stageRender({
        className: 'w-112',
        sortData,
        handleOrderChange,
      }),
    ]
  }
  const liveColumns = () => {
    return [
      {
        key: 'features',
        name: 'FEATURES',
        className: 'w-44',
        custom: (value: any) => {
          return (
            <FeatureTracking
              pickup={value.pickup}
              delivery={value.delivery}
              dineIn={value.dineIn}
              email={value.email}
              phone={value.phone}
            />
          )
        },
      },
      paymentRender({
        className: 'w-184',
        sortData,
        handleOrderChange,
      }),
      weekSalesRender({
        className: 'w-88',
        sortData,
        handleOrderChange,
      }),
      {
        key: 'comparison',
        name: 'COMP',
        className: 'w-24',
        align: 'center',
        custom: (value: any) => {
          return <SaleComparison weeklySales={value} />
        },
      },
      gbpRender({
        sortData,
        className: 'w-96',
        handleOrderChange,
      }),
      successFollowupRender({
        className: 'w-48',
        sortData,
        name: 'SUCCESS FOLLOWUP',
        handleOrderChange,
      }),
      {
        key: 'cont',
        name: 'CONT',
        className: 'w-132',
        align: 'right',
        sortable: true,
        sortDirection: sort_by === 'cont' ? sort : '',
        onSortDirection: (
          key: string,
          item: { label: string; value: string },
        ) => {
          const { value } = item
          handleOrderChange({
            sort_by: 'cont',
            sort: value,
          })
        },
        custom: (value: any) => {
          return <Contribution num={value || 0} />
        },
      },
    ]
  }
  const churnColumns = () => {
    return [
      pricingRender({
        className: 'w-192',
        sortData,
        handleOrderChange,
      }),
      {
        key: 'features',
        name: 'FEATURES',
        className: 'w-176',
        custom: (value: any) => {
          return (
            <FeatureTracking
              pickup={value.pickup}
              delivery={value.delivery}
              dineIn={value.dineIn}
              email={value.email}
              phone={value.phone}
            />
          )
        },
      },
      paymentRender({
        className: 'w-184',
        sortData,
        handleOrderChange,
      }),
      weekSalesRender({
        className: 'w-88',
        sortData,
        handleOrderChange,
      }),
      {
        key: 'comparison',
        name: 'COMP',
        className: 'w-24',
        align: 'center',
        custom: (value: any) => {
          return (
            <div className='w-full flex justify-center'>
              <SaleComparison weeklySales={value} />
            </div>
          )
        },
      },
      gbpRender({
        sortData,
        handleOrderChange,
      }),
      stageDaysRender({
        className: 'w-28',
        sortData,
        handleOrderChange,
      }),
      stageRender({
        className: 'w-112',
        sortData,
        handleOrderChange,
      }),
    ]
  }
  const columns = (nowMode: TMode): any => {
    switch (nowMode) {
      case 'in_sales':
        return [...baseColumns, ...inSalesColumns()]
      case 'onboarding':
        return [...baseColumns, ...onBoardingColumns()]
      case 'live':
        return [...baseColumns, ...liveColumns()]
      case 'churn':
        return [...baseColumns, ...churnColumns()]
      case 'all':
        return [...baseColumns, ...churnColumns()]
    }
  }

  const goDetail = (item: any) => {
    navigate(`/accounts/detail/${item.id}`)
  }
  return (
    <div>
      <Table
        loading={loading}
        resizePageSize={pageSize}
        current={page}
        columns={columns(mode)}
        data={data}
        total={total}
        onPageNoChange={value => onPageNoChange?.(value)}
        clickedRow={goDetail}
      />
    </div>
  )
}
